import { useState } from "react";
import { ActionChallenge } from "./ActionChallenge";
import { removeActionChallenge } from "../../../Store/actions";
import { toast } from "react-toastify";

interface IFactorWrapper {
    XLFactor: any;
    actionChallenges: any;
    universalSurveytitle: any;
    setActionChallenges: any;
  }
  
  export const FactorWrapper: React.FC<IFactorWrapper> = ({XLFactor,actionChallenges,universalSurveytitle,setActionChallenges}) => {
  
    const [visivility,setVisivility] = useState(false);
  
    const handleShowHide= ()=> {
      setVisivility(prev => !prev);
    }
  
    const handleDeleteActionChallenge = async (actionChallengeId:any)=> {
      const res = await removeActionChallenge(universalSurveytitle._id,actionChallengeId);
      if(res.success){
        toast.success(res.message);
        setActionChallenges(res.actionChallenges);
      }
      else {
        toast.error(res.message);
      }
    }
  
    return (
    <>
              <div className="border-b pb-2">
                <div className="flex justify-between">
                  <div>
                    {XLFactor} ({actionChallenges?.challenges?.length})
                  </div>
                  <div className="flex gap-3">
                    <div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input onChange={handleShowHide} type="checkbox" value="" className="sr-only peer" checked={visivility} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{visivility? "Hide":"Show"}</span>
                      </label>
                    </div>
                    <div className={`${actionChallenges?.challenges?.length > 0? "":"hidden"}`}>
                      <button 
                      className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
                      onClick={()=> handleDeleteActionChallenge(actionChallenges._id)}
                      >Delete All</button>
                    </div>
                  </div>
                </div>
                  <div className={`flex bg-gray-100 p-4 rounded flex-col gap-3 ${visivility? "":"hidden"}`}>
                  {
                      actionChallenges?.challenges.map((ac:any,i:number) => {
                        return <ActionChallenge 
                        key={i} 
                        actionChallenge={ac} 
                        i={i} 
                        actionChallengeId={actionChallenges._id}
                        setActionChallenges={setActionChallenges}
                        universalSurveytitle={universalSurveytitle} />
                      })
                  }
                </div>
              </div>
    </>)
  }
  