import { useEffect,useRef,useState } from "react";
import {
  getUserDashboardData,
  updateLoggedInUser,
  getTodaysEvents
 } from "../../Store/actions";
import {
  useLocation
} from "react-router-dom";
import {
  EventModal,
  Loading
} from "../../components";
import {
  FormSkeleton,
  YourInsightsFullView,
  Tabs,
  InstantInsights,
  PeakStateActionChallenges,
  MobileView,
  DesktopView
} from "./components";
import { Main } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { CogniPulsePhySelPhySelfieImpulseProgressModal } from "../../components/Modals";
import { getUserActivities } from "../../Store/actions/activity";
import { AppDispatch, RootState } from "../../Store/store";



const Index: React.FC<any> = ()  => {
  const location = useLocation();
  const [showAddEvent, setShowAddEvent] = useState(true);
  const [showProgress, setShowProgress] = useState(()=> location.state?.loading);
  const [numberOfCompletedPhycheSelfies, setNumberOfCompletedPhycheSelfies] = useState(null);
  const user = useSelector((state:RootState) => state.loggedInUser);
  const [isPhySelfieChanged, setIsPhySelfieChanged] = useState(false);

  useEffect(() => {
    (async ()=> {
      if(location.state?.loading){
        const res = await getTodaysEvents();
        if(res.success){
          // console.log(res);
          setNumberOfCompletedPhycheSelfies(res.numberOfCompletedPhycheSelfies);
        }
      }
    })()
  }, [showAddEvent,location.state?.loading]);


  if(!user && !location.state?.loading){
    return <div><Loading/></div>
  }

  if(!user && location.state?.loading){
    return <FormSkeleton/>
  }
  
  // if(showAddEvent && location.state?.loading){
  if(showAddEvent && location.state?.loading){
    return <Main>
                <EventModal
                  isOpen={showAddEvent}
                  setIsOpen={setShowAddEvent}
                  userId={user._id}
                  isManagemaneEvent={false}
                  isUpdate={null}
                  data={null}
                  setEvents={null}
                  eventId={null}
                  surveyTitle={null}
                  setIsPhySelfieChanged={setIsPhySelfieChanged}
                />
          </Main>
  }

  
  if(showProgress){
    const max = graterNumber(location.state?.numberOfCompletedSurveys,location.state?.numberOfNotifications,numberOfCompletedPhycheSelfies,location.state?.numberOfCompletedImpactSurveys);
    return <Main>
                    <CogniPulsePhySelPhySelfieImpulseProgressModal 
                        numberOfNotifications={max || 1}
                        numberOfCompletedSurveys={location.state?.numberOfCompletedSurveys}
                        numberOfCompletedImpactPulse={location.state?.isNonCogniPulse? location.state?.numberOfCompletedImpactSurveys:location.state?.numberOfCompletedImpactSurveys}
                        numberOfCompletedPhycheSelfies={numberOfCompletedPhycheSelfies}
                        isCogniPuseChanged={location.state?.isCogniPuseChanged}
                        isPhySelfieChanged={isPhySelfieChanged}
                        isImPuseChanged={location.state?.isImPuseChanged}
                        setShowProgress={setShowProgress}
                    />
            </Main>
  }

  if(user && user.canAccessDashboard === false){
    return <div className="h-[93vh] flex justify-center items-center">
      <p>Thank you for tracking you state. Your data will be available soon!</p>
    </div>
  }

  return <><NewDashboard/></>
}

export default Index;

const NewDashboard: React.FC<any> = () => {
  const location = useLocation();
  const XLScoreContainer = useRef(null);
  const [deshboardData,setDeshboardData] = useState(null);
  const [isLoading,setIsLoading] = useState(true);
  const [isAnimation,setIsAnimation] = useState(()=> location.state?.loading? true:false);
  const [,setSecVis] = useState({
    actionChallenges: false,
    streamGraph: false
  });
  const [isYourInsightsFullViewOn,setIsYourInsightsFullViewOn] = useState(false);
  const [currentTourStep] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const [, setIsShowOnboarding] = useState(()=> loggedInUser.isDashboardTutorialFinished === false? true:false);
  const [clickedOn] = useState("");
  const [isInstantInsightsOpen,setIsInstantInsightsOpen] = useState(false);
  const [isPeakStateActionChallengesOpen,setIsPeakStateActionChallengesOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState<any>("");
  const user = useSelector((state:RootState) => state.loggedInUser);
  const isMobileScreen = useIsMobile();

  useEffect(() => { 
    (async () => {
      const query = `user=${user._id}&surveyName=${selectedActivity.category}&isEventsAndActionChallenges=true`;

      const res = await getUserDashboardData(query);

      if(res.success){
        setDeshboardData({...res});
        dispatch(updateLoggedInUser({hasSeenDashboardFirstTime: true}));
      }
      setIsLoading(false);
    })();
  }, [user?._id,selectedActivity,dispatch]);

  useEffect(() => {
    if(location.state?.payload?.isShowDashboard){
      setIsShowOnboarding(true);
    }
  }, [location.state?.payload?.isShowDashboard]);

  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      if(res?.success){
        setActivities(res.activities);
        const ct = res.activities.find((ac:any) => ac.name === location.state?.category);
        if(ct){
          setSelectedActivity(ct)
        }
        else {
          setSelectedActivity(res.activities[0])
        }
      }
    })()
  }, [location.state?.category]);


  if(isLoading || !deshboardData){
    return <Main><div className="w-full h-[calc(100vh-64px-57px)] flex justify-center items-center"><Loading/></div></Main>
  }

  const {
    XLScore,
    scoreOfAllfactors,
    percentageOfChangeOfFactors,
    historiclData,
    XLChange,
    higestDiffrance,
    factorsMinMax,
    currentActionChallengesWithSteps,
  } = deshboardData;

  
  
  if(isYourInsightsFullViewOn){
    return <YourInsightsFullView 
            deshboardData={deshboardData} 
            selectedActivity={selectedActivity} 
            XLChange={XLChange} 
            // XLScore={XLScore} 
            historiclData={historiclData} 
            factorsMinMax={factorsMinMax} 
            percentageOfChangeOfFactors={percentageOfChangeOfFactors}
            setIsOpen={setIsYourInsightsFullViewOn}
          />
  }

  if(isInstantInsightsOpen){
    return <InstantInsights 
              higestDiffrance={higestDiffrance} 
              currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
              setSecVis={setSecVis} 
              isAnimation={isAnimation} 
              user={user} 
              selectedActivity={selectedActivity} 
              factorsMinMax={factorsMinMax} 
              percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
              scoreOfAllfactors={scoreOfAllfactors}
              setIsOpen={setIsInstantInsightsOpen}
            />
  }


  if(isPeakStateActionChallengesOpen){
    return <PeakStateActionChallenges 
              clickedOn={clickedOn} 
              selectedActivity={selectedActivity} 
              setIsOpen={setIsPeakStateActionChallengesOpen} 
           />
  }

  
  return (
    <>
        <Main>
        
            <Tabs activities={activities} selectedActivity={selectedActivity} setCurrentActivity={setSelectedActivity}/>
           
            {
              !isMobileScreen && <>
                    <DesktopView 
                      XLChange={XLChange}
                      XLScore={XLScore}
                      selectedActivity={selectedActivity}
                      isAnimation={isAnimation}
                      isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                      XLScoreContainer={XLScoreContainer}
                      currentTourStep={currentTourStep}
                      historiclData={historiclData}
                      setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                      currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                      higestDiffrance={higestDiffrance}
                      user={user}
                      factorsMinMax={factorsMinMax} 
                      percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                      scoreOfAllfactors={scoreOfAllfactors}
                      deshboardData={deshboardData}
                    />
              </>
            }
            {
              isMobileScreen && <>
                  <MobileView
                    XLChange={XLChange}
                    XLScore={XLScore}
                    selectedActivity={selectedActivity}
                    isAnimation={isAnimation}
                    isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                    XLScoreContainer={XLScoreContainer}
                    currentTourStep={currentTourStep}
                    historiclData={historiclData}
                    setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                    currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                    higestDiffrance={higestDiffrance}
                    factorsMinMax={factorsMinMax} 
                    percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                    scoreOfAllfactors={scoreOfAllfactors}
                    setIsAnimation={setIsAnimation}
                  />
              </>
            }
        </Main>
    </>
  )
}

function graterNumber(...rest:any){
  return  rest.filter((v:any) => typeof v === 'number').sort().reverse()[0];
}

function useIsMobile(){
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500? true:false);


  useEffect(() => {

      window.addEventListener("orientationchange",(e:any) => {
        if(e.target.screen.orientation.type === "portrait-primary"){
          setIsMobile(true);
        }
        else {
          setIsMobile(false);
        }
      })

    return ()=> {
      window.removeEventListener("orientationchange",() => {
        setIsMobile(false);
      });
    }
  }, []);

  return isMobile
}






