import { useState } from "react";

interface IFilterOptions {
    setQuery: any;
    users: any;
    surverTitles: any;
    groups: any;
  }
  
 export  const FilterOptions: React.FC<IFilterOptions> = ({setQuery,users,surverTitles,groups}) => {
    const [showAll,setShowAll] = useState(false);
    const [showExcluded,setShowExcluded] = useState(false);
    const [userSearchTerm,setUserSearchTerm] = useState("");
    const [surveySearchTerm,setSurveySearchTerm] = useState("");
    const [groupSearchTerm,setGroupSearchTerm] = useState("");
  
    const handleShowAllChange = () => {
      setShowAll(p=>!p);
      setQuery(() => {
          return {
            all: !showAll,
            userId: "",
            showExcluded: true
          }
      })
    }
  
    const handleShowExcludedChange = () => {
      setShowExcluded(p=>!p);
      setQuery(() => {
        return {
          all: true,
          userId: "",
          showExcluded: !showExcluded
        }
      })
    }
  
    const handleUserSellect = (_id:string) => {
      setUserSearchTerm("");
      setQuery(() => {
        return {
          showExcluded: true,
          all: true,
          userId: _id
        }
      })
    }
  
    const handleSurveyTitleSelect = (_id:string) => {
      setSurveySearchTerm("");
      setQuery(() => {
        return {
          showExcluded: true,
          all: true,
          surveyTitle: _id
        }
      })
    }
  
    const extrectAllUserIds = (group:any) => {
      let userIds:any = "";
      visit(group);
      function visit(node:any){
        const temp = node.users?.map((u:any) => u._id);
        userIds = [...userIds,...temp];
        
        if(node.children){
          node.children.forEach((child:any) => {
            const foundGroup = groups.find((g:any) => g._id === child._id);
            if(foundGroup){
              visit(foundGroup);
            }
          })
        }
      }
      return userIds.join(",");
    }
  
    const handleGroupSellect = (group:any) => {
      const userIds = extrectAllUserIds(group);    
  
      setGroupSearchTerm("");
      setQuery(() => {
        return {
          showExcluded: true,
          all: true,
          users: userIds || null
        }
      })
    }
  
   
  
    return (
      <>
       <div className='py-10'>
          <div className="flex gap-10 items-center flex-wrap">
              <div className="block min-h-6 pl-7">
                <label className='flex items-center gap-3'>
                  <input checked={showAll} onChange={handleShowAllChange} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                  <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show All</label>
                </label>
              </div>
              <div className="block min-h-6 pl-7 relative">
                <label className='flex items-center gap-3'>
                  <input checked={showExcluded} onChange={handleShowExcludedChange} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                  <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show Excluded</label>
                </label>
              </div>
              <div className="block min-h-6 pl-7">
                <input value={userSearchTerm} onChange={(e)=> setUserSearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search User.." />
                {
                  userSearchTerm && <div className="absolute z-[1] bg-white w-[198px] rounded-md p-2">
                                      {
                                        users.filter((user:any) => `${user.forename} ${user.surname}`.toLowerCase().includes(userSearchTerm.toLowerCase())).map((user:any) => (
                                          <p onClick={()=> handleUserSellect(user._id)} className='cursor-pointer'>{user.forename} {user.surname}</p>
                                        ))
                                      }
                                    </div>
                }
              </div>
              <div className="block min-h-6 pl-7 relative">
                  <input value={surveySearchTerm} onChange={(e)=> setSurveySearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Survey.." />
                    {
                      surveySearchTerm && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                          {
                                            surverTitles.filter((survey:any) => `${survey.title}`.toLowerCase().includes(surveySearchTerm.toLowerCase())).map((survey:any) => (
                                              <p onClick={()=> handleSurveyTitleSelect(survey._id)} className='cursor-pointer'>{survey.title}</p>
                                            ))
                                          }
                                        </div>
                    }
              </div>
              <div className="block min-h-6 pl-7">
                <input value={groupSearchTerm} onChange={(e)=> setGroupSearchTerm(e.target.value)} type="search" id="default-search" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Group.." />
                {
                  groupSearchTerm && <div className="absolute bg-white w-[198px] rounded-md p-2">
                                      {
                                        groups.filter((group:any) => `${group.groupname}`.toLowerCase().includes(groupSearchTerm.toLowerCase())).map((group:any) => (
                                          <p onClick={()=> handleGroupSellect(group)} className='cursor-pointer'>{group.groupname}</p>
                                        ))
                                      }
                                    </div>
                }
              </div>
          </div>
       </div>
     
      </>
    )
  }