import React, { useEffect } from 'react';
import {motion, useAnimation} from "framer-motion";
import {logoSquire} from "../../../assets";


interface ILogo {
  setIsDisplayLogo: any;
}

export const Logo: React.FC<ILogo> = ({setIsDisplayLogo}) => {
  const animate = useAnimation();

  useEffect(() => {
    animate.start({opacity: 1,scale: 1})
    const time = setTimeout(() => {
      animate.start({opacity: 0})
      setTimeout(() => {
        setIsDisplayLogo(false);
      }, 1500);
    }, 2000);

    return () => {
      clearTimeout(time);
    }
  }, [animate,setIsDisplayLogo]);

  return (
    <div className='w-full h-[calc(100vh-64px)] flex justify-center items-center'>
        <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={animate}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}
        className="flex flex-col items-center p-5 rounded-md">
          <img src={logoSquire} alt="logo" className='w-[500px] max-w-[100%]'/>
        </motion.div>
    </div>
  )
}




