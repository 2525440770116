import { XL_FACTORS } from "../../../constants";

export const tableHeaders = [
    "#",
    "User Name",
    "User Email",
    "Org.",
    "Dept.",
    "Group",
    "S. Title",
    "User Activity Name",
    "User Activity Title",
    "Date",
    "Time",
    "Survey Dur",
    "Impact Start period",
    ...XL_FACTORS,
    "XL Score",
    "Lowest XL",
    "Highest XL",
    "Event",
    "XlChange",
    "Exclude",
    "Mood"
];


export const ImpactTableHeaders = [
    "#",
    "User Name",
    "User Email",
    "Org.",
    "Dept.",
    "Group",
    "S. Title",
    "User Activity Name",
    "User Activity Title",
    "Date",
    "Time",
    "Survey Dur",
    "Impact Start period",
    "Creativity",
    "Productivity"
];


export const sdTableHeader = [
    "#",
    "User Name",
    "User Email",
    "Org.",
    "Dept.",
    "Group",
    "S. Title",
    "User Activity Name",
    "User Activity Title",
    "Date",
    "Time",
    "XL SD (last 7 days)",
]