import { SET_PREVIOUS_ROUTE } from "../../constants";


interface IpreviousRoute {
  type: string;
  payload?: any;
}


export function previousRoute(state:any = "", action:IpreviousRoute) {

    switch (action.type) {

      case SET_PREVIOUS_ROUTE:
        return action.payload;

      default:
        return state;
        
    }
}
