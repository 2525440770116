import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {PrimaryButton} from "../../../components";
// import {useMixpanelBrowser} from "../../../Hooks";
import {capitalized} from "../../../utils";
import {Main,CenterContent} from "../../../layout";
import { getUserActivities } from '../../../Store/actions/activity';
import dayjs from 'dayjs';


interface IPurposeAndGoalStatement {
  user: any;
  currentCategory: any;
  currentName: any;
  isCommingFromPrePostSurvey: any;
  setpurposeAndGoalStatementSeen: any;
}


export const PurposeAndGoalStatement: React.FC<IPurposeAndGoalStatement> = ({user,currentCategory,currentName,isCommingFromPrePostSurvey,setpurposeAndGoalStatementSeen}) => {
  const [goal,setGoal] = useState(null);
  const [purpose,setPurpose] = useState<any>({});
  const navigator = useNavigate();
  const [goalStartAndEndDate, setGoalStartAndEndDate] = useState<any>(null);


  const handleNext = () => {
    setpurposeAndGoalStatementSeen(true);
    // addEvent(user?._id,"Purpose & Goal Next Button!");
  }

  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      
      if(res?.success){
        if(!res.activities && res.activities.length === 0){
          return navigator("/user-survey-selection");
        }


        const currentActivity = res.activities.find((act:any) => act.category.trim() === currentCategory?.trim() && act.name.trim() === currentName?.trim());
        if(isCommingFromPrePostSurvey){
          setpurposeAndGoalStatementSeen(false);
        }
        if(currentActivity && !isCommingFromPrePostSurvey){
          if(currentActivity.goal && currentActivity.goal.length === 0){
            setpurposeAndGoalStatementSeen(false);
          }
          else {
            setGoal(currentActivity.goal);
            setPurpose(currentActivity.purpose);

            
            if(currentActivity.goalEnteredDate){
              setGoalStartAndEndDate({
                start: currentActivity.goalEnteredDate,
                end: currentActivity.goal[5]
              })
            }
          }
        }
      }

    })()
  }, [user,currentCategory,currentName,navigator,isCommingFromPrePostSurvey,setpurposeAndGoalStatementSeen]);

  

  return (
    <>
      <Main>
          <CenterContent>
              <h1 className="text-sm font-bold text-secondry-color  tracking-widest text-center mb-1">
                My purpose for my {currentName} {currentCategory}
              </h1>
              <p className="text-sm  tracking-widest mb-5 text-center text-secondry-color">
                {
                  purpose && <>
                    Using my {purpose[0]?.toLowerCase()} and my {purpose[1]?.toLowerCase()} I {purpose[2]?.toLowerCase()} {purpose[3]?.toLowerCase()} to {purpose[4]?.toLowerCase()} {purpose[5]?.toLowerCase()} in order to {purpose[6]?.toLowerCase()} {purpose[7]?.toLowerCase()} while staying true to my core values of {purpose[8]?.toLowerCase()} {purpose[9]?.toLowerCase()} & {purpose[10]?.toLowerCase()}
                  </>
                }
              </p>
              <p className="text-sm font-bold  tracking-widest text-center mb-1 text-secondry-color">
                {
                  goalStartAndEndDate && <>{dayjs(goalStartAndEndDate?.end).diff(dayjs(),"day")} Days remaining of my {dayjs(goalStartAndEndDate?.end).diff(dayjs(goalStartAndEndDate?.start),"day")} day goal</>
                }
                {
                  !goalStartAndEndDate && <span onClick={()=>{navigator("/purpose-and-goal")}}>Click here to update goal</span>
                }
              </p>
              <p className="text-sm  tracking-widest text-center mb-8 text-secondry-color">
                  {
                    goal && Object.keys(goal)?.map((key,idx) => idx===0? capitalized(`${goal[key]} `):`${goal[key]} `.toLowerCase())
                  }
              </p>
            
              <PrimaryButton onClick={handleNext}>
                Next
              </PrimaryButton>
          </CenterContent>
      </Main>
    </>
  )
}

