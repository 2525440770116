interface IDashboardStatus {
    user:any;
  }
  
  export const DashboardStatus: React.FC<IDashboardStatus> = ({user}) => {
  
  
    return (
      <>
                {
                  user.isDashboardTutorialFinished && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0'>Finished</p>
                }
                {
                  !user.isDashboardTutorialFinished && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-red-50 text-red-600 text-center rounded-md mb-0'>Not Finished</p>
                }
      </>
    )
  }