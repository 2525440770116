import { useState } from "react";
import {Eye} from "./Icons";


interface IPasswordInputWithEye {
    value: any;
    onChange: any;
    name: any;
    id: any;
    placeholder: any;
}


export const PasswordInputWithEye: React.FC<IPasswordInputWithEye> = ({value,onChange,name,id,placeholder}) =>{
    const [type, setType] = useState("password");

    return (
        <div className="relative flex justify-end items-center">
            <input value={value} onChange={onChange} type={type} name={name} id={id} placeholder={placeholder || "••••••••"} className="bg-secondry-color/20 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            {
                type === "password" && <>
                <Eye onClick={()=>setType("text")} className="absolute mr-3 cursor-pointer"/>
                <span onClick={()=>setType("text")} className="cursor-pointer absolute mr-4 w-[25px] rounded-md h-[12px] bg-black/70"/>    
                </>
            }
            {
                type === "text" && <Eye onClick={()=>setType("password")} className="absolute mr-3 cursor-pointer"/>
            }
        </div>
    )
}
