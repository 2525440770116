import React from 'react';
import { BackButton } from '../../../components';


interface IHeader {
  title?: any;
  handleBack: any;
}

export const Header: React.FC<IHeader> = ({title="Title",handleBack}) => {

 

  return (
    <div className='relative border-b border-secondry-color/20 pb-2 mb-4 flex justify-between items-center'>
        {
          handleBack && <BackButton onClick={handleBack}/>
        }
        <h1 className='text-2xl font-bold flex-1 text-center'>{title}</h1>
    </div>
  )
}
