import CircularSlider from "react-circular-slider-svg";


interface IArcSliderWrapper {
    handleChange: any;
    value: any;
    description: any;
    minValue: any;
    maxValue: any;
    name: any;
}


export const ArcSliderWrapper: React.FC<IArcSliderWrapper> = ({handleChange,value,description,minValue,maxValue,name}) => {
    const width = 200;

    return (
        <div style={{width: `${width}px`, height: `${(width*60)/100}px`, marginTop: `${(width*20)/100}px`}} className='relative z-10 flex justify-center items-center'>
            <CircularSlider
                size={width}
                minValue={minValue}
                maxValue={maxValue}
                startAngle={60}
                endAngle={300}
                angleType={{
                    direction: "cw",
                    axis: "-y"
                }}
                handle1={{
                    value: value,
                    onChange: (v)=> {handleChange(v,name)}
                }}
                arcColor="#001f3f"
                arcBackgroundColor={"#CECECE"}
            />
            <p className="absolute text-xs w-[60%] text-center select-none text-secondry-color">{description}</p>
        </div>
    )

}