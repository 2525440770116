import dayjs from "dayjs";
import { EmptyField } from "./EmptyField";

interface IOneLineField {
    user: any;
    fieldName: any;
  }
  
  export const OneLineField: React.FC<IOneLineField> = ({user,fieldName}) => {
    if(!user[fieldName] && fieldName !== "isSharingData"){
      return <EmptyField/>
    }
  
    // onbordingEmailTime
  
    if(fieldName === "dob" && user[fieldName]){
      return <span className='text-secondry-color/80'>{new Date(user[fieldName]).toLocaleDateString()}</span>
    }
    if((fieldName === "onbordingEmailTime" || fieldName === "postSurveyDate") && user[fieldName]){
      return <span className='text-secondry-color/80'>{dayjs(user[fieldName]).format("LLL")}</span>
    }
    if(fieldName === "isSharingData"){
      if(user[fieldName] === true){
        return <span className='text-green-600 bg-green-50 w-full p-1 rounded-md'>Yes</span>
      }
      return <span className='text-red-600 bg-red-50 w-full p-1 rounded-md'>No</span>
    }
  
    return <span className='text-secondry-color/80'>{user[fieldName]}</span>;
  }
  