import React,{useEffect, useState} from 'react';
import { getAllSurveyTitles } from '../../../Store/actions';
import { PopupContainer } from '../../../layout';
import { PrimaryButton, SecondryButton } from '../../../components';
import { Day } from './Day';


interface IAddSurveyTitleTiming {
    setTimingData: any;
    timingData: any;
    isOpen: any;
    setIsOpen: any;
    addName: any;
    onSubmit: any;
}

export const AddSurveyTitleTiming: React.FC<IAddSurveyTitleTiming> = ({setTimingData,timingData,isOpen,setIsOpen,addName,onSubmit}) => {

    const [surveyTitles, setSurveyTitles] = useState<any>(null);
    const [days] = useState([0,1,2,3,4,5,6]);
    const [unRegularSurvey,setUnRegularSurvey] = useState(timingData.unRegularSurvey || "");
    const [isUnRegularSurvey,setIsUnRegularSurvey] = useState(timingData.unRegularSurvey? true:false);
   

    const handleTimingDataChange = (e:any) =>{
        setTimingData((prev:any) =>{

            if(e.target.name === "startDate"){
                return {
                    ...prev,
                    duration: {...prev.duration,start: e.target.value}
                } 
            }
            if(e.target.name === "endDate"){
                return {
                    ...prev,
                    duration: {...prev.duration,end: e.target.value}
                } 
            }
            if(e.target.name === "time"){
                const [hours,minutes] = e.target.value.split(":");
                // console.log(hours,minutes);
                return {
                    ...prev,
                    time: {hours,minutes}
                } 
            }

            if(e.target.name === "frequency"){
                if(e.target.value === "daily"){
                    return {
                        ...prev,
                        days: [0,1,2,3,4,5,6],
                        [e.target.name]: e.target.value
                    }
                }
                if(e.target.value === "weekly"){
                    return {
                        ...prev,
                        [e.target.name]: e.target.value
                    }
                }
            }
  
        })
     
    }

   
    useEffect(() => {
        setTimingData((prev:any)=> {
            if(!isUnRegularSurvey){
                return {
                    ...prev,
                    unRegularSurvey: ""
                }
            }
            return {
                ...prev,
                unRegularSurvey: unRegularSurvey || surveyTitles.filter((s:any) => !s.isUniversal)[0]
            }
        })
    }, [surveyTitles,setTimingData,isUnRegularSurvey,unRegularSurvey]);
    

    useEffect(() => {
        setTimingData((prev:any) => {
            return {
                ...prev,
                surveytitle: surveyTitles? surveyTitles[0]._id:""
            }
        })
    }, [surveyTitles,setTimingData]);
  
    useEffect(() => {
        (async () => {
            const stRes = await getAllSurveyTitles();
            if(stRes.success){
                setSurveyTitles(stRes.surveyTitles);
            }
        })()
    }, []);
    

    if(!isOpen){
        return null;
    }

  return (
    <PopupContainer>
        <form className="p-0">
            <div className="mb-3">
                <h1 className='font-bold text-lg text-secondry-color'>Duration</h1>
                <div className="flex flex-col">
                    <label htmlFor="start" className="text-secondry-color">Start: </label>
                    <input 
                    type="date" 
                    name='startDate' 
                    value={timingData?.duration?.start} 
                    className="border px-3 flex-1 rounded-md py-2" 
                    id="start" 
                    required 
                    onChange={handleTimingDataChange}
                    />
                </div>
                <div className="flex flex-col mt-1">
                    <label htmlFor="end" className="text-secondry-color">End: </label>
                    <input 
                    type="date" 
                    name='endDate' 
                    value={timingData?.duration?.end} 
                    className="border px-3 flex-1 rounded-md py-2" 
                    id="end" 
                    required
                    onChange={handleTimingDataChange}
                    />
                </div>
            </div>

            <div className="mb-3 flex flex-col">
                <label htmlFor="time" className="text-secondry-color">Time: </label>
                <input 
                type="time" 
                name='time' 
                value={timingData?.time?.hours +":"+ timingData?.time?.minutes} 
                className="border px-3 flex-1 rounded-md py-2" 
                id="time" 
                onChange={handleTimingDataChange}
                required
                />
            </div>

            <div className="mb-3">
                <label htmlFor="frequency" className="font-bold mb-1 text-secondry-color">Frequency</label>
                <div className="recuring_time_span" id="frequency">
                    <select 
                    id="frequency" 
                    name='frequency' 
                    value={timingData?.frequency} 
                    className="border w-full mb-2 p-2 rounded-md" 
                    required
                    onChange={handleTimingDataChange}
                    >
                        <option value="">Please Select Frequency</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                    </select> 
                </div>
                <div className="recuring_details">
                    {
                        timingData?.frequency && (
                                <>
                                {days.map((d,i)=> {
                                    return <Day timingData={timingData} setTimingData={setTimingData} key={i} day={d} />
                                })}
                                </>
                        )
                    }
                </div>
            </div>

            <div className="my-3 flex flex-col">
                <label htmlFor="frequency" className="font-bold text-secondry-color">Survey Title</label>
                <select value={timingData.surveytitle} onChange={(e)=>{setTimingData((prev:any) => {return {...prev,surveytitle: e.target.value}})}} className="border p-2 rounded-md">
                    {
                    surveyTitles && surveyTitles.map((st:any,index:number) => {
                        return <option value={st._id} key={index} className='text-secondry-color'>{st.title}</option>
                    })
                    }
                </select>
            </div>

            <div className="my-6">
                <div className='flex items-center gap-2 font-bold'><label htmlFor="unRegularSurvey" className="text-secondry-color">Un-Regular Survey</label> <input className='' type="checkbox" checked={isUnRegularSurvey} onChange={()=> setIsUnRegularSurvey(p => !p)}/></div>
                <select value={unRegularSurvey} onChange={(e)=>setUnRegularSurvey(e.target.value)} className="border p-2 rounded-md w-full">
                    {
                    surveyTitles && surveyTitles.filter((s:any) => !s.isUniversal).map((st:any,index:number) => {
                        return <option value={st._id} key={index} className='text-secondry-color'>{st.title}</option>
                    })
                    }
                </select>
            </div>

            <div className='flex gap-2'>
                <PrimaryButton onClick={()=>setIsOpen(false)} style={{padding: "12px 0"}}>Cancel</PrimaryButton>
                <SecondryButton  onClick={onSubmit} style={{padding: "12px 0"}}>{addName}</SecondryButton>
            </div>
        </form>
    </PopupContainer>
  )
}

