import { toast } from "react-toastify";
import { deleteUser } from "../../../Store/actions";

interface IActions {
    user:any;
    setAllUsers:any;
    handdleUpdateUser:any;
  }
  
  export const Actions: React.FC<IActions> = ({user,setAllUsers,handdleUpdateUser}) => {
  
    const handdleDeleteUser = async (id: any)=> {
      console.log("d");
      
      if(window.confirm("Are you sure?")){
              const res = await deleteUser(id);
              if(res.success){
                toast.success(res.message);
                setAllUsers((prev:any) =>{
                  const users = prev.filter((user:any) => user._id !== id);
                  return users;
                })
              }
      }
    }
  
    return (
      <div className='flex gap-1'>
        <button className="rounded-md text-white ml-2 bg-blue-400  px-2 py-1">Start Access</button>
        <button className="rounded-md text-white ml-2 bg-blue-400  px-2 py-1">Stop Access</button>
        <button onClick={() => handdleUpdateUser(user._id)} className="rounded-md text-white ml-2 bg-yellow-400 px-2 py-1">Edit User</button>
        <button onClick={()=>  handdleDeleteUser(user._id)} className="rounded-md mt-1 md:mt-0 text-white ml-2 bg-red-400  px-2 py-1">Delete User</button>
      </div>
    )
  }