import {useEffect,useState} from 'react';
import {useDispatch} from "react-redux";
import {
  getAllUsers,
  updateUser,
  getUserById,
  getAllSurveyTitles
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Main} from "../../layout";
import dayjs from 'dayjs';
import { UpdateUserModel, UsersTable } from './components';



const Index: React.FC<any> = ()  => {
  
  const dispatch = useDispatch();
  const [toggleupdateForm, setToggleupdateForm] = useState(false);
  const [formData, setFormData] = useState<any>({
    email: "",
    forename: "",
    surname: "",
    role: "",
    dob: "",
    profession: ""
  });
  const [allUsers, setAllUsers] = useState<any[]>([]);
  
  // _id for update resources 
  const [_id, set_id] = useState('');


  // update user 
  const handdleUpdateUser = async(id:any)=> {
    set_id(id);
    const res = await getUserById(id);
    const res1 = await getAllSurveyTitles();
    const impactSurveyNames = ["Impact","impact survey"];
    
    
    setFormData(() => {
        return {
        dob: res.user.dob ,
        profession: res.user.profession || "",
        title: res.user.title || "",
        middleNames: res.user.middleNames || "",
        surname: res.user.surname || "",
        forename: res.user.forename || "",
        email: res.user.email,
        userName: res.user.userName || "",
        gender: res.user.gender || "",
        organizationName: res.user.organizationName || "",
        department: res.user.department || "",
        team: res.user.team || "",
        role: res.user.role,
        rank: res.user.rank || "",
        className: res.user.className || "",
        level: res.user.level || "",
        studentNumber: res.user.studentNumber || "",
        mobilePhoneNumber: res.user.mobilePhoneNumber || "",
        landlineNumber: res.user.landlineNumber || "",
        address: res.user.address || "",
        unRegularSurveys: res.user.unRegularSurveys.length !== 0?  res.user.unRegularSurveys:res1.surveyTitles.filter((st:any) => impactSurveyNames.indexOf(st.title) !== -1).map((st:any) => st._id),
        managementGroups: res.user.managementGroups || [],
        postSurveyDate: res.user.postSurveyDate? dayjs(res.user.postSurveyDate).format("YYYY-MM-DDTHH:mm"):'',
        postSurveyId: res.user.postSurveyId || '',
        preSurveyId: res.user.preSurveyId || '',
        onbordingEmailTime: res.user.onbordingEmailTime? dayjs(res.user.onbordingEmailTime).format("YYYY-MM-DDTHH:mm"):''
      }
    })
    setToggleupdateForm(true);
  }

  const handdleUpdateSubmit = async ()=> {
    const res = await updateUser(_id,formData);

    if(res.success){
      toast.success(res.message);
      const users = allUsers.map((user:any) => user._id === _id? {...user,...res.user}:user);
      setAllUsers(users);
      setToggleupdateForm(false);
    }
    else {
      toast.error(res.message);
    }
  }

 
  useEffect(() => {
      async function fetchData() {
        const usersRes = await getAllUsers();
        setAllUsers(usersRes.users);

        // const groupsRes = await getAllGroups();
        // if(groupsRes.success){
        //   setAllGroups(groupsRes.groups);
        // }
      }
      fetchData();
  }, [dispatch]);
  
  
  return (
    <>
    <Main>
        <h2 className="text-secondry-color text-center py-10 text-2xl font-bold">Manage Users</h2>
        
        {
          allUsers && <UsersTable 
                        users={allUsers} 
                        setAllUsers={setAllUsers} 
                        handdleUpdateUser={handdleUpdateUser}
                      />
        }
    </Main>

    {
      toggleupdateForm && <UpdateUserModel handdleUpdateSubmit={handdleUpdateSubmit}  formData={formData} setFormData={setFormData} setIsOpen={setToggleupdateForm}/>
    }
    </>
  )
}

export default Index;



















