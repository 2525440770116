import {BackButton, PrimaryButton, SecondryButton,ProgressBar} from "..";
import { useEffect, useState } from "react";
import { 
    completeActionChallenge, 
    completeStep, 
    createActionChallenge,
    getAllSurveyTitles,
    getUniversalSurveyTitle,
    getUserById,
    setNumberOfActionChallenges
 } from "../../Store/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ConfettiExplosion from 'react-confetti-explosion';
import Joyride from "react-joyride"
import { SECONDRY_BUTTON_COLOR } from "../../constants";
import { Steps } from "./CogniPulsePhySelPhySelfieImpulseProgressModal";
import { useNavigate } from "react-router-dom";
import { StairsGoal } from "../Icons";
import { getUserActivities } from "../../Store/actions/activity";
import { AppDispatch, RootState } from "../../Store/store";
import { Modal } from "./Modal";
import { getActionChallengeByName } from "../../Store/actions/actionChallenge";
import { confettiRise } from "../../assets/sounds";


interface IActionChallengeModal {
    currentActivityName: any;
    show: any;
    setShow: any;
    currentActionChallenge: any;
    factor: any;
    userId: any;
    forTeam: any;
    setShowCoachingScreen?: any;
}

const ActionChallengeModal: React.FC<IActionChallengeModal> = ({setShowCoachingScreen,currentActivityName,show,setShow,currentActionChallenge,factor,userId,forTeam}) => {
    const [actionChallenge, setActionChallenge] = useState<any>(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [completedSteps, setCompletedSteps] = useState(0);
    const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
    const [numberOfCompletedActionChallenges, setNumberOfCompletedActionChallenges] = useState(null);
    const numberOfActionChallenges = useSelector((state:RootState) => state.numberOfActionChallenges);
    const dispatch: AppDispatch = useDispatch();

    const handleAccept = async () => {
        const data = {
            factor,
            impact: "immediate" ,
            subFactor: currentActionChallenge.subFactor,
            actionChallenge: currentActionChallenge.actionChallenge,
            steps: currentActionChallenge.steps.map((step:any) => {return {step}}),
            for: forTeam? 'team':'individual',
            user: userId 
        };

        const res = await createActionChallenge(data);
     
        if(res.success){
            setActionChallenge(res.actionChallenge);
            setIsAccepted(true); 
            dispatch(setNumberOfActionChallenges(numberOfActionChallenges+1));
        }
        else {
            toast.error(res.message);
        }
    }

    const handleStepComplete = async (stepId:string) => {
        const res = await completeStep({actionChallengeId: actionChallenge._id,stepId});

        if(res.success){
            setActionChallenge(res.actionChallenge);
        }
        else {
            toast.error("Someting Went Wrong!");
        }
    }

    const handleChallengeComplete = async () => {
        const res = await completeActionChallenge({actionChallengeId: actionChallenge._id});
        if(res.success){
            setActionChallenge(res.actionChallenge);
            setNumberOfCompletedActionChallenges(res.numberOfCompletedActionChallenges);
        }
        else {
            toast.error("Something Went Wrong!");
        }
    }

    const handleReDo = async (ac:any) => {
        const actionChallenge = {
            factor: ac.factor,
            impact: "immediate" ,
            subFactor: ac.subFactor,
            actionChallenge: ac.actionChallenge,
            steps: ac.steps.map((step:any) => {return {step: step.step}}),
            for: ac.for,
            user: ac.user 
        };

        const res = await createActionChallenge(actionChallenge);
        
        if(res.success){
            setActionChallenge(res.actionChallenge);
            toast.success("You can re-do this task");
        }
        else {
            toast.error("Something went wrong!");
        }
    }

    useEffect(() => {
        if(currentActionChallenge){
            (async ()=>{
                const res = await getActionChallengeByName({actionChallengeName: currentActionChallenge.actionChallenge});

                // console.log(res);
                

                if(res.success && res.actionChallenge){
                    setActionChallenge(res.actionChallenge);
                }
                else {
                    setActionChallenge({
                        ...currentActionChallenge,
                        steps: currentActionChallenge?.steps?.map((step:any) => {return {step}})
                    });
                }
            })()
        }
    }, [currentActionChallenge]);

    useEffect(() => {
        if(actionChallenge){
            const cSteps = actionChallenge.steps !== undefined? actionChallenge?.steps.filter((step:any) => step.isCompleted):[];

            setCompletedSteps(cSteps.length);
            if(actionChallenge.steps && cSteps.length === actionChallenge.steps.length){
                setIsAllStepsCompleted(true); 
            }
            else {
                setIsAllStepsCompleted(false); 
            }
        }
    }, [actionChallenge]);


    if(!show || !actionChallenge){
        return null;
    }

    if(numberOfCompletedActionChallenges){
        return <MyModal setShow={setShow} numberOfCompletedActionChallenges={numberOfCompletedActionChallenges} currentActivityName={currentActivityName}/>
    }
   
    return (
        <>
            <Modal isHeader={false} setIsOpen={setShow}>
                <>
                    <div className="w-full flex justify-start border-b border-secondry-color/20 mb-3 pb-3">
                        <BackButton onClick={()=> setShow(false)}/>
                    </div>
                    <div className="flex flex-col items-center">
                            <h1 className='text-xl font-bold text-secondry-color'>Coaching action</h1>
                            <p className='text-xs text-secondry-color mb-4'>{actionChallenge.actionChallenge}</p>
                            <p className='text-[12px] flex justify-center items-center gap-3 mb-2'>{actionChallenge?._id ? <SecondryButton onClick={()=>{}} disabled={true}>Accepted</SecondryButton>:<PrimaryButton onClick={handleAccept}>I Accept</PrimaryButton>}</p>
                            <div className="w-[50%]"><ProgressBar bgClass={"bg-primary-button-color"} totalSteps={actionChallenge.steps?.length || 0} completedSteps={completedSteps}/></div>
                            
                            <div className="my-10 flex flex-col gap-5">
                                {
                                    actionChallenge.steps.map((step:any,index: number) => (
                                        <div key={index} className="flex justify-between items-center gap-4">
                                            <span className="text-xs md:text-md lg:text-base text-secondry-color font-bold">{step.step}</span>
                                            {
                                                step.isCompleted? <div>
                                                <SecondryButton onClick={()=>{}} style={{padding: "12px 12px"}} disabled={true}>
                                                    Completed
                                                </SecondryButton>
                                                </div>:<div>
                                                <PrimaryButton style={{padding: "12px 12px"}} onClick={()=>handleStepComplete(step._id)} disabled={!actionChallenge?._id}>
                                                    Done
                                                </PrimaryButton>
                                                </div> 
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                            {
                                actionChallenge.isCompleted? <>
                                <PrimaryButton onClick={()=>{}} style={{padding: "12px 0"}} disabled={true}>
                                    Challenge Completed
                                </PrimaryButton>
                                </>:<>
                                <PrimaryButton style={{padding: "12px 0"}} onClick={handleChallengeComplete} disabled={!isAllStepsCompleted}>
                                    Complete
                                </PrimaryButton>
                                </>
                            }

                            {
                                setShowCoachingScreen && <SecondryButton onClick={()=> {setShow(false);setShowCoachingScreen(false)}} style={{marginTop: "10px"}}>Go to Personal Dashboard</SecondryButton>
                            }
                            
                            {
                                actionChallenge.isCompleted && <>
                                    <span className="mt-3">Or</span>
                                    <SecondryButton onClick={()=> {handleReDo(actionChallenge)}} style={{marginTop: "10px"}}>Re-Do</SecondryButton>
                                </>
                            }
                            
                    </div>
                    {
                        isAccepted && <IntroJSTour/>
                    }
                </>
            </Modal>
        </>
    )
}

export {ActionChallengeModal};


function IntroJSTour(){
    
    function Comp(){
        return <>
                Great! You have accepted this coaching action, most people find it is helpful to do it straight away… however if you can’t complete all the steps right now just click on <span className='w-6 h-4 inline-block'><StairsGoal/></span> and you can access it anytime.
              </>
    }
  
    const mySteps = [
      {
        element: '#introSelector12',
        intro: <Comp/>
      }
    ];
  
    const steps = mySteps.map(step => {return {target: step.element,content: step.intro,disableBeacon: true}})
  
    const cb = (state:any) => {
    //   if(state.action === "reset"){
    //     dispatch(updateLoggedInUser({isDashboardTutorialFinished: true}));
    //   }
    //   if(state.action === "close"){
    //     setIsShowOnboarding(false);
    //     setCurrentTourStep(0);
    //     return;
    //   }
    //   setCurrentTourStep(state.index);
    }


    return (
      <Joyride
          callback={cb} 
          steps={steps}
          continuous={true}
          scrollOffset={100}
          spotlightPadding={5}
          locale={{
              last: "Ok"
          }}
          styles={{
            options: {
                zIndex: 2000,
                primaryColor: SECONDRY_BUTTON_COLOR
            }
          }}
      />
    )
}


interface IMyModal {
    numberOfCompletedActionChallenges: any;
    currentActivityName: any;
    setShow: any;
}

const MyModal: React.FC<IMyModal> = ({numberOfCompletedActionChallenges,currentActivityName,setShow}) => {
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [showConfetti, setShowConfetti] = useState<any>();
    const navigation = useNavigate();
    const lineDur = .5;
    const circleDur = .2;
    const total = lineDur+circleDur;
    const timeTillConfetti = 1000*((numberOfCompletedActionChallenges)*total)+1000;

    const handleFetchUnRegularSurvey = async () => {
        const resUser = await getUserById(loggedInUser._id);
        const res = await getAllSurveyTitles();
        const universalRes =  await getUniversalSurveyTitle();
        const resAct = await getUserActivities();
        
        if(resUser.success && res.success && resAct.success){
          const cat = resAct.activities.find((item:any) => item.name === currentActivityName);
          if(cat){
            navigation(`/survey/${loggedInUser._id}/${universalRes.surveyTitle._id}/${new Date().getTime()}?surveyName=${cat.category}&title=${cat.name}&new=true&isPreviousQuestions=true`);
          }
        }
        else {
          toast.error("Something went wrong!");
        }
    }

    useEffect(() => {
        const time = setTimeout(() => {
          setShowConfetti(true);
          confettiRise.play();
          setTimeout(() => {
            setShowConfetti(false);
          }, 3000);
        },timeTillConfetti);
    
        return ()=> {
          clearTimeout(time);
        }
    }, [timeTillConfetti]);

    return <Modal isHeader={false} setIsOpen={setShow}>
              <div className="">
              <h1 className="text-center text-2xl md:text-3xl text-primary-button-color font-bold">
                  Awsome Job, {loggedInUser.forename}!
              </h1>

              {
                showConfetti && <div className="flex justify-center"><ConfettiExplosion zIndex={2001} force={.8} duration={3000} particleCount={250}/></div>
              }

              <p className="text-center font-bold mt-4 text-secondry-color">Coaching Challenge!</p>             
              <p className="text-center mt-2 mb-8 text-secondry-color">You've completed a Coaching action. Keep it up!</p>  

              <div className="mb-8">
                    <Steps 
                        stepsItems={new Array(numberOfCompletedActionChallenges).fill(1)} 
                        currentStep={numberOfCompletedActionChallenges}
                        label={"Today's Coaching Actions"}
                        initialDlay={0}
                        lineDur={lineDur}
                        circleDur={circleDur}
                        isChanged={true}
                    />
              </div>

              <p className="text-sm text-secondry-color text-center mb-2">Take a quick CogniPulse to measure the change in your state & cognitiive potential</p>
              <div className="mb-4">
                <PrimaryButton style={{fontSize: "16px"}} onClick={handleFetchUnRegularSurvey}>Quick CogniPulse (see your change)</PrimaryButton>
              </div>

              <p className="text-sm text-secondry-color text-center">or</p>

              <div className="mt-4">
                <SecondryButton 
                style={{fontSize: "16px"}} 
                onClick={()=>{
                    navigation("/my-dashboard",{state: {time: new Date().getTime()}})
                    setShow(false)
                }}
                >Go to Personal Dashboard</SecondryButton>
              </div>
              </div>
          </Modal>
}