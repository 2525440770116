import { InfoIconWithToolTip } from "../../InfoIconWithToolTip";
import { constrainersInfo, factors } from "../utils";
import { Factor } from "./Factor";

interface IConstrainers {
    Emotives: any;
    Utility: any;
    Apathy: any;
    showRange: any;
    factorsMinMax: any;
    percentageOfChangeOfFactors: any;
    showChange: any;
  }
  


export const Constrainers: React.FC<IConstrainers> = ({Emotives,Utility,Apathy,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}) => {
  
    const facs = [Emotives,Utility,Apathy];
  
    return <>
            <div  className="flex flex-col items-center border-4 border-secondry-color rounded p-2">
              
              <div className="flex justify-around w-full">
                {
                    factors.constrainers.map((factor,idx) => {
                      const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                      return <div id={`introSelector${factor.factor}Constrainers`} key={idx} className="flex flex-col items-center">
                              <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                                <Factor showChange={showChange} showRange={showRange} key={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={true} min={factorsMinMax.min[factor.factor]} max={factorsMinMax.max[factor.factor]} change={change}/>
                              </div>
                              <div className="flex justify-center gap-9 w-full">
                                  <div key={factor.toolTip} className='relative z-[1] text-xs flex items-center gap-1 text-secondry-color'>
                                    <span>{factor.factor}</span> 
                                    <InfoIconWithToolTip content={factor.toolTip}/>
                                  </div>
                              </div>
                            </div>
                    })
                }
              </div>
              
              <p className='text-center mb-0 font-bold pt-2 flex justify-center items-center gap-2 text-accent-color'><span>Constrainers</span> <InfoIconWithToolTip content={constrainersInfo}/></p>
            </div>
    </>
}