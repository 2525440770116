
interface IDay {
    day: any;
    setTimingData: any;
    timingData: any;
}

export const Day: React.FC<IDay> = ({day,setTimingData,timingData}) => {
    
    const weekDays = ["S","M","T","W","T","F","S"];

  
    const handdleDayChange = (d:any)=>{
        if(timingData.frequency === "weekly"){
            setTimingData((prev:any)=>{
                const index = prev.days.indexOf(d);
                console.log(prev);
                return {
                    ...prev,
                    days: index === -1? [...prev.days,d]:prev.days.filter((item:any) => item !== d)
                }
            })
        }
    }


    return (
        <div onClick={(e)=> handdleDayChange(day)} className={timingData.days.indexOf(day) !== -1? "selected-day":""}>{weekDays[day]}</div>
    )
}