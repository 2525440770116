import { collabrativeFlow, deepFocusFlow, innovationFlow, productiveFlow, strategicFlow } from "../../../assets";

export const flowStates = [
    {
      title: "Creative Flow",
      description: "Dive into brainstorming, innovation, and out-of-the-box thinking, allowing your creativity to flourish.",
      url: innovationFlow
    },
    {
      title: "Productive Flow",
      description: "Get into the zone for rapid task completion, meeting deadlines, and high adaptability.",
      url: productiveFlow
    },
    {
      title: "Collaborative Flow",
      description: "Connect seamlessly with your team, facilitating discussions and efficiently delegating tasks.",
      url: collabrativeFlow
    },
    {
      title: "Strategic Flow",
      description: "Immerse yourself in planning, decision-making, and long-term strategy formulation with clarity and foresight.",
      url: strategicFlow
    },
    {
      title: "Deep Focus Flow",
      description: "Engage in tasks that require intense concentration and critical thinking, shutting out distractions.",
      url: deepFocusFlow
    }
  ]


  export const XLFactorsTooltipText = "These are the key influences shaping your XL Score, a dynamic blend of drivers and constrainers that influence your well-being and performance.";