function orderOptions(arr:any,item:any){
    const newArray = arr.map((opt:any) => {
        if(opt.option === item.option && opt.isSelected){
            return {...opt,isSelected: false,order: null};
        }
        if(opt.option === item.option && !opt.isSelected){
            return {...opt,isSelected: true,order: null};
        }
        return opt;
    });
    const max = newArray.reduce((acc:any,it:any) => {
        if(it.order && it.order > acc){
            return it.order;
        }
        return acc;
    },0);
    const result = newArray.map((opt:any) => (opt.option === item.option && opt.isSelected)? {...opt,order: max+1}:opt);
    return result;
}


export {orderOptions}