import React, { useState } from 'react'
import { Main } from '../../../layout'
import { Header, Wrapper } from './index';
import { PrimaryButton } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoggedInUser } from '../../../Store/actions';
import { useScrollToTop } from "../../../hooks";
import { AppDispatch, RootState } from '../../../Store/store';


interface ISetOrganisationName {
    setScreen: any;
}

export const SetOrganisationName: React.FC<ISetOrganisationName> = ({setScreen}) => {
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [organizationName, setOrganizationName] = useState(loggedInUser.organizationName || "");
    const dispatch: AppDispatch = useDispatch();
    useScrollToTop();

  const handleClick = () => {
      dispatch(updateLoggedInUser({organizationName}));

    if(loggedInUser.isPreSurveyDone){
        setScreen((prev:any) => {
            return {
                ...prev,
                organisationName: false,
                welcome: true
            }
        });
    }
    else {
        setScreen((prev:any) => {
            return {
                ...prev,
                organisationName: false,
                baiseline: true
            }
        });
    }
  }

  const handleBack = () => {
    setScreen((prev:any) => {
        return {
            ...prev,
            enterActivityTitle: true,
            organisationName: false,
        }
    });
  }

  return (
    <Main>
            <Wrapper>
                <Header handleBack={handleBack} title="Personalise"/>
                        
                <p className='mb-6 text-secondry-color'>We'd Like to Personalise Your Experience</p>
                {/* <p className='mb-6 text-secondry-color'>This helps us tailor your experience and provide relevant organisational insights</p> */}

                <div className='flex flex-col text-secondry-color mb-8'>
                    <label htmlFor='organizationName' className='mb-2 font-bold' >Please enter the name of your organisation</label>
                    <input id='organizationName' placeholder='e.g. Acme Corporation' className='bg-secondry-color/20 border border-secondry-color rounded px-3 py-1' type='text' value={organizationName} onChange={(e)=> setOrganizationName(e.target.value)}/>
                    <small className='text-secondry-color/50 mt-1'>Your organisation name helps us personalise your experience. Your data is secure</small>
                </div>
                        
                <PrimaryButton style={{padding: "12px 0"}} onClick={handleClick} disabled={!organizationName? true:false}>
                    Submit Organisation Name
                </PrimaryButton>
            </Wrapper>
    </Main>
  )
}
