import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
    Wrapper, 
    ArcSliderWrapper, 
    Header
} from "./index";
import { capitalized } from "../../../utils";
import { Chart as ChartJS} from "chart.js/auto";
import 'chartjs-adapter-moment';
import { Line } from "react-chartjs-2";
import dayjs from 'dayjs';
import { PrimaryButton,InfoIconWithToolTip} from "../../../components";
import { onBoardUser, updateLoggedInUser } from "../../../Store/actions";
import { toast } from "react-toastify";
import {Main} from "../../../layout";
import ReactDOMServer from 'react-dom/server';
import {personalJourneyMap} from "../../../assets";
import { useRef } from "react";
import { useScrollToTop } from "../../../hooks";
import { AppDispatch, RootState } from "../../../Store/store";
ChartJS.register([]);
const image = new Image();
image.src = personalJourneyMap;


interface IFinalProjection {
    setScreen: any;
}

export const FinalProjection: React.FC<IFinalProjection> = ({setScreen}) => {
    const [myData, setMyData] = useState<any>(null);
    const [noOfXLRateInteractionDaysAWeek, setNoOfXLRateInteractionDaysAWeek] = useState(2);
    const [improvementGoalAsPercentage, setImprovementGoalAsPercentage] = useState(20);
    const [noOfMinutesForXLRateADay, setNoOfMinutesForXLRateADay] = useState(2);
    const [isSubmiting,setIsSubmiting] = useState(false);
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const customRatio = document.body.clientWidth >= 600? 9:12;
    const navigation = useNavigate();
    const dispatch:AppDispatch = useDispatch();
    const overlay = useRef<any>(null);
    useScrollToTop();

    
   
    const customScaling = (value:any, maxValue:any)  => {
        // if(maxValue === 30){
        //     if (value <= 24) {
        //         // First segment: mapping 0-80 to 0-20
        //        return (6 / 24) * value;
        //     } 
        //     else {
        //        // Second segment: mapping 80-100 to 20-100
        //       return 6 + ((30 - 6) / (30 - 24)) * (value - 24);
        //     }
        // }

        // if (value <= 80) {
        //     // First segment: mapping 0-80 to 0-20
        //    return (20 / 80) * value;
        // } 
        // else {
        //    // Second segment: mapping 80-100 to 20-100
        //   return 20 + ((100 - 20) / (100 - 80)) * (value - 80);
        // }

        return value;
    }
    
    const handleChange = (value:any,name:any) => {
        if(name === '1'){
            setNoOfXLRateInteractionDaysAWeek(value);
        }
        if(name === '2'){
            setImprovementGoalAsPercentage(value);
        }
        if(name === '3'){
            setNoOfMinutesForXLRateADay(value);
        }
    }

    const handleBack = () => {
        setScreen((prev:any) => {
            return {
                ...prev,
                welcome: true,
                finalProjection: false
            }
        })
    }

    const handleNext = async () => {
        dispatch(updateLoggedInUser({isFinalProjectionSeen: true}));

        setIsSubmiting(true);
        const finalData = {
            noOfXLRateInteractionDaysAWeek: Number(noOfXLRateInteractionDaysAWeek.toFixed(0)),
            improvementGoalAsPercentage: Number(customScaling(improvementGoalAsPercentage,100).toFixed(0)),
            noOfMinutesForXLRateADay: Number(customScaling(noOfMinutesForXLRateADay,30).toFixed(0))
        };

        dispatch(updateLoggedInUser(finalData));




        const onBoardRes = await onBoardUser(loggedInUser._id);

        console.log(onBoardRes);
        

        if(onBoardRes && onBoardRes.success){
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true,isOnboardingDone: true}))
        }
        if(onBoardRes && onBoardRes.success){
            navigation("/manage/notifications",{state: {link: onBoardRes.link}});
        }
        if(onBoardRes && !onBoardRes.success){
            toast.error(onBoardRes.message);
        }
        setIsSubmiting(false);

    }
    
    useEffect(() => {
        const startingPerformanceLevel = 100;
        const percentageIncreasePerInteraction = .001*customScaling(noOfMinutesForXLRateADay,30);
        const XLRateInteractionsPerDay = 1;
        let lastDetails:any = undefined;
        let dayNumber = 1;
    
        const tempData = new Array(90).fill(1).map((item,idx) => {
            const date = dayjs().add(idx,"day");
            const x = (1+percentageIncreasePerInteraction/XLRateInteractionsPerDay);
            const y = XLRateInteractionsPerDay*dayNumber;
            const performanceImprovement = startingPerformanceLevel*Math.pow(x,y);
            const greenLine = performanceImprovement-startingPerformanceLevel;
            const orangeLine = customScaling(improvementGoalAsPercentage,100);
            const weekDay = date.day() || 7;

            if(lastDetails && weekDay > noOfXLRateInteractionDaysAWeek){
                return {
                    ...lastDetails,
                    date: new Date(date.clone().toISOString())
                };
            }

            const detail = {
                date: new Date(date.clone().toISOString()),
                greenLine,
                orangeLine
            };

            lastDetails = detail;
            dayNumber++;

            return detail;
        });


        const d = {
             labels: tempData.map(item => item.date),
             datasets: [
                  {
                    label: "Improvement w/ XLRate",
                    data: tempData.map(item => item.greenLine),
                    backgroundColor: '#00FF00',
                    pointRadius: 0,
                    borderColor: "#00FF00",
                    clip: {left: 0, top: 0, right: 0, bottom: 0},
                    datalabels: {
                        labels: {
                          title: null
                        }
                    }
                  },
                //   {
                //     label: "Your Improvement Goal",
                //     data: tempData.map(item => item.orangeLine),
                //     backgroundColor: '#FFA500',
                //     pointRadius: 0,
                //     borderColor: "#FFA500",
                //     datalabels: {
                //         labels: {
                //           title: null
                //         }
                //     }
                //   }
             ]
        }

        setMyData(d);
    }, [noOfXLRateInteractionDaysAWeek,improvementGoalAsPercentage,noOfMinutesForXLRateADay]);

    return (<>
            <Main>
                <Wrapper>
                {/* <ProgressBarAndBackButton handleBack={handleBack} completedSteps={4}/> */}
                <Header title="Your Personal Journey Map" handleBack={handleBack}/>

                <div className="h-[74vh] md:h-[75vh] overflow-auto">
                    <p className='text-secondry-color text-xl font-bold mb-6'>{capitalized(loggedInUser?.forename)}, your personal development journey is ready!</p>

                    <p className='text-secondry-color font-bold'>Here’s what’s in it for you:</p>
                    <ul className='list-disc ml-6'>
                        <li className='text-secondry-color mt-2'>A powerful personal development coach in your pocket that will: 
                            <ul className='list-disc ml-6'>
                                {
                                    [
                                        `empower you to achieve your personal development goals like: ${loggedInUser.personalImprovementGoals?.join(", ")}`,
                                        `guide you to overcome the things that are challenging you, like: ${loggedInUser.personalImprovementObstacles?.join(", ")}`,
                                        "Supercharge your performance and enhance your wellbeing through continuous personal improvement",
                                    ].map((item,idx) => {
                                        return <li key={idx} className='text-secondry-color mt-2'>{item}</li>
                                    })
                                }
                            </ul>
                        </li>
                    </ul>

                    <p className='text-secondry-color text-md mt-6 mb-2 font-bold'>See how you can supercharge your performance and enhance your wellbeing through continuous personal improvement by:</p>

                    <div className="flex flex-col justify-center items-center md:flex-row md:items-start  gap-1">
                        {/* <div className="flex flex-col items-center flex-1">
                            <p className='text-secondry-color mb-0'>1</p>
                            <p className='text-secondry-color relative z-[10] mb-0 text-[10px] px-2 flex items-center gap-2 gap-2 relative z-20'>Choose your improvement goal. <InfoIconWithToolTip content={"Tip:  Slide the dial to the improvement % you need over the next 12 months (i.e. the improvement you feel you need achieve your personal goals at work)"}  id="dial-1-tooltip" /></p>
                            <ArcSliderWrapper name="2" handleChange={handleChange} description={`A ${customScaling(improvementGoalAsPercentage,100).toFixed(0)}% improvement (over the next year) would empower me to achieve my goals`} value={improvementGoalAsPercentage} minValue={3} maxValue={100}/>
                        </div> */}
                        <div className="flex flex-col items-center flex-1">
                            <p className='text-secondry-color mb-0'>1</p>
                            <p className='text-secondry-color mb-0 text-[10px] px-2 flex items-center gap-2 gap-2 relative z-20'>Choose days per week <InfoIconWithToolTip content={"Tip:  Most people choose 5 days a week"}  id="dial-2-tooltip" /></p>
                            <ArcSliderWrapper name="1" handleChange={handleChange} description={`I will interact with XLRate ${noOfXLRateInteractionDaysAWeek.toFixed(0)} days a week`} value={noOfXLRateInteractionDaysAWeek} minValue={1} maxValue={7}/>
                        </div>
                        <div className="flex flex-col items-center flex-1">
                            <p className='text-secondry-color mb-0'>2</p>
                            <p className='text-secondry-color mb-0 text-[10px] px-2 flex items-center gap-2 relative z-20'>Choose number of times per day <InfoIconWithToolTip content={ReactDOMServer.renderToStaticMarkup(<ChooseHowLongTooltip/>)}  id="dial-3-tooltip"/></p>
                            <ArcSliderWrapper name="3" handleChange={handleChange} description={`I will do ${customScaling(noOfMinutesForXLRateADay,30).toFixed(0)} of CogniPulse Coaching interactions per day`} value={noOfMinutesForXLRateADay} minValue={1} maxValue={12}/>
                        </div>
                    </div>

                    <h3 className='text-secondry-color text-md text-center mt-6 font-bold'>Projected Progress</h3>
                    <p className='text-secondry-color text-md mb-0 text-center font-bold'>Compounded Improvement over 90 days</p>

                    <div style={{width: "100%"}} className='pt-2 pb-3 relative' >
                        {
                            myData && <Line 
                                    data={myData}
                                    options={{
                                        scales: {
                                            x: {
                                                type: 'timeseries',
                                                ticks: {
                                                    callback: function(value:any, index, ticks){
                                                        if(index === 0){
                                                            return "Today"
                                                        }
                                                        return this.getLabelForValue(value).split(",")[0]
                                                    }
                                                }
                                            },
                                            y: {
                                                max: 100,
                                                min: 0,
                                                ticks: {
                                                    callback: function(value,index,ticks){
                                                        return `${value} %`;
                                                    }
                                                },
                                                title: {
                                                    display: true,
                                                    text: "Personal Improvement",
                                                    font: {
                                                        family: 'sans-serif',
                                                        size: 18,
                                                        style: 'normal',
                                                        lineHeight: 1.2
                                                    },
                                                }
                                            }
                                        },
                                        aspectRatio: 16 / customRatio,
                                        plugins: {
                                            legend: {
                                                position: "bottom",
                                                align: "center",
                                                labels: {
                                                    font: {
                                                        size: 11
                                                    },
                                                    boxWidth: 11
                                                }
                                            },
                                            // datalabels: {
                                            //     color: 'blue',
                                            //     labels: {
                                            //       title: {
                                            //         font: {
                                            //           weight: 'bold'
                                            //         }
                                            //       },
                                            //       value: ""
                                            //     }
                                            // }
                                        }
                                    }}
                                    plugins={[
                                        {
                                            id: 'customCanvasBackgroundImage',
                                            beforeDraw: (chart) => {
                                                if (image.complete) {
                                                const {chartArea} = chart;
                                                overlay.current.style.width = `${chartArea.width}px`;
                                                overlay.current.style.height = `${chartArea.height}px`;
                                                overlay.current.style.top = `${chartArea.top+35}px`;
                                                overlay.current.style.left = `${chartArea.left-55}px`;
                                                // const x = chartArea.left + chartArea.width / 2 - image.width / 2;
                                                // const y = chartArea.top + chartArea.height / 2 - image.height / 2;
                                                // ctx.drawImage(image,x,y);
                                                } 
                                                else {
                                                    image.onload = () => chart.draw();
                                                }
                                            }
                                        }
                                    ]}
                                    />
                        }
                        <div ref={overlay} className="absolute top-0 left-0 w-full h-full">
                            <img src={personalJourneyMap} alt="aa" className="w-[100%] h-[100%]"/>
                        </div>
                    </div>

                    <p className='text-secondry-color font-bold'>Within 2-6 weeks you’ll start noticing:</p>

                    <ul className='list-disc pb-6 mb-0 ml-6'>
                        {
                            [
                                "Personal development progress",
                                "Improved sense of well-being",
                                "Greater self-awareness",
                                "Increased fulfilment",
                                "Increased sense of autonomy",
                                "Increased sense of resilience"
                            ].map((item,idx) => {
                                return <li key={idx} className='text-secondry-color'>{item}</li>
                            })
                        }
                    </ul>
                </div>

                <div className="">
                    <PrimaryButton style={{padding: "12px 0"}} disabled={isSubmiting} onClick={handleNext} >
                        {isSubmiting? "Saving..":"Next"}
                    </PrimaryButton>
                </div>
                </Wrapper>  
            </Main>
        </>)
}


export const ChooseHowLongTooltip: React.FC<any> = () => {


    return (
        <div className="">
            <p className="pb-2 font-bold text-xs">Tip:  This is time you give yourself to ‘sharpen the saw’ that is your potential.</p>
            <li className="pb-1 text-xs">1-2 mins: Track Your Psychological State - (i.e. Understand your current mindset) </li>
            <li className="pb-1 text-xs">+1-2 mins: Capture an XL Snapshot - (i.e. a psychological selfie, to capture valuable insights you'll miss or forget otherwise) </li>
            <li className="pb-1 text-xs">+1-2 mins:  Review Your Dashboard - (i.e.  build resilience, and gain valuable insights) </li>
            <li className="pb-1 text-xs">+5-10 mins: Complete a Personalized Action Challenge - (i.e. Boost your state, enhancing performance & well-being)</li>
        </div>
    )
}