import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getUserActivities = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities`,"GET",{});

    return res;
}

interface IActivity {
    category: string,
    name: string
}

export const createActivity = async (activity:IActivity | IActivity[]) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities`,"POST",activity);

    return res;
}

interface Ibody {
    category?: string;
    name?: string;
    purpose?: string[];              
    goal?: string[];
    dayOrWeekGoal?: string;
    shouldDisplayDayOrWeekGoalInCognipuse?: boolean;
}

export const updateActivity = async (activityid: string,body: Ibody) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities/${activityid}`,"PUT",body);

    return res;
}

// export const updateGoal = async (activityid:string,goal: string[]) => {

//     const res = await fetchRequest(`${DOMAIN_NAME}/activities/goal/${activityid}`,"PUT",{goal});

//     return res;
// }

// export const updateDayOrWeekGoal = async (activityid:string,dayOrWeekGoal: string) => {

//     const res = await fetchRequest(`${DOMAIN_NAME}/activities/day-or-week-goal/${activityid}`,"PUT",{dayOrWeekGoal});

//     return res;
// }

// export const updateCategoryAndName = async (activityid:string,category:string,name:string) => {

//     const res = await fetchRequest(`${DOMAIN_NAME}/activities/category-name/${activityid}`,"PUT",{category,name});

//     return res;
// }