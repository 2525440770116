import { useState } from "react";
import { PrimaryButton } from "../../../components"
import { toast } from "react-toastify";
import {CrossSVG} from "../../../components";


interface IOptions  {
    tag: any;
    options: any;
    handleClick: any;
    setOptions: any;
}

export const Options: React.FC<IOptions> = ({tag,options,handleClick,setOptions}) => {

    const handleRemoveYourOption = (e:any,item:any) => {
        e.stopPropagation();
        setOptions((prev:any) => {
            if(item.isSelected){
                return prev.map((it:any) => (it.order && it.order > item.order)? {...it,order: it.order-1}:it).filter((it:any) => it.option !== item.option);
            }
            return prev.filter((it:any) => it.option !== item.option);
        });
    }

    return (
        <>
        <div className="flex border border-secondry-color/40 p-2 rounded-lg flex-col gap-3 mt-4 h-[39vh] md:h-[45.5vh] overflow-auto">
                {
                    options.map((item:any,idx:number) => (
                        <div key={idx}>
                            {
                                (options[idx-1] === undefined || options[idx-1]?.tag !== item.tag) && <div>
                                    <h2 className="mb-3 font-bold text-secondry-color">{item.tag}</h2>
                                </div>
                            }
                            {
                               item.option !== null && <div onClick={()=>handleClick(item)} className={`ml-10 ${item.isSelected? "bg-secondry-color/50 text-primary-color":"bg-secondry-color/20"} p-3 rounded-lg flex gap-5 items-center bg-primary-color cursor-pointer text-secondry-color`}>
                                                            {
                                                                item.tag === tag && <>
                                                                    <span className={`w-4 h-4 text-[10px] flex justify-center items-center rounded ${item.isSelected? "text-[18px] text-primary-color":"border border-accent-color"}`}>{item.isSelected? item.order:""}</span>
                                                                    <div className=" flex-1 flex items-center justify-between gap-2">
                                                                        <span className='pr-2'>{item.option}</span>
                                                                        <span onClick={(e)=> handleRemoveYourOption(e,item)} className="h-4 h-4 bg-white rounded-md p-[2px]" title="remove"><CrossSVG onClick={() =>{}}/></span>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                item.tag !== tag && <>
                                                                    <span className={`w-4 h-4 text-[10px] flex justify-center items-center rounded ${item.isSelected? "text-[18px] text-primary-color":"border border-accent-color"}`}>{item.isSelected? item.order:""}</span>
                                                                    <span className={`pr-2 flex-1 ${item.isSelected? "text-primary-color":""}`}>{item.option}</span>
                                                                </>
                                                            }
                                                        </div>
                            }
                            
                        </div>
                    ))
                }

        </div>
        <AddOption tag={tag} setOptions={setOptions}/>
        </>
    )
}


interface IAddOption  {
    setOptions: any;
    tag: any;
}


const AddOption: React.FC<IAddOption> = ({setOptions,tag}) => {
    const [value, setValue] = useState("");

    const handleAddOption = () => {
        const newOption = {
            tag,
            order: null,
            isSelected: false,
            option: value
        }

        setOptions((prev:any) => {
            const isPresent = prev.find((item:any) => item.option === newOption.option && item.tag === newOption.tag);
            if(isPresent){
                toast.error("Duplicate Option")
                return prev;
            }
            const index = prev.findIndex((item:any,idx:number) => (item.tag === newOption.tag && (prev[idx+1] || prev[idx+1]?.tag !== newOption.tag)));
            const dupArray = [...prev];
            dupArray.splice(index,0,newOption);
            return dupArray;
        })
        setValue("")
    }

    return (
        <div className={`mt-3 mb-3 bg-secondry-color/20 p-2 rounded-lg flex gap-2 items-center bg-primary-color cursor-pointer text-secondry-color`}>
            <input onChange={(e)=>setValue(e.target.value)} value={value} type="text" className="rounded-md px-2 py-2.5 w-full flex-1" placeholder={tag === "Your Personal Goals"? "Add your own goals…":"Add personal barriers…"}/>
            <PrimaryButton disabled={value? false:true} onClick={handleAddOption} style={{width: "60px"}}>Add</PrimaryButton>
        </div>
    )
}