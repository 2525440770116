import {user} from "./user";
import {globalStates} from "./globalStates";
import {auth} from "./auth";
import {numberOfActionChallenges} from "./numberOfActionChallenges";
import {previousRoute} from "./previousRoute";


const rootReducer = {
    user: user,
    globalStates: globalStates,
    loggedInUser: auth,
    numberOfActionChallenges: numberOfActionChallenges,
    previousRoute: previousRoute
}


export default rootReducer;