import { useState } from "react";
import { XL_FACTORS } from "../../../constants";
import { FactorWrapper } from "./FactorWrapper";

interface IActionChallengeWraper {
    survey: any;
    actionChallenges: any;
    universalSurveytitle: any;
    setActionChallenges: any;
  }
  
  export const ActionChallengeWraper: React.FC<IActionChallengeWraper> = ({survey,actionChallenges,universalSurveytitle,setActionChallenges}) => {
  
    const [visivility,setVisivility] = useState(false);
  
    const handleShowHide= ()=> {
      setVisivility(prev => !prev)
    }
  
    return (
    <>
    <div className="border-b pb-2">
                <div className="flex justify-between">
                  <div>
                    {survey}
                  </div>
                  <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input onChange={handleShowHide} type="checkbox" value="" className="sr-only peer" checked={visivility}/>
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{visivility?"Hide":"Show"}</span>
                    </label>
                  </div>
                </div>
                <div className={`flex bg-gray-100 p-4 rounded flex-col gap-3 ${visivility? "":"hidden"}`}>
                {
                    XL_FACTORS.map(XLFactor => {
  
                        const ac = actionChallenges.find((ac:any) => ac.XLFactor === XLFactor && ac.survey === survey);
  
                        return <FactorWrapper 
                          key={XLFactor} 
                          XLFactor={XLFactor}
                          actionChallenges={ac} 
                          universalSurveytitle={universalSurveytitle}
                          setActionChallenges={setActionChallenges}
                        />
                      })
                }
                </div>
              </div>
    </>
    )
  };
  