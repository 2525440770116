import { toast } from "react-toastify";
import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getAllWaitingList = async () =>{
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/waiting-lists`,"GET",{});

        return res;
    } catch (error:any) {
        toast.error(error.message);
    }
}


export const createWaitingList = async (data:any) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/waiting-lists`,"POST",data);

    return res;
}

export const deleteWaitingList = async (waitingListId:string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/waiting-lists/${waitingListId}`,"DELETE",{});

    return res;
}

export const getNumberOfPeopleAreWaiting = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/waiting-lists/number-of-people`,"GET",{});

    return res;
}




