export const arrayToObject = (arr: any[]) => {
      //        const temp1 = {};
  //         ca.purpose?.forEach((p,i) => {
  //          temp[keys[i]] = p;
  //         });
  //         ca.goal?.forEach((g,i) => {
  //           temp1[keys[i]] = g;
  //         });
  //         // console.log(temp);
  //         // console.log(temp1);
  //         setPurpose(temp);

  const keys = ["_1", "_2", "_3", "_4", "_5", "_6", "_7", "_8", "_9","_10","_11"];

  const obj:any = {}

  arr.forEach((item:any,idx:any) => {
     const key:any = keys[idx];
     obj[key] = item;
  });

  return obj;
};


