import {
    SET_NUMBER_OF_ACTION_CHALLENGES
} from "../../constants";
import { AppDispatch } from "../store";




export const setNumberOfActionChallenges = (numberOfActionChallenges: any) => async (dispatch: AppDispatch) => {

    try {
          dispatch({type: SET_NUMBER_OF_ACTION_CHALLENGES ,payload: numberOfActionChallenges});

    } catch (error) {
    }
}