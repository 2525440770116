import { useEffect, useState } from "react";
import { getUserById, updateUser } from "../../../Store/actions";
import { toast } from "react-toastify";
import { PopupContainer } from "../../../layout";
import { CrossSVG, Loading, SecondryButton } from "../../../components";
import { DashboardStatus, EmptyField, ManagerGroupsOrTeams, OnboardingEmailReceived, OnBoardingStatus, OneLineField, PersonalSurveys, UpdateUserModel } from "../../Users/components";

  interface IViewUserModal {
    setVisilivity: any;
    selectedGroup: any;
  }
  
  export const ViewUserModal: React.FC<IViewUserModal> = ({setVisilivity,selectedGroup}) => {
    const [user, setUser] = useState<any>(null);
    const [error, setError] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState<any>();
  
    
    const handleUpdate = () => {
      setFormData(() => {
        return {
        dob: user.dob ,
        profession: user.profession || "",
        title: user.title || "",
        middleNames: user.middleNames || "",
        surname: user.surname || "",
        forename: user.forename || "",
        email: user.email,
        userName: user.userName || "",
        gender: user.gender || "",
        organizationName: user.organizationName || "",
        department: user.department || "",
        team: user.team || "",
        role: user.role,
        rank: user.rank || "",
        className: user.className || "",
        level: user.level || "",
        studentNumber: user.studentNumber || "",
        mobilePhoneNumber: user.mobilePhoneNumber || "",
        landlineNumber: user.landlineNumber || "",
        address: user.address || "",
        unRegularSurveys: user.unRegularSurveys || "",
        managementGroups: user.managementGroups || [],
      }
      });
      setIsEditing(true);
    }
  
    const handdleUpdateSubmit = async ()=> {
      const res = await updateUser(selectedGroup.data._id,formData);
  
      if(res.success){
        toast.success(res.message);
        setUser(res.user);
        setIsEditing(false);
      }
      else {
        toast.error(res.message);
      }
    }
  
    useEffect(() => {
      (async () => {
        setisLoading(true);
        const res = await getUserById(selectedGroup?.data?._id);
        setisLoading(false);
        
        if(res.success){
          setUser(res.user);
        }
        else {
          setError(res.message);
        }
      })()
    }, [selectedGroup?.data?._id]);
  
    if(isLoading){
      return <PopupContainer>
                <div className="flex justify-end">
                  <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG onClick={()=>{}}/></div>
                </div>
                <Loading/>
             </PopupContainer>
    }
  
    if(error){
      return <PopupContainer>
                <div className="flex justify-end">
                  <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG onClick={() => {}}/></div>
                </div>
                <div className="min-h-[200px] flex justify-center items-center">
                  <span className="text-red-800 bg-red-50 px-2 rounded-md"><span className="font-bold text-red-600">Error:</span> {error}</span>
                </div>
             </PopupContainer>
    }
  
    if(!user){
      return <PopupContainer>
                  <div className="flex justify-end">
                    <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG onClick={()=>{}}/></div>
                  </div>
                  <div className="min-h-[200px] flex justify-center items-center">
                    <span className="text-yellow-600 bg-yellow-50 px-2 rounded-md">User Not Found</span>
                  </div>
              </PopupContainer>
    }
  
    return (
      <div className="position fixed top-[64px] w-full left-0 min-h-[calc(100vh-64px)] px-10 py-10 bg-primary-color">
        <div className="flex justify-end pb-4 mb-4 border-b border-secondry-color">
          <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG onClick={()=>{}}/></div>
        </div>
        
        {
          isEditing && <UpdateUserModel setIsOpen={setIsEditing} formData={formData} setFormData={setFormData} handdleUpdateSubmit={handdleUpdateSubmit}/>
        }
        {
          !isEditing && <>
            <div className="flex flex-col gap-3">
            {
                [user].map((user,idx) => (
                  <div key={idx} className="pb-4 flex flex-wrap border-b">
                      {
                          ["Dob","Profession","Title","MiddleNames","Surname","Forename","Email","User Name","Gender","Organisation","Department","Team","Role","Rank","Class Name","Level","Student Number","Mobile Phone Number","Landline Number","Address","No. Survey User","Personal Surveys","Manager Groups/Teams","Received Onboarding Email","On Boarding Status","Dashboard Tour Status","Actions"].map((item,idx1) => (
                            <div key={idx1} className="p-2 px-3 bg-secondry-color/10 mb-1">
                                <p className="mb-1 font-bold text-sm text-secondry-color whitespace-nowrap">{item}</p>
                                {
                                  item === "Profession" && <OneLineField user={user} fieldName="profession"/>
                                }
                                {
                                  item === "Dob" && <OneLineField user={user} fieldName="dob"/>
                                }
                                {
                                  item === "Title" && <OneLineField user={user} fieldName="title"/>
                                }
                                {
                                  item === "MiddleNames" && <OneLineField user={user} fieldName="middleNames"/>
                                }
                                {
                                  item === "Surname" && <OneLineField user={user} fieldName="surname"/>
                                }
                                {
                                  item === "Forename" && <OneLineField user={user} fieldName="forename"/>
                                }
                                {
                                  item === "Email" && <OneLineField user={user} fieldName="email"/>
                                }
                                {
                                  item === "User Name" && <OneLineField user={user} fieldName="userName"/>
                                }
                                {
                                  item === "Gender" && <OneLineField user={user} fieldName="gender"/>
                                }
                                {
                                  item === "Organisation" && <OneLineField user={user} fieldName="organizationName"/>
                                }
                                {
                                  item === "Department" && <OneLineField user={user} fieldName="department"/>
                                }
                                {
                                  item === "Team" && <OneLineField user={user} fieldName="team"/>
                                }
                                {
                                  item === "Role" && <OneLineField user={user} fieldName="role"/>
                                }
                                {
                                  item === "Rank" && <OneLineField user={user} fieldName="rank"/>
                                }
                                {
                                  item === "Class Name" && <OneLineField user={user} fieldName="className"/>
                                }
                                {
                                  item === "Level" && <OneLineField user={user} fieldName="level"/>
                                }
                                {
                                  item === "Student Number" && <OneLineField user={user} fieldName="studentNumber"/>
                                }
                                {
                                  item === "Mobile Phone Number" && <OneLineField user={user} fieldName="mobilePhoneNumber"/>
                                }
                                {
                                  item === "Landline Number" && <OneLineField user={user} fieldName="landlineNumber"/>
                                }
                                {
                                  item === "Address" && <OneLineField user={user} fieldName="address"/>
                                }
                                {
                                  item === "No. Survey User" && (user?.activities?.length || <EmptyField/>)
                                }
                                {
                                  item === "Personal Surveys" && <PersonalSurveys user={user}/>
                                }
                                {
                                  item === "Manager Groups/Teams" && <ManagerGroupsOrTeams user={user}/>
                                }
                                {
                                  item === "On Boarding Status" && <OnBoardingStatus user={user}/>
                                }
                                {
                                  item === "Received Onboarding Email" && <OnboardingEmailReceived user={user}/>
                                }
                                {
                                  item === "Dashboard Tour Status" && <DashboardStatus user={user}/>
                                }
                                {
                                  item === "Actions" && <div><SecondryButton onClick={handleUpdate}>Edit User</SecondryButton></div>
                                }
                            </div>
                          ))
                      }
                  </div>
                )) 
            }
            </div>
          </>
        }
      </div>
    )
  }