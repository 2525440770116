export const questions = new Array(20).fill(1).map((v,i) => i+1);
export const tableHeaders = [
  "#",
  "User Name",
  "User Email",
  "Org.",
  "Dept.",
  "Group",
  "S. Title",
  "Creation",
  "Survey Dur",
  ...questions,
  "Exclude"
]