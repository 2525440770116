import { useEffect, useState } from "react";
import {defaultRadioValues} from "../constants";
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { 
    createNotification, 
    updateNotification } from "../../../Store/actions";
import { InputSelect } from "./InputSelect";
import { RadioButton } from "./RadioButton";
import { PrimaryButton, SecondryButton } from "../../../components";
import { PopupContainer } from "../../../layout";
import { getUserActivities } from "../../../Store/actions/activity";


interface INotificationModal {
    setIsShowModal: any;
    setNotifications: any;
    notificationToEdit: any;
    setNotificationToEdit: any;
}


export const NotificationModal: React.FC<INotificationModal> = ({setIsShowModal,setNotifications,notificationToEdit,setNotificationToEdit}) => {
    const [activities, setActivities] = useState<any>(null);
    const [radios, setRadios] = useState(() => {
        return defaultRadioValues.map(day => {
            if(notificationToEdit){
                const d = notificationToEdit.days.find((d:any) => d.day === day.day);
                if(!d){
                    return day;
                }
                return {...day,times: d.times.map((time:any) => `${time.hour}:${time.minute}`),isChecked: true}
            }
            return day;
        });
    });
    const [dates] = useState({startDate: setInputDates(notificationToEdit?.startDate) || "2024-07-03",endDate: setInputDates(notificationToEdit?.endDate) || "2024-12-04"});
    

    
    function setInputDates(date:any){
        if(date){
            return dayjs(date).format("YYYY-MM-DD");
        }
        return null;
    }

    const handleSubmit = async (e:any) => {
        e.preventDefault();

        if(!dates.startDate || !dates.endDate){
            toast.error("Mising Start or End Date");
            return;
        }
        const selectedActivity = activities.find((act:any) => act.isSelected);
        if(!selectedActivity){
            toast.error("Mising Activity");
            return;
        }
        const selectedDay = radios.find(item => item.isChecked);
        if(!selectedDay){
            toast.error("Select at least one day");
            return;
        }

        const arrargeDays = (day:any) => {
            const times = day.times.map((time:any) => {
                let t = time.split(":");
                let hour = Number(t[0]);
                let minute = Number(t[1]);
                const dateTime = new Date();
                dateTime.setHours(hour);
                dateTime.setMinutes(minute);
                return {hour,minute,time: dateTime};
            });
            return {day: day.day,times};
        }

        const data:any = {
            startDate: dates.startDate,
            endDate: dates.endDate,
            days: radios.filter(day => day.isChecked).sort((a,b) => a.day - b.day).map(arrargeDays),
            activityName: selectedActivity.name,
            activityCategory: selectedActivity.category,
        };

        if(selectedActivity.isNonUniversalSurvey){
            data.surveytitle = selectedActivity._id;
            delete data.activityCategory;
            delete data.activityName;
        }

        if(notificationToEdit){
            const res = await updateNotification(notificationToEdit._id,data);
            if(res.success){
                toast.success("Notification Updated Successfuly!");

                setNotifications((prev:any) => {
                    return prev.map((noti:any) => noti._id === res.notification._id? {...res.notification}:noti);
                });

                setIsShowModal(false);
                setNotificationToEdit(null);
            }
            else {
                toast.error(res.message);
            }
            return;
        }

        const res = await createNotification(data);
        
        
        if(res.success){
            toast.success("Notification Created Successfuly!");
            setNotifications((prev:any) => {
                return [...prev,res.notification]
            });
            setIsShowModal(false);
            setNotificationToEdit(null);
        }
        else {
            toast.error(res.message);
        }
    }

    const handleCancel = (e:any) => {
        e.preventDefault();
        setIsShowModal(false);
        setNotificationToEdit(null);
    }

    useEffect(() => {
        (async () => {
            const res = await getUserActivities();
            if(res?.success){
                const act = res.activities.map((ac:any) => {
                    if(notificationToEdit){
                        if(notificationToEdit.activityCategory === ac.category){
                            return {...ac,isSelected: true};
                        }
                    }
                    return {...ac,isSelected: false}
                })
                setActivities(act)
            }
            else {
                toast.error("Internal Server Error!");
                setIsShowModal(false);
            }
        })()
    }, [setIsShowModal,notificationToEdit]);

    if(!activities){
        return null;
    }

    return <>
        <PopupContainer>
                <form className='w-full'>
                    <InputSelect notificationToEdit={notificationToEdit} activities={activities} setActivities={setActivities}/>
                    {/* <InputWithLabel label='Start Date' name="startDate" value={dates.startDate} onChange={handleChange}/>
                    <InputWithLabel label='End Date' name="endDate" value={dates.endDate} onChange={handleChange}/> */}
                    <RadioButton radios={radios} setRadios={setRadios}/>
                    <div className='flex gap-4'>
                        <PrimaryButton style={{padding: "12px 0"}} onClick={handleCancel}>
                            Cancel
                        </PrimaryButton>
                        <SecondryButton style={{padding: "12px 0"}} onClick={handleSubmit}>
                            {notificationToEdit? 'Update':'Create'}
                        </SecondryButton>
                    </div>
                </form>
        </PopupContainer>
    </>
}