import { useEffect, useRef } from "react";
import { Chart as ChartJS} from "chart.js/auto";
import dayjs from "dayjs";

interface IMyChart {
    setCurrentIndex: any;
    setBrushSelection: any;
    chart: any;
    setChart: any;
    streamGraphChartData: any;
    events: any;
    setEventData: any;
    setEventId: any;
    setIsUpdateEvent: any;
    setIsOpenAddEventsModal: any;
}
  
  export const MyChart: React.FC<IMyChart> = ({setCurrentIndex,setBrushSelection,chart,setChart,streamGraphChartData,events,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal}) => {
    const chartRef = useRef<any>(null);
    
  
    function handeDoubleClick(){
        chart.resetZoom();
    }
  
    function handleOnClick(e:any){
        const points = chart.getElementsAtEventForMode(e,'nearest',{intersect: true},true);
        if(!points[0]){
          return;
        }
        const data = points[0];
        if(!data){
            return;
        }
        // if user click on event circle 
        if(data.datasetIndex === 0){
            const {index} = data;
            const event = events[index];
            setEventData(event);
            setEventId(event._id);
            setIsUpdateEvent(true);
            setIsOpenAddEventsModal(true);
            return;
        }
    }
  
    useEffect(() => {
        let chart = undefined;
        if(streamGraphChartData){
            
            const getOrCreateTooltip = (chart:any) => {
                let tooltipEl = chart.canvas.parentNode.querySelector('div');
              
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                  tooltipEl.style.borderRadius = '3px';
                  tooltipEl.style.color = 'white';
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.transform = 'translate(-50%, 0)';
                  tooltipEl.style.transition = 'all .1s ease';
              
                  const table = document.createElement('table');
                  table.style.margin = '0px';
              
                  tooltipEl.appendChild(table);
                  chart.canvas.parentNode.appendChild(tooltipEl);
                }
              
                return tooltipEl;
            };
              
            const externalTooltipHandler = (context:any) => {
                // Tooltip Element
                const {chart, tooltip} = context;
                const tooltipEl = getOrCreateTooltip(chart);
  
                if(!tooltip?.dataPoints){
                  return;
                }
                if(!tooltip?.dataPoints[0]){
                  return;
                }
                if(!tooltip?.dataPoints[0].dataset){
                  return;
                }
                if(!tooltip?.dataPoints[0].dataset.label){
                  return;
                }
  
                if(tooltip?.dataPoints[0]?.dataset?.label !== "Events" && tooltip?.dataPoints[0]?.dataset?.label !== "Action Challenges"){
                    return;
                }
              
                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }
              
                // Set Text
                if (tooltip.body) {
                  const titleLines = tooltip.title || [];
                  const bodyLines = tooltip.body.map((b:any) => b.lines);
              
                  const tableHead = document.createElement('thead');
              
                  titleLines.forEach((title:any,idx:number) => {
                    title = tooltip.dataPoints[idx].raw.x;
                    const tr:any = document.createElement('tr');
                    tr.style.borderWidth = 0;
              
                    const th:any = document.createElement('th');
                    th.style.borderWidth = 0;
                    const text = document.createTextNode(title);
              
                    th.appendChild(text);
                    tr.appendChild(th);
                    tableHead.appendChild(tr);
                  });
              
                  const tableBody = document.createElement('tbody');
                  bodyLines.forEach((body:any, i:number) => {
                    const colors = tooltip.labelColors[i];
              
                    const span = document.createElement('span');
                    span.style.background = colors.backgroundColor;
                    span.style.borderColor = colors.borderColor;
                    span.style.borderWidth = '2px';
                    span.style.marginRight = '10px';
                    span.style.height = '10px';
                    span.style.width = '10px';
                    span.style.display = 'inline-block';
              
                    const tr:any = document.createElement('tr');
                    tr.style.backgroundColor = 'inherit';
                    tr.style.borderWidth = 0;
              
                    const td:any = document.createElement('td');
                    td.style.borderWidth = 0;
  
                    if(tooltip.dataPoints[i].dataset.label === "Events"){
                      const rawData = tooltip.dataPoints[i].raw;
                      const text = document.createTextNode(rawData.description);
                      td.appendChild(span);
                      td.appendChild(text);
                      rawData.tags.forEach((tag:any) => {
                        const button = document.createElement("button");
                        button.innerText = tag;
                        button.style.backgroundColor = "blue";
                        button.style.marginLeft = "2px";
                        button.style.marginBottom = "2px";
                        button.style.padding = "0px 10px";
                        button.style.color = "#ffffff";
                        button.style.borderRadius = "5px"
                        td.appendChild(button)
                      });
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                    else if(tooltip.dataPoints[i].dataset.label === "Action Challenges"){
                      const rawData = tooltip.dataPoints[i].raw;
                      const text = document.createTextNode(rawData.actionChallenge);
                      td.appendChild(span);
                      td.appendChild(text);
                      rawData.steps.forEach((step:any) => {
                        const button = document.createElement("button");
                        button.innerText = step.step;
                        button.style.backgroundColor = "blue";
                        button.style.marginLeft = "2px";
                        button.style.marginBottom = "2px";
                        button.style.padding = "0px 10px";
                        button.style.color = "#ffffff";
                        button.style.borderRadius = "5px"
                        td.appendChild(button)
                      });
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                    else {
                      const text = document.createTextNode(body);
              
                      td.appendChild(span);
                      td.appendChild(text);
                      tr.appendChild(td);
                      tableBody.appendChild(tr);
                    }
                  });
              
                  const tableRoot = tooltipEl.querySelector('table');
              
                  // Remove old children
                  while (tableRoot.firstChild) {
                    tableRoot.firstChild.remove();
                  }
              
                  // Add new children
                  tableRoot.appendChild(tableHead);
                  tableRoot.appendChild(tableBody);
                }
              
                const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
              
                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.background = '#ffffff';
                tooltipEl.style.color = '#000000';
                tooltipEl.style.border = '1px solid #000000';
                tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                tooltipEl.style.font = tooltip.options.bodyFont.string;
                tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
            };
  
  
            chart = new ChartJS(chartRef.current, {
                type: 'bar',
                data: streamGraphChartData,
                options: {
                    scales: {
                    x: {
                          type: 'time',
                          ticks: {
                          autoSkip: true,
                          autoSkipPadding: 50,
                          maxRotation: 0,
                        },
                    },
                    y: {
                          stacked: true,
                          ticks: {
                                        callback: function(ctx:any,i:number){
                                            return i*10;
                                        }
                          },
                          min: -100,
                          max: 100
                    }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler
                        },
                        zoom: { 
                            limits: {
                                y: {min: -100,max: 100,minRange: 0}
                            },
                            pan: {
                                enabled: true,
                                modifierKey: 'ctrl',
                                mode: 'x',
                                onPanComplete: function(e){
                                    const chart:any = e.chart;
                                    const selection = {
                                        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                    };
                                    if(chart.getZoomLevel() === 1){
                                        chart.update();
                                        setBrushSelection(selection);
                                        return;
                                    }
                                    const startTick = chart.scales.x.ticks[0];
                                    const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                    const majorTicks = chart.data.labels.map((item:any,index:number) => {return {year: item,index}}).filter((item:any) => dayjs(item.year).isBetween(dayjs(startTick.value),dayjs(endTick.value)));
                                    const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
     
                                    if(highlightedTick){
                                        chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                        chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                        chart.update();
                                        setCurrentIndex(highlightedTick.index);
                                    }
                                    setBrushSelection(selection);
                                }
                            },
                            zoom: {
                                drag: {
                                    enabled: true,
                                    backgroundColor: "#00000055"
                                },
                                pinch: {
                                    enabled: true
                                },
                                mode: 'x',
                                onZoomComplete: function(e:any){
                                    const chart = e.chart;
                                    const selection = {
                                        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                    };
                                    if(chart.getZoomLevel() === 1){
                                        chart.update();
                                        setBrushSelection(selection);
                                        return;
                                    }
                                    const startTick = chart.scales.x.ticks[0];
                                    const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                    const majorTicks = chart.data.labels.map((item:any,index:number) => {return {year: item,index}}).filter((item:any) => dayjs(item.year).isBetween(dayjs(startTick.value),dayjs(endTick.value)));
                                    const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
     
                                    if(highlightedTick){
                                        chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                        chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                        chart.update();
                                        setCurrentIndex(highlightedTick.index);
                                    }
                                    setBrushSelection(selection);
                                }
                            }
                        },
                        legend: {
                                            labels: {
                                            boxWidth: 10,
                                            boxHeight: 10
                                            },
                        },
                        annotation: {
                            annotations: {
                                line1: {
                                    type: 'line',
                                    yMin: -100,
                                    yMax: 100,
                                    xMin: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                    xMax: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                    borderColor: '#000000',
                                    borderWidth: 2,
                                }
                            }
                        }
                    }
                }
            });
            setChart(chart);
        }
        return () => {
            if(chart){
                chart.destroy();
            }
        }
    }, [streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setChart,setBrushSelection,setCurrentIndex]);
  
  
    if(!streamGraphChartData){
        return null;
    }
  
  
    return <canvas ref={chartRef} onDoubleClick={handeDoubleClick} onClick={handleOnClick}></canvas>
  }
  