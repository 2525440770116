import React from 'react';
import {deleteSurveyTitle} from "../../../Store/actions";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import { RootState } from '../../../Store/store';


interface ISurvey {
  surveyTitle: any;
  handleUpdate: any;
  setAllSurveyTitles: any;
}

const Survey: React.FC<ISurvey> = ({surveyTitle,handleUpdate,setAllSurveyTitles}) => {
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);

  const handdleDeleteSurvey = (id:any)=>{
    if(window.confirm("Are you sure?")){
      deleteSurveyTitle(id);
      setAllSurveyTitles(null);
    }
  }


  return (
            <form className='border border-secondry-color/10 p-2 rounded-md'>
              <div className="mb-3">
                <h6 className="flex justify-between">
                  <span>Title</span>
                  <span className='flex gap-2'>
                    <span className="border border-secondry-color/10 px-2 py-1 rounded-md" onClick={()=>handleUpdate(surveyTitle?._id)}>Update</span>
                    <span className="border border-secondry-color/10 px-2 py-1 rounded-md" onClick={()=> handdleDeleteSurvey(surveyTitle?._id)} >Delete</span>
                  </span>
                </h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle?.title}</p>
              </div>
              <div className="mb-3">
                <h6>consentform</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle?.consentform}</p>
              </div>
              <div className="mb-3">
                <h6>briefingform</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle?.briefingform}</p>
              </div>
              <div className="mb-3">
                <h6>debriefingform</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle?.debriefingform}</p>
              </div>
              <div className="mb-3">
                <h6>Email Subject</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle.email? surveyTitle.email.subject:''}</p>
              </div>
              <div className="mb-3">
                <h6>Email Body</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle.email? surveyTitle.email.body:''}</p>
              </div>
              <div className="mb-3">
                <h6>Submitted Survey Screen</h6>
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle.submittedsurveyscreen||''}</p>
              </div>
              <div className="mb-3">
                <h6>Survey Question Instruction</h6>
                <br/>Visibility : {surveyTitle.surveyquestioninstruction? surveyTitle.surveyquestioninstruction.isvisible:'false'}
                <p className='bg-secondry-color/10 mt-2 p-2'  id="question">{surveyTitle.surveyquestioninstruction? surveyTitle.surveyquestioninstruction.instruction:''}</p>
              </div>
              <div className="mb-3" style={{overflowWrap: "anywhere"}}>
                <h6>Link Preview</h6>
                <Link  to={`/survey/`+ loggedInUser._id + `/`+surveyTitle._id+`/`+new Date().getTime()} >{`${window.location.origin}/survey/`+ loggedInUser._id + `/`+surveyTitle._id+`/`+new Date().getTime()}</Link>
              </div>
              <select className="w-full p-2" id="questio-type" aria-label="Default select example">
                {
                  surveyTitle?.questions?.map((ques:any,i:number)=>{
                    return <option key={i} value={ques.XLFactor}>{ques.question}</option>
                  })
                }
              </select>
            </form>
  )
}

export default Survey