import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { FACTOR_COLORS, XL_FACTORS } from '../../../constants';
import { Chart as ChartJS} from "chart.js/auto";

interface IStreamGraph {
    data: any;
    hideLabels: any;
}

export const StreamGraph: React.FC<IStreamGraph> = ({data,hideLabels}) => {
    const chartRef = useRef(null);
    const [chartData,setChartData] = useState<any>(null);
    
    useEffect(() => {
        let c = undefined;
        if(chartRef.current && chartData){
            c = new ChartJS(chartRef.current, {
                type: 'bar',
                data: chartData,
                options: {
                    scales: {
                    x: {
                        type: 'time',
                        ticks: {
                            autoSkip: true,
                            autoSkipPadding: 50,
                            maxRotation: 0,
                        },
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            callback: function(ctx,i){
                                return i*10;
                            }
                        },
                        min: -100,
                        max: 100
                    }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: hideLabels? false:true
                        }
                    }
                }
            });
        }

        return () => {
            if(c){
                c.destroy();
            }
        };
    }, [chartData,data.historiclData,hideLabels]);


    useEffect(() => {
        if(data.historiclData || data.impactSurveyData){
            setChartData({
                labels: data.historiclData.map((item:any) => new Date(item.year)),
                datasets: [
                    {
                        type: "line",
                        label: "CogniFlow",
                        data: data.impactSurveyData?.map((ev:any) => {return {x: new Date(ev.createdAt),y: ev.cogniFlow}}),
                        borderColor: "#B453FB",
                        backgroundColor: "#B453FB",
                        stack: 'stack1',
                        pointRadius: 0,
                        tension: .4,
                        hidden: true
                    },
                    {
                        type: 'line',
                        label: "XLScore",
                        data: data.historiclData.map((item:any) => {return {x: new Date(item.year),y: item["XLScore"]}}),
                        borderColor: FACTOR_COLORS["XLSCORE"],
                        backgroundColor: FACTOR_COLORS["XLSCORE"],
                        pointRadius: 0,
                        fill: false,
                        stack: 'combined',
                        tension: .4
                      },
                      ...XL_FACTORS.slice(0,6).map(factor => {
                          return {
                            type: 'line',
                            label: factor,
                            data: data.historiclData.map((item:any) => {return {x: new Date(item.year),y: item[factor]}}),
                            borderColor: FACTOR_COLORS[factor.toLocaleUpperCase() as keyof typeof FACTOR_COLORS],
                            backgroundColor: FACTOR_COLORS[factor.toLocaleUpperCase() as keyof typeof FACTOR_COLORS],
                            fill: true,
                            pointRadius: 0,
                            tension: .4
                          }
                      })
                ]
            })
        }
    }, [data.historiclData,data.impactSurveyData]);
    
    if(!data){
        return <div className='w-full h-full flex justify-center items-center text-4xl font-bold text-secondry-color'>No Data</div>
    }

    if(data?.historiclData?.length === 1){
        return <>
        <div className='absolute top-0 left-0 w-full h-full backdrop-blur-[1px] rounded-md '>
            <div className='w-full h-full flex justify-center items-center text-center text-4xl font-bold text-secondry-color'>
                Not Enough Data to Show
            </div>
        </div>
        <canvas ref={chartRef}/>
        </>
    }

    return <canvas ref={chartRef}/>
}
