import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cogniPulse } from "../../../assets";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { getAllSurveyTitles, getUniversalSurveyTitle, getUserById } from "../../../Store/actions";
import { getUserActivities } from "../../../Store/actions/activity";
import { useState } from "react";
import { Processing } from "./Processing";


interface ICogniPulseButton {
    selectedActivity: any;
}

export const CogniPulseButton: React.FC<ICogniPulseButton> = ({selectedActivity}) => {
    const navigator = useNavigate();
    const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
    const [isLoading, setIsLoading] = useState(false);

    const handleCognipulse = async (e:any) => {
        e.preventDefault();

        if(isLoading){
            return;
        }
        setIsLoading(true);

        const resUser = await getUserById(loggedInUser._id);
        const res = await getAllSurveyTitles();
        const universalRes =  await getUniversalSurveyTitle();
        const resAct = await getUserActivities();
        

        if(resUser.success && res.success){
          const cat = resAct.activities.find((item:any) => item.name === selectedActivity.name);
          navigator(`/survey/${loggedInUser._id}/${universalRes.surveyTitle._id}/${new Date().getTime()}?surveyName=${cat.category}&title=${cat.name}&new=true`);
        }
        else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }

    return <>
        <div className={`rounded-md border-secondry-color/10 flex-1 flex flex-col items-center p-2 gap-2`} title='cogniPulse'>
            {
                !isLoading && <>
                    <img onClick={handleCognipulse}  src={cogniPulse} alt='cogniPulse' className='h-[50px] cursor-pointer'/>
                    <span onClick={handleCognipulse}  className='cursor-pointer'>CogniPulse</span>
                </>
            } 
            {
              isLoading && <Processing isCircular={true}/>
            }
        </div>
    </>
}