import { PrimaryButton } from "../../../components";

interface IViewDashboardUI {
    handdleViewDashboard: any;
    selectedUsers: any;
  }
  
  export const ViewDashboardUI: React.FC<IViewDashboardUI> = ({handdleViewDashboard,selectedUsers}) => {
  
    if(selectedUsers.length === 0){
      return null;
    }
  
    return <>
        <div className={"flex gap-2 items-center pr-2"}>
          <p className="mb-0">{selectedUsers[0].groupname}</p>
          <p className="mb-0">{selectedUsers[0]?.userSurveys[0]}</p>
          <PrimaryButton style={{width: "150px",padding: "12px 0"}} onClick={handdleViewDashboard}>View dashboard</PrimaryButton>
        </div>
    </>
  }