import React,{useEffect,useState} from 'react';
import {CompletedSurvey, FilterOptions, SomethingNotFound} from "./components";
import {
  getAllPrePostSurveys,
  getAllUsersSurnameForename,
  getAllGroups,
  getAllSurveyTitles
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Loading, Tbody, Th, Thead, Tr} from "../../components"; 
import {Main} from "../../layout"; 
import {Table} from "../../components";
import { CSVLink } from 'react-csv';
import { prepareCSVData } from './utils';
import { questions, tableHeaders } from './constants';






const Index: React.FC<any> = ()  => {

  const [completedSurveys,setCompletedSurveys] = useState<any>(null);
  const [users,setUsers] = useState([]);
  const [groups,setGroups] = useState([]);
  const [surverTitles,setSurverTitles] = useState([]);
  const [excludedFields,setExcludedFields] = useState<any>([]);
  const [loading,setLoading] = useState(true);
  const [surveyFound,setSurveyFound] = useState(false);
  const [isShowData,setIsShowData] = useState(false);
  const [query,setQuery] = useState<any>({
    all: false,
    showExcluded: false,
  });
  const headerStr = JSON.stringify(tableHeaders.filter((item,i: number) => excludedFields.indexOf(i+1) === -1));
  const bodyStr = completedSurveys? JSON.stringify(
    completedSurveys.map((survey:any,i:number) => {
      const group:any = groups.find((gp:any) => {
        const user = gp.users?.find((user:any) => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })

      const arr = [
        i+1,
      `${survey?.user?.forename}${survey?.user?.surname}`,
      survey?.user?.email,
      "org",
      "dept",
      group?.groupname || "NA",
      survey?.surveytitle?.title,
      new Date(survey?.createdAt).toLocaleString(),
      ...questions.map(num => {
          const ans = survey.answers.find((an:any) => Number(an.XLFactor) === num);
          if(!ans){
            return "NA"
          }
          return mapRange(ans.rating,1,7,0,100)
      })
      ]

      return arr.filter((item,i) => excludedFields.indexOf(i+1) === -1);
    })
  ):"";
  const csvData = prepareCSVData(completedSurveys,groups);


  function mapRange(value:any, inMin:any, inMax:any, outMin:any, outMax:any) {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  const handleInclueExcludeNumber = (number:number) => {
    setExcludedFields((prev:any) => {
      const isExist = prev.find((n:any) => n === number)
      if(isExist){
        return prev.filter((n:any) => n !== number);
      }
      return [...prev,number]
    })
  }

  const  downloadTxtFile = ()=> {
    const text = `${headerStr}${bodyStr}`;
    const filename = "sample_file.txt";
  
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
  
    element.style.display = "none";
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }


  useEffect(() => {
      (async ()=>{
        setLoading(true);

        let queryStr = "";
        const arr = Object.keys(query);
        for (let index = 0; index < Object.keys(query).length; index++) {
          const key = arr[index];  
          queryStr += `${key}=${query[key]}${arr.length-1 > index? "&&":""}`;
        }

        const res = await getAllPrePostSurveys(queryStr);
      
        setLoading(false);
        if(res.success){
          const temp = res.prePostSurveys.sort((a:any,b:any)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
        
          setCompletedSurveys(temp);
          setSurveyFound(temp.length > 0? true:false);
        }
        else {
          toast.error("Someting Went Wrong!")
        }
        
      })()
  }, [query]);

  useEffect(() => {
    (async ()=> {
      const res = await getAllUsersSurnameForename();
      if(res.success){
        setUsers(res.users);
      }
      const res1 = await getAllGroups();
      if(res1.success){
        setGroups(res1.groups);
      }
      const res2 = await getAllSurveyTitles();
      if(res2.success){
        const unRegularSurveys = res2.surveyTitles.filter((s:any) => !s.isUniversal);
        setSurverTitles(unRegularSurveys);
      }
    })()
  }, []);

  if(loading){
    return <Loading/>
  }

  
  return (
    <>
    <Main>
        <FilterOptions setQuery={setQuery} users={users} surverTitles={surverTitles} groups={groups}/>
        <div className="flex gap-3">
          {
            bodyStr !== "[]" && <button onClick={downloadTxtFile} className='bg-blue-600 text-white rounded-md px-4 py-1'>Download .txt File</button>
          }
          {
            (bodyStr !== "[]" && csvData) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={csvData} className='text-white no-underline'>Download .csv File</CSVLink></button>
          }
          {
            bodyStr !== "[]" && <button onClick={()=> setIsShowData(p => !p)} className='bg-blue-600 text-white rounded-md px-4 py-1'>{isShowData? "Hide":"Show"} Data</button>
          }
        </div>
        <div className="">
          {
            (bodyStr !== "[]" && isShowData) &&
              <>
               <div className="mt-5 flex flex-wrap gap-2">
                {
                  tableHeaders.map((val,i) => (
                    <span  onClick={()=>handleInclueExcludeNumber(i+1)} style={{backgroundColor: excludedFields.indexOf(i+1) === -1? "":"red"}}  className='cursor-pointer bg-blue-900 text-white rounded-md p-1 text-[10px]'>{val}</span>
                    ))
                  }  
                </div>
                { headerStr }
                { bodyStr }
              </>
          }
        </div>
        
        {
          !surveyFound && <SomethingNotFound someting={"Surveys"}/>
        }
        {
          (!loading && surveyFound)  &&  <> 
          <div className="w-full mt-5">
            <Table>
                <Thead>
                  <Tr>
                    {
                      tableHeaders.map((th,idx) => <Th key={idx}><>{th}</></Th>)
                    }
                  </Tr>
                </Thead>
                <Tbody>
                    {completedSurveys && completedSurveys.map((item:any,index:number)=> {
                                                      const group = groups.find((gp:any) => {
                                                        const user = gp.users?.find((user:any) => user._id === item?.user?._id);
                                                        if(user){
                                                          return gp
                                                        }
                                                        return undefined 
                                                      })
                                                      return <CompletedSurvey 
                                                      survey={item} 
                                                      key={index} 
                                                      index={index}
                                                      group={group}
                                                      />
                    })}
                </Tbody>
            </Table>
          </div>
          </>
        }
    </Main>
    </>
  )
}

export default Index;




