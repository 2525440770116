import React from 'react';
import { PrimaryButton} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoggedInUser } from '../../../Store/actions';
import {Main} from "../../../layout";
import { Header } from './index';
import { useScrollToTop } from "../../../hooks";
import { AppDispatch, RootState } from '../../../Store/store';


interface IIntro {
    setScreen: any;
}

export const Intro: React.FC<IIntro> = ({setScreen}) => {
    const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
    const dispatch: AppDispatch = useDispatch();
    useScrollToTop();

    const handleNext = async () => {
        dispatch(updateLoggedInUser({isIntroSeen: true}));
        setScreen((prev:any) => {
            return {
                ...prev,
                personalImprovementGoals: true,
                intro: false
            }
        })
    }

    return (
        <Main>
            <div className="w-full min-h-[calc(100vh-64px)] max-w-3xl mx-auto py-8">
                <Header handleBack={() => {}} title='Welcome'/>
                <div className="space-y-4 h-[75vh] overflow-auto">
                            <div className="flex justify-center pb-3">
                                <img src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png" alt="logo" className='w-[400px] max-w-full'/>
                            </div>

                            <p className="text-secondry-color leading-relaxed">
                                Welcome to <span className="text-accent-color leading-tight">XLRate</span>  {loggedInUser.forename},
                            </p>
                            <p className="font-bold text-secondry-color leading-relaxed">
                                Your Journey to Optimised Cognitive Performance Starts Here
                            </p>
                            <p className="text-secondry-color leading-relaxed">
                                 Welcome to a transformative experience designed exclusively for visionaries and high-performance professionals. XLRate is your unique solution for mastering the science of cognitive optimisation, enabling you to balance operational tasks with strategic thinking effectively.
                            </p>

                            <p className="font-bold text-secondry-color leading-relaxed">
                                Why XLRate?
                            </p>
                            <ul className='list-disc ml-6'>
                                <li className='text-secondry-color -mt-2'><span className='font-bold'>Maximised Productivity through Flow:</span> Enhance your focus and efficiency dramatically. Research shows that being in a flow state can increase productivity by up to 500%.</li>
                                <li className='text-secondry-color'><span className='font-bold'>Enhanced Creativity:</span> Reach and maintain your creative peak with tools that ensure you stay ahead in innovation.</li>
                                <li className='text-secondry-color'><span className='font-bold'> Accelerated Learning:</span> Develop new skills faster with advanced insights that keep you agile in a dynamic market.</li>
                                <li className='text-secondry-color'><span className='font-bold'> Advanced Problem-Solving:</span> Transform challenges into opportunities with enhanced problem-solving capabilities facilitated by flow states.</li>
                            </ul>

                            <p className="font-bold text-secondry-color ">
                                Optimise Your Cognitive States Throughout the Day
                            </p>
                            <p className="text-secondry-color leading-relaxed">
                                XLRate provides real-time insights and personalised feedback to help you adjust your work approach to match your most effective cognitive states. This capability enhances your personal productivity and improves your effectiveness in a leadership role by enabling better decision-making and team management.
                            </p>


                            <h4 className='font-bold text-secondry-color mt-6'>Experience the Next Level of Performance and Leadership:</h4>
                            <ul className='list-disc ml-6'>
                                <li className='text-secondry-color -mt-2'><span className='font-bold'>Cognitive Blueprint Mapping:</span> Tailor your mental performance optimally for critical tasks using our sophisticated machine-learning techniques.</li>
                                <li className='text-secondry-color'><span className='font-bold'> Real-Time Feedback:</span> Continuously monitor your cognitive performance, similar to how a fitness tracker monitors physical health.</li>
                                <li className='text-secondry-color'><span className='font-bold'> Customised Strategic Coaching: </span> Receive coaching that aligns seamlessly with your schedule and professional growth, ensuring constant development.</li>
                                <li className='text-secondry-color'><span className='font-bold'> Flow State Optimisation:</span>  Learn to achieve and maintain peak mental states to boost your operational effectiveness and strategic thinking.</li>
                            </ul>

                            <p className="font-bold text-secondry-color ">
                                Ready to Elevate Your Performance?
                            </p>
                            <p className="text-secondry-color leading-relaxed pb-8">
                                Join the XLRate community and take the first step towards unparalleled personal and professional success.
                            </p>   
                </div>
                <div className="max-w-4xl mx-auto justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                        Get Started
                    </PrimaryButton>
                </div>
            </div>
        </Main>
    )
}












