

interface IPrimaryButton {
  children: JSX.Element[] | JSX.Element | string;
  onClick?: any;
  style?: React.CSSProperties;
  disabled?: boolean;
  id?: string;
}

export const PrimaryButton: React.FC<IPrimaryButton> = ({children,onClick,style={},disabled=false,id=""}) => {


  return (
    <button id={id} onClick={onClick} disabled={disabled} style={style} className={`bg-[#001f3f] w-full px-2 text-base md:text-lg md:px-4 py-2 rounded-md text-white hover:shadow hover:shadow-[#001f3f] focus:bg-[#001f3f]/70 disabled:bg-[#001f3f]/60 disabled:text-primary-color disabled:hover:shadow-none disabled:cursor-not-allowed`}>
        {
            children && children
        }
        {
            !children && "Primary Button"
        }
    </button>
  )
}