import { toast } from "react-toastify";
import { deleteGroup } from "../../../Store/actions";
import { getAllUsers } from "./utils";
import { Add, Delete, Edit, Eye, User } from "../../../components/Icons";



export const Node: React.FC<any> = ({ node,style, dragHandle,tree }) => {
    const {
      handleAddGroupClick,
      handleEditGroupClick,
      handleAddSurveyTimingClick,
      handleUpdateSurveyTimingClick,
      handleEditBatchUsersClick,
      handleDashboardToggleClick,
      treeData,
      setTreeData,
      scheduledTimes,
      showUserNames,
      setShowViewUserPopup,
      loggedInUser
    } = tree.props;
    const isEdit = scheduledTimes? scheduledTimes?.find((t:any) => t.group === node.data._id):null;
    const {users} = getAllUsers(node);
    const trmp = [...users].every((el:any) => el.canAccessDashboard); 
    const canAccessDashboard = trmp
    // const [canAccessDashboard, setCanAccessDashboard] = useState(trmp);

    const onDeleteSubmit = async (node:any)=> {
        if(!window.confirm("Are you sure?")){
          return;
        }

        const groupId = node.data._id;
        const res = await deleteGroup(groupId);
        
        if(res.success){
          treeData.forEach((root:any) => {
            function visitTree(d:any){
              const isChildOf = d.children?.find((child:any) => child._id === groupId);
              if(isChildOf){
                const remaningChild = d.children.filter((child:any) => child._id !== groupId)
                d.children = remaningChild;
              }
              if(d.children){
                d.children.forEach((child:any) => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
          toast.success("Group Deleted Successfully!");
        }
        else {
          toast.success("Error While Deleting Group!");
        }
        
    }

    const handleDashboardViewChange = (e:any) => {
      // setCanAccessDashboard(p=>!p)
      handleDashboardToggleClick(e);
    }

    function OnboardingStatus({status}:any){

      if(!status){
        return ""
      }

      const steps = ["hasCompletedFirstLogin","isIntroSeen","isPreSurveyDone","isWelcomeSeen","isPersonalImprovementGoalsSeen","isPersonalImprovementObstaclesSeen","isFinalProjectionSeen","isEnterActivityTitleSeen","hasCompletedFirstSurvey","hasSeenDashboardFirstTime"];

      if(!node.data.hasCreatedNewPassword){
        return <>
        <span title="Onboarding" >On</span>
        <span className="bg-red-600 w-[10px] h-[10px] rounded-full"/>
        <span title="Pre Survey" >Pre</span>
        <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        <span title="Post Survey" >Post</span>
        <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        </>
      }

      const isFinished = steps.every(step => node.data[step] === true);

      if(isFinished){
        return <>
        <span title="Onboarding" >On</span>
        <span className="bg-green-600 w-[10px] h-[10px] rounded-full"/>
        <span title="Pre Survey" >Pre</span>
        <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        <span title="Post Survey" >Post</span>
        <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        </>
      }

      return <>
      <span title="Onboarding" >On</span>
      <span className="bg-yellow-400 w-[10px] h-[10px] rounded-full"/>
      <span title="Pre Survey" >Pre</span>
      <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
      <span title="Post Survey" >Post</span>
      <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
      </>
    }


  
    

    return (
        <>
        <div  ref={dragHandle} className="ml-4 tree flex items-center" style={{...style,backgroundColor: (node.isSelected || node.willReceiveDrop)?"#ccc":"transparent"}}>
          <div onClick={() => node.toggle()} className="left flex items-center gap-[5px] flex-1">
            <span>{node?.data?.email ? <User className={"w-4"}/> : "🗀"}</span>
            <span className="name overflow-x-hidden">{!node?.data?.email ? node.data.name:showUserNames? node.data.name:"user"}</span>
            <OnboardingStatus status={node?.data?.email? true:false}/>
          </div>
          
          {
            node.isSelected && <>
                <div className={`right flex justify-end items-center gap-[5px] pr-[5px]`}>
                    <Add
                    title="Add Child Group"
                    className="cursor-pointer w-4"
                    onClick={()=> handleAddGroupClick(node.data._id)}
                    />

                    {
                      isEdit? (
                        <Edit 
                          title="Edit Survey Timing"
                          className="cursor-pointer w-4"
                          onClick={()=>handleUpdateSurveyTimingClick(node.data._id)}
                        />
                      ):(
                        <Add
                          title="Add Survey Timing"
                          className="cursor-pointer w-4"
                          onClick={()=>handleAddSurveyTimingClick(node.data._id)}
                        />
                      )
                    }
                    
                    <Delete 
                    title="Delete" 
                    className="w-5 cursor-pointer"
                    onClick={()=>{onDeleteSubmit(node)}}/>


                    <Edit 
                      title="Edit Group"
                      className="cursor-pointer w-4"
                      onClick={()=>handleEditGroupClick(node.data._id,node.data.groupname)}
                    />

                    <Edit 
                    title="Edit Batch Users"
                    className="cursor-pointer w-4"
                    onClick={()=>handleEditBatchUsersClick(node.data._id)}
                    />

                    {
                      (node?.data?.email && loggedInUser?.role === "admin") && <Eye onClick={()=> setShowViewUserPopup(true)} title="View User" className="cursor-pointer" />
                    }

                    <div className="flex items-center">
                      <input title={canAccessDashboard? "can access dashboard":"can't access dashboard"} value="" onChange={handleDashboardViewChange} checked={canAccessDashboard} type="checkbox" data-id={node.data._id} className="dashboardAccess"/>
                    </div>
                </div> 
            </>
          }
        </div>

       
      </>
    );
}