import {Tooltip}  from 'react-tooltip';
import {Info} from "./Icons";



type IInfoIconWithToolTip = any;

export const InfoIconWithToolTip: React.FC<IInfoIconWithToolTip> = (props) => {
  const remProps = {...props};
  const content = remProps.content;
  const tooltipStyle = remProps.tooltipStyle || {};
  delete remProps.content;
  delete remProps.tooltipStyle;
  const rest = content.replace(/[^a-zA-Z]/gi, '');
  const id = `${rest}-tooltip`;


  return (
    <>
      <ToolTipInfoIcon  {...remProps} id={id} style={{ cursor:"pointer", display:"inline-block"}}/>
      <RectTooltip 
        style={{
          ...tooltipStyle
        }} 
        html={content} 
        anchorSelect={`#${id}`}
      />   
    </>
  )
}


type IToolTipInfoIcon = any;

export const ToolTipInfoIcon: React.FC<IToolTipInfoIcon> = (props) =>  {

  return <Info {...props}/>;
}


type IRectTooltip = any;

export const RectTooltip: React.FC<IRectTooltip> = (props) => {
  const remProps = {...props};
  const children = remProps.children;
  delete remProps.children;
  
  return <Tooltip {...remProps} variant="info" style={{fontSize: "14px",maxWidth: "200px",fontWeight: "normal"}}>
            {children}
         </Tooltip> 
}



