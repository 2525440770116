interface IArrowBack {
    onClick?: any;
    className?: any;
    title?: any;
}


export const ArrowBack = ({onClick}:IArrowBack) => {

    return <img 
    onClick={onClick} 
    className='border border-secondry-color rounded-full w-10 h-10 p-2 bg-white text-primary-color cursor-pointer'  
    alt="arrow-back" 
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVR4nNXVIU4DQBAF0JcGUVksAoegAgmGI1TVkyDqwFWXO4DjDGDoDeAKvQACRWhS0dRAaNNkNmlQiJkE/gHeT3Z3dvjj6eGyCu/iGWuMs/EOHgN/w2F2wV3gHzjOxm8CX+E8Gx8F/oVhNj7AZxRcZeNnWAY+ycb7mAd+n40f4DXwJ+xl4vuYBf4Sg+VfFfw8omn2EbUc4b3qkltOd57pdpJLMtgZtOuqkgt8V30VLZPKz67lNkoWOFGQDh4qF47qldlSuvR/lQ2TBTV9NsnUKAAAAABJRU5ErkJggg=="/>
}


export const Info = (props:any) => {

   

    return <img
            {...props}
            className='cursor-pointer inline-block w-[10px]' 
            alt="info" 
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVR4nLWVu04CURCGv85CYmfhBWIAxYpox0MosTW+gSIWKu9hadTGSlCCjZc3EWN8CNEGZIMZ8y85BNiLWf9kkjNn53LO7D9zIBgZ4BB4BtrAl6StvQqQ5g9YAs6BPjAIEQ9oACtRg+8An3LuAjfALlAAZiUF7dVlY7YdoBwW/EgnMoc7IBvhQDmg6dymGnRyT2U5Jj5O5O9NusmyU5ag4Gn9+Gk4dcq16H64csoyDUXdzmQzwK6lWBf+RkZO3ZCaFyMmyCtWX5X55flAbAnDgiQMDcU8MOVJitEuCBvAu8TWQdhTzAdT3qSshjiVnOaydRAKsrOOH7InlWCClOw+/yvBnOw+THmVspZggnXZvcT5yaUYCUZ+ckVKPcEEt7Lb99vfb7RcAgnyQA/49hvNcCnHZgIJ7mVj78nIA9PRB5uKkzCjUZLVehJqDnvGOr7sjFubinFRc/y3pxlVnQenpXqGIe+UxdNsC0TZKVdPg8toZ9y2ZjSxte0ZW8zGL8tW1OvOA2diQpRH/zrilB2D0cxG7qO60saKia2tiYznQypOwg9HZqPtiWXlkgAAAABJRU5ErkJggg=="
           />
}


export const Eye = ({onClick,className}:IArrowBack) => {

   
    return <img 
            onClick={onClick}
            alt="Eye-Open"
            className={`${className || ""}`}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVR4nO2Vyy5kURSGvxZNJ1q5JC08g3ZJiIkBE02YeBkmbaLFLRKXeAEzl6EHkIiIqcaIROJWdIL0QIR0Wsmq/MWyFc4Jw/MlO1X7r7X+vfa1ICEhISE6hUA7MAGsA3+AW7UzaROKsdgPowYYBy6BTMR2qZzq9wycAmaAG2e8CQwCP1TYFzX73gEMA/su3nKn5BWLXuDEGdlyN0fM/QS0Aucu/1ieb1IMzLrEO30OBXFFWuK0Ch2T5hkJPDJa0TDugUpgTYH/tdTb6jfxlLE8+26ap0W6efySp/VXgYoglipgRwHn2mPjr7QwwW9PrpkWTigjD6MTuHBF2ZhZzHzLmdTyyLX0EuIX8FW6eeSodbm/cxMLC/juEvak10fYgtEgplH6rtPq8hWAlmPb3eG2rApz0n7ylCIVcfLKIRxQrnmgByr3ltiEvwXx2WpWFfAP6AO61D8FSolOSlc3I49+eVp/BSh/KfEzMO2WdcMZLQAFEQa3mEX3fmw4v8moz3QPcJhnn+eBslfy7LelPHkHQDcxSemxuQrM0joT9ZqNtQZp6SD2SoczzvY9o0qH7DjGn9GRBn646x9BgU6yPbHL+tO5UduXNqwbFOWsJCQkJGDcA3crwmw/XEzUAAAAAElFTkSuQmCC"
            />
}


export const BackArrowBold = ({onClick}:IArrowBack) => {

   
    return <img 
    onClick={onClick}
    alt="left-arrow"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR4nNXWPQoCMRCG4fcEWsgq+AOKlXowj6mlhZ2uhWujNuolVhYiLJIUwpcPHJj6IZNkZuBPYgKcgC3QzYlcgDrkDuiokTFQtZBPrpXIKIGUQE+FDMKd1F95BoZKpIwgVThlVuQKTFVIP4HcgJkSOUaQOzBXIocI8gAWKqRIIE9gqUKa2EeQF7BSIlaocJXO9hisz9v6Ya0tyNpUrWPCOviso9y6nNDCmpJtcq5bP8UbhdWADWvaLVIAAAAASUVORK5CYII="
    />
}


export const ForwardArrowBold = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="forward"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nNXWPQrCQBCG4fcEsQkJ6oY0ksaT5ZaxE2wVf9HKFF7CIKwgsulmPnRg6geGb2cWfqQmwArYAZUXkgFr4Bn74oW1H8i7z0CwhvI4shQ2t8aKEezkgZXAfgSbWWMhhuEbO3pgr8RdVVgN3BLYAZhaYwvgrsIaoFdhS+CRwDZ/CTWK0UnCUCviXSkebFCsoFKxVAvFmchVh69VnfJM9Tkhfrc6YOuJmNYA6EaADfD8J8gAAAAASUVORK5CYII="
    />
}




export const StairsGoal = ({onClick,className}:IArrowBack) => {

    return <img
    onClick={onClick} 
    alt="stairs"
    className={`inline-block text-white ${className || ""}`}
    src="https://www.svgrepo.com/show/323346/stairs-goal.svg"
    />
}


export const Add = ({onClick,className,title}:IArrowBack) => {

    return <img
    onClick={onClick} 
    alt="add"
    title={`${title || ""}`}
    className={`${className || ""}`}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACRElEQVR4nO3czWpTQRyG8ReLUnsP9jb82rhwo7VF8Zq0aBEE6RU0unhvqVSsRbdZiAtTaOTIRFREkyEzfds8P/ivSqCdZ06bhjNHAgAAAADgEtgog3N0Q9K+pM+SpmU+SXpTvoaOnkr6+kuIP+eLpMcU6eO+pNN/xJjNRNI9orR1VdLRHDFm817SNaK082SBGLPZIUg7ryuCvCJIO+8qgrwlSDuuCDK8BgRZDeYKyWKCZDFBspggWUyQLCZIFhMkiwmSxQTJYoJkMUGymCBZTJAsJkgWEySLCZLFBMligmQxQbKYIFlMkCwmSBYTJIsJ8ndrkh5J2pU0KgvVY04qgpx0/P5GZU22yhp1cXfBIwGrOkeS7rSOsTPnYRlGPw8NbbeKsSlpzGJr0c02Lmu3dMM5C3a+qtZgr0WQmj+ojH6swcdlx7gu6YwFVu0GOytrSBDlBFlf9lXCryxVBzlWA3sBO216QedFiyC87VXW216Vf3ImATtuekFmUj5iauq2pMOAH3YaPsMa3VInVyQ9lPRc0gEfLmr24eJBWZMHZY0uPVfsVM6pE2R1mCskiwmSxQTJYoJkMUGymCBZTJAsJkgWEySLCZLFBMligmQxQbKYIFlMkCwmSBYTJIsJksUEyWKCZDFBspggWUyQLCZIllFFkOH2TjSyWxHkGTXa2aoIMtz4jEbWFnyKxOGq3IV+nm5K+jZHjNPyGBB0sP2fp0qMe5xcwu82y0HU43LseJgPkl62PNOH+ay3OAcOAAAAAIB6+w5tEFKIhFHFOQAAAABJRU5ErkJggg=="
    />
}



export const Cross = ({onClick}:IArrowBack) => {

    return <img
    className="w-5"
    title="Delete Tag" 
    alt="delete"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVR4nO2YzU7CQBRGz+PogldAZYE/rJSCO3CJoLj0kX0A5SeAlDShhpAZ2tA6nU6+k3TXuZmT2+nce0EIIYQQQvhIA3guMV4LuMExl8AXsAGGJcRrAt/AzKVMKhHvn9+CMlfAz0G8GXCNA/r7TMQlyLSB+VGsLfCGIxKZtWEDoxIkPnBMzyLzmmPtLbAwrJ1SERGwMmxofGLNnW8S58h4K5HStchM/t6Ae4vEO57RAZaWP1BtJFIeLZnJypaXPBgyU9k98V8ytZIIRqST49Py9pAHddifTmy4Nr/fbo7b3ftbPQqhRInOqIBtla/z8j2rjB/l7EW8kOkbJJIu8aVgYxUDnzgimFb34mj4UHSS0qxq+HAok0gMqOk4KKgBnRBCCCGEIJMdXBTwCqWDRf4AAAAASUVORK5CYII="
    />
}


export const Check = ({onClick}:IArrowBack) => {

    return <img 
    onClick={onClick}
    alt="Check"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOUlEQVR4nO2Zz2sTQRTHl3pQ/wXRiydBLCgetBVEhHbeS1tPKYJQpHdRVPAYj9mZ9NdBUbCleuihgkevbeatv+OxtggeRGn9BaII2pZm5aVNm+ZXZ9uZNKk+eIfsTt58vrNvZmfned5/28EmCa8rgmteI5rSsfNKw5LUmJUEPV4jmR/gGUn4RxGG7FLjQioda/fqwVLptgOS4J0kGEqROJUIE02F9/sm4Ygk+J6HX3UNP/10x7HieElqP6wIbkrCqeSE2O9cgCLsLgSThLNKwx2pUaSedBxSBHMl8KsOc9yG2+b+Qzhb1KbbuQCpcbAy4BZdw4BzAYrghSsBkuC5U/i+p/G9SsO8MwEaF7gPZwJ40jpLH8qLgFbr4ImJ03s4sCQYcy6AYIz74j43DewHXfsUiU5JkFSEgSL87RpclfoiL62S4C6/CPspdtBYgNI4sw3A4QapNW0ugNCvNeCtTHyj1EoaC0ilsaWW8CNTPeHLpf7w0YcrlQUEHSeNBfD2oPob1T58JhzMeTkRUuPn4i3Lxmmkcdg1/Oibi+Gr7Bo8O/++/Xp9OkkN9yLB59IoiHXVcuQzK/AsqrS96IwsQGrRXOuRHy0Lz09ANEeC9wM4znlXD/CKXcM340mc0uKs1PijbuBp5SkQ/lIEUB0+EBd4Q2Vr7bYFr9Z80SfRWx6e4DJ/w5oGe/j+kjGAJfhweT5gVlHsxpYEMLwpiE14VU2AaQrxGm0KZBteVUuhKJO43BqeyQ6E92d63Y08GUziKMtoNRHWR15HWEajvMgqgVpOmzDyiywnII3nTIKXfRIW4RVhyNua6AIIR0w7qCTCBrzKpRAOR4LnravU8ClKJ8UirMHT8nZ6fDy+y3z0NbRupqO8CJvwKp9GaWyJkD4gN9sRg9uGV8vuN/RHvSJ4++8cqzT8wZbtye1sska1ocdid2HlxbprmHcy8oXGR+DO0kfjM8+1cRHCYf73b0OJCb5Kgge8auXKR6Vlo0L/wm34mJ5rbFLDx/WxYvHaFPk0TPMS20fiRPGJWWpSHC33PcHX+F5h20SYaOIYHItjcmyvXsusMhBtXiOZauRCd96khqvsqxf+2w60v6BxFO3k3vUtAAAAAElFTkSuQmCC"
    />
}


export const SelfImprovement = ({onClick,className}:IArrowBack) => {

    return <img 
    onClick={onClick}
    alt="self-improvement"
    className={`w-4 ${className || ""}`}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVR4nO2Zz0rEMBCHv5dw0cuqN/E9VtQH3D8P4FkU76IeFPSkB6+6fQIVZFcWIoU51JJ2J00b6jI/GAqTNMnHZJKhBZNp47UHzIAM+JHnVPz/RkfAJ+A8lvtHbU52VzGRz0K0XwPhxD6A3b6DzJRjTuhYA+AlAiRTgryTCKIIE6KlEmSRCmLQEGSuBHlLBUFDkKkSZJwKoinInvLUGqaCaAqC3BP5YqsgRikhYkCQe2Iip9NSnuO2I7EFPMsiX4Htin4xIJ1LE4nipXkbWFetlMm+iqm/QiDaqKuc0oLqry4gNHWVU5qq/uoCIqSucm3UX11BhNRVLrb+6hKCgMR2AQeAV4+REFUTrmuPMa/ugaeISPQGJFahIMPARZ/2FaTuHafsbyB1soiQeGu5hqdSVXvdO85AFLKIbNrWygq+eUOQzDNGcpATmTy345p3zmrG8Y3R+kLXtWvnpME4f2QgIosItrX8shwRWY5gOeKX5UhEjnyX+n6Jf1Hy5/16HZGbUt9z8V+U/Nd9BzkEHuTj9mXh38sOcCURyr+3HYSAmEy0o1/ZQ+zPg3edVwAAAABJRU5ErkJggg=="
    />
}


export const Rocket = ({onClick,className}:IArrowBack) => {

    return <img
    onClick={onClick}
    className={`w-4 ${className || ""}`}
    alt="rocket"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVR4nO2WsUoEMRCGP/UUX8BCTwsLwcL2Og8t9B20tfA5VHwHtbFcsLCTuydQOLQ6USwVERWEa7S8UwYmEEJcd7PJVv7ww25mMl8xye7Av4qpARyp5bkWjQMZ8K3OdC259i2o8V5q6Cow9ICHGkuiBtD3QI37qfq9nQM13koBvioAvowNXXIAF0ATmAc6Tkxyo2nXKS5AowUnJrnRlJUAS240XTvFOwoXaNeJSW40PRU4WMaPMcFfJcCSG0WLwKgEeKR7KmkZeC4BNX4FVkKhTeA9AGr8BsyFgM8qQI3PQ8CDCODPEPCdp5Dvl5gXuw0BnzhFTtW/gX1xGY1Ka90q0AOmgVYOuKU5PWttLQQ8Btzr6bS/zX9pFngBHqrMYjtA21mTvm1Y75ueXrZ1b1R96I9gRn2ja8l1oP0bWFfusA7whE4g5gB16xzqJ/WqHANTdUGj6AeD0fA/IC7BNAAAAABJRU5ErkJggg=="/>
}


export const Lightning = ({onClick,className}:IArrowBack) => {

    return <img 
    onClick={onClick}
    alt="Lightning"
    className={`w-4 ${className || ""}`}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nO3WIQwBURjA8b8gmMJUyUQzUdUVQRYU2SRTTZI0yfRLGpvggkkkTVBoptgIxm67cLvdveN230v3317+vRfe+x7EqVsBH59lIlRRgVrrJAW3AuChFDwNgEtS8FGB7qXQLPBWwD0puK5ArQ3lpeCRAl4j2EYBt6XQJPDwQZ9ARgquKk5rIFg34P6KPZ9GCPgQBXwNATeigM0/0S2QQLCJD1xDuKUHukBDZ4+nsyKNpjyGxgwNlV3oCyjogJsueIymBg70DuR0wXMH3EdjOxu9AGmd8M2GO2jOtD8H1qyO45e+si6wQw0FdMwAAAAASUVORK5CYII="
    />
}


export const Warning = ({onClick}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className="w-4"
    alt="warning"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8ElEQVR4nO2ZW4hNURjHf2aMy7gO0QhDrrmlUWRCIsWDywMPg6JGlHjgZV6QiaYQuT3wYF4oE0UUUZLGRNFEI5OUZDAuTe7369aq/9RubOfs2zl7b51ffbVa6/vW+n/nsvZa34YcORLLMqCchDMJ+CWbQkLpANQDluy6+hLHciXwQmapL1EUAs0SXwGsUvsp0I0EsV3CbwF5spvq20ZCGAx8kugZtv4y4DfwGRhKAjipJI47jNVq7AQxZ5rtUx/iMD4I+KhkZhJT8oAGidyawq9KPreBfGLIGgl8kmZn6go8ku9qYkZP4LnEuTmOLJXvS6A3MWKPj6f3VcXsJiaMAL7qPDXZQ1ypYr4Do4kB5/XJHvERW6PYc0TMPAl5BxQ7jHcCimSm3Z5ixVqaKxIKgHsSUfkPny22069pO1GpcTOXmTPrbJSAB0DnNM8MS20nzDd1Xz4byDJ9gFdafGEKPzeJGBbJ5w3QjyxyWAtfTuPnNhHDRfkdIkuMA34AP4EJISYyVluxmXciWeCKhB1w4eslEcNB+dZn+lq8RAu9BvpmIJEioFX+i8kQXYCHWmSdyxiviRjWy79ZV+bQ2awFmjzs934SyQfuKGYTITMQ+KDJ53qI85OIYbZizJW5hBA5ponPeIzzm4jhrOKOEhJTdX39BozKYiLDdao2a08nIGYLvCEhO3zEB0nEsFOxDbpK+2alrVrYK4JEegDPFL8Cn3QHWmzVwiA7naW2HyoU3yJNnqkO4WstsN1HCkKozlR7DR4GfNEfzV4tjIoy24Yz0kvg6RTVwqiolaZTbgNmhfgwatt1jO0KOFeJraZsNKY9HjS6qBa6Za8tEdMOSpXmugt0TOW4Vo6PQzqwhZ1IobRZ0pr2CB3Wy0vz+mCOzKmo7YdyaWyV5r/YL4drCXjXVyet+9oPjNE102u1MCpKpdVcucfbBy4EqBZGRY00X2rrWKCO98AAkkN/4K20z0c3Pivh1mQv7yfZ6qL+eeT4b/kDXLNVbksXjZ8AAAAASUVORK5CYII="
    />
}


export const Delete = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Delete"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVR4nO3XsSuEcRzH8beBZLRfl06UwXIm8Q+YLrMyWmS6RZ0Sk8FAKZsyuFIGMtpYbiQl6ZLpJqXc5NJJPc9Gj/T7fn796vuu7/r0e22fBzzP8zzPM2sCeAL6P1wXWCOR6r8g8nuI+bgScAI8Au2Cey2AfPzhG23gBqiFRAwC9wWPs7pPYD4UpBoJ0c/uIBRkKjJkl4CdR0K8AZWQkCFgFegJEWfAOEZ1hJAFDLsVQmYsIVdCSNkS0hRCRiwheyJEF+MaIsizNWRFBGlZQxZFkEtryJwIcmQNmRRBdqwhoyJI3RoykP0YWUOWEdRJfWfl3aW+s5R7q4ygZuo7K28/9Z2Vt5H6zlLtrRaiasaQCxWkYgzZRti1EaKX7TlZ08C7AWSLCM0CL4EA3/ttM9tyURoGloBD4PQfdwysA2OxAJ7neZ73BQOMI4vXGtQmAAAAAElFTkSuQmCC"
    />
}


export const Edit = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Edit"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAABP0lEQVR4nO3WTytFQRjH8S9JsVHk4iqXrdfgNei+AWvxBuzUfQOWYinvw8Ld2ZDrIhbuC7CTlGI0+tE07pyZM42F8tQsznnmPJ8z/04Hysc4cAy8AXvAyC8YTAEt4BIwavulsVHgDOgCKx5mR1YkpoFNYA14GoK92k4njpzSbBE3ZoBz5XYD2BE1EdtOHWQWuHBy78CWh7W0Qb471Y2Gtw4utiOsByx9PZADzQFXFaO22IY2CLlQQ29aNb0DrQ+5UGwkJoTUgeaBfgR5AJZDBVIgi1xHkDtgsapIDJoE7iPILdCMTUkMaiv/qKnxkRtgwenfHXKok6BD5bd1/ewhzdR6MWigfEfYi6572oWUgFYDa9LXeaIU1PbOx4HuTQT6Z0NjwLpGlhomd43qhvmH/v7UmcLtR9jvUmnE/a/4jA9K8PStdRVmIwAAAABJRU5ErkJggg=="
    />
}



export const User = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Edit"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVR4nO2ZPShFYRjHf5dEsriSrAwWGViUkiQMRh8Li7JZZVDko5QySBYGCzIZZRZyDcrHjUSxiluyuK7u0VvnLqeLc895OM/N+dV/fHuf3zmn932f80JISEhQ1ABDwBywAiwBE0ALECEP6ASOAeubHABRFDMPpH+QyOQBWAUaUMaoSwFn3oFhlFAOvHgUMUkB9Shg0IdEJmsoYFNA5BoFxAREPoDioEVuBERMyoIWuRUSKQ1a5EhAwqx6gbMjIHKKAqYFRJZRQI+ASC8KaBQQaUIBewIiuyjgUkDkAgVsCYhsoICOHPqQbDFj21FCtw+RLpRx50HCHG/UsehBxIxRRy2QzLEzrEMps/n+NjJEXO4r90Ahyom5EImTB5y4ELkiD4i7/EGnmiLg0YXIK1CCYqZyWLUWUEgUWPdw5jL/xCpRQBUw6fJz+ipPwAxQHYRAq/0033wIOJMEtoG2375DKbB76zPB4r/KOdBvzylK8x8JWFmEzNwijNgHPCugpOwafDHgs/uTShro83OB86xAwrKTACq8iIwrKN5yZMyLyKGCwi1H9r2IJBQUbmXZPENCQv4rn6Ej5y2yxDtPAAAAAElFTkSuQmCC"
    />
}


export const ArrowDownRed = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Edit"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4klEQVR4nO3ZX0tUQRgG8MXUupCu0j5AXYVWF3kZixa6F8rZ5xnmLtogWAhEyH+3QvRl6qIQ/Q4h2Cfon99C3/ekE5MrnnbBUOc4x3UeGFhm33M4P+bM7pk5tVpKSkpKSkrK+eLm5++KMQ9zY57l5PMgDZiWLJtwwFi5F2/tDSXXFNhV0pXagJ9KLrn19YHgkL+IsgHsAb0tA/IrAuRb8Dlx6QgeNTc3dycYRLLscSyIZNlEMIg2m/VYEDXmaTCI/5mNBcnJqZCQmWgQY2bCQcjZiCMymyDXekSE3FZgUoBxITdPqd3Yt/aBGvNEyC+Vg+w1m/eO6129Pqjkx5464IN/Zjuu27P2fqUgQh46a0eKx7h2e0iBz4W6T77vn5pG43alIHqEedd9nLN2WMgtf6v5z93fC/C+ipBDJRd6MK3WLddo3OzuV2CxkpNdTzBv/nc+IV93aqsJ0RNM+xTEKwEOrsQfogAHYsyLHgTw8iwIjQ3pYH4XMWJMy/ed9Tx5bEih7Sj59bzH5xWCXKjlQSF98xhv+mRhpf2y1BXgUSyIAOP9sR1k7Wjtym/Qkd+DIjqQ1QiQpXI2sYEVBX5cxkgouVxchJUSf9/6HUD/KiDYawVyyk/s4HMiJSUlJSXlOuUPqatJmNAJni8AAAAASUVORK5CYII="
    />
}


export const ArrowDown = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Edit"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVR4nO3YSwrCMBRG4eMOVQwZ6OoVQfGxgQqVQgsiSe0zucX/QGdJuR+hgxSUUkoppZTqmQPuwBlYY68NcAGegI8tWgE3oKyfom1xhnw9UzPfo5452PVjoSWM/0KU9azRtoENuTE+MlM1a2u7wMYXcCDPN1sEZtl3fYEFjBuLsIBxUyFyYtzUiBwYNxciJWZ2RApMMsScmOSIOTDZEFNisiOmwJhBjMGYQwzBmEX0wZhHdMEsBvHr7mDtjjP4ZBZzEl0xi0LEMItENFW/lE7A0ejvJaWUUkop9U+9AdnJ7S7GX9jsAAAAAElFTkSuQmCC"
    />
}





export const ArrowUpGreen = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="Edit"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADA0lEQVR4nO3dv2oUURQG8Emjnda+gVqZwtI9B7EI2NjsPSt5BS0EUeMT5CHUgP+KPIAKQu7VylaTdzCFQbB3j9z1D4KYZJPsfGdmvg8OhJBsZr5f7tywBG7TMAzDMAzDMAzDMAzDMAzDoHL1/fii5LSmJW1osc1hTdqo9z4qdqFB59rb8Vkt9lKzTbWYD3qyTSWnFysfVs8gMbbhRZRgk+0TBGW2MtA3X8LOs9b3DD6mbL9VMm11T9FiDwP8FnrkkWwPWgSZ/TUFv2kNPJLtcYsgsz/54DetsWeTIAWOQBDFF08QxZdNEMUXTBDFl0oQxRdJkADlOUHwhTlB8CU5QfDFOEHwZXiA4VsnikcgiGbbkpxu19GScgCI4a4QyWmt8Wbpz4V5s/Tzny3w1zY4EKkY/4nkyR309Q0KRPbBCIQyDBA5BEYQlP6DyBwYAVD6DSJHwACj9BdEjoEBROkniGS7e9D1jMpkuc5BX1dfiyALXhmSx5ek2Bct9nVU0uVAK6VfK0Tmw/j9fZFQ+gMiR8OIhtIPEDkeRiSU7oPIyWBEQek2yAljeACU7oIsCMPBKN0EWTCGA1G6B9IShoNQugXSMoYDULoDAsLwllG6AQLG8BZR4oNITusHvfaoTJYl294CMX5di+0d7g3JtN5XkM/jnfGpCBg6B8rKq5XTUtJuD0HS60gYOgeKFHvTQxDbjoahh0SRknb6uofcAGzgfpyNXrcm14/4evFBNNs3yXbryrvVc3Xqx/VzATB8AdfXAZBhzSZBChyBIIovniCKL5sgii+YIIovlSCKL5IgAcpzguALc4LgS3KC4ItxguDL8ADDt04Uj0AQxRdPEMWXTRDFF0wQxZdKEMUXSZAA5TlB8IV5n0CeBLhhjzyS06M2QXhcRQl0XEU9rIQHuth+q+O75JvnmzZTD8BCPxY07KSnTdupB1/VA7DwN2+hRnL6CDuprf5gyfacjy+bPabqyoBh/LOnFLs/1IMltaR7re8ZDMMwDMMwDMMwDMMwDMMwzV/5Af3G54CYJuy8AAAAAElFTkSuQmCC"
    />
}
















