import { useEffect, useRef } from "react";
import { Chart as ChartJS} from "chart.js/auto";
import { mapper__100_100_to_0_100 } from "../../../utils";
import { getBrainColor } from "../../UserDashboard/components/ScoreInBrainWithLabels";

interface ICircularProgressBar {
    XLScore_100_100: any
  }
  
  export const CircularProgressBar: React.FC<ICircularProgressBar> = ({XLScore_100_100}) => {
    const progressRef = useRef<any>(null);
  
    
    useEffect(() => {
      const progress = new ChartJS(progressRef.current,{
          type: "doughnut",
          data: {
            labels: [
              'Gray',
              'Blue'
            ],
            datasets: [{
              label: 'XLScore',
              data: [mapper__100_100_to_0_100(XLScore_100_100), 100-mapper__100_100_to_0_100(XLScore_100_100)],
              backgroundColor: [
                getBrainColor(mapper__100_100_to_0_100(XLScore_100_100)),
                '#cccccc',
              ],
              borderWidth: 0,
            }]
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            },
            rotation: 90,
            cutout: '80%' // gap inside the circle 
          },
          plugins: [
          //   {
          //   id: "doughnutLabel",
          //   beforeDatasetDraw(chart,args,pluginOptions){
          //     const {ctx} = chart;
          //     const xCord = chart.getDatasetMeta(0).data[0].x;
          //     const yCord = chart.getDatasetMeta(0).data[0].y;
          //     ctx.font = "bold 12px sans-serif";
          //     ctx.fillStyle = "rgba(54,162,235,1)";
          //     ctx.textAlign = "center";
          //     ctx.textBaseline = "middle";
          //     ctx.fillText("XLScore",xCord,yCord);
          //     ctx.font = "bold 8px sans-serif";
          //     ctx.fillStyle = "rgba(54,162,235,1)";
          //     ctx.textAlign = "center";
          //     ctx.textBaseline = "middle";
          //     ctx.fillText("10",xCord,yCord+10);
          //   }
          // }
        ]
      })
      
      return () => {
        if(progress){
          progress.destroy();
        }
      };
    }, [XLScore_100_100]);
  
  
  
    return <canvas ref={progressRef}/>
  }
  