import { useEffect, useState } from "react";
import {Add} from "../../components/Icons";
import {
  getSurveyTitleById,
  createSurveyTitle,
  updateSurveyTitle,
  getUniversalSurveyTitle,
  addQuestions,
  removeQuestion,
  addActionChallenges,
} from "../../Store/actions";
import { toast } from "react-toastify";
import {XLDataToJSON} from "../../utils";
import { XL_FACTORS,ACTIVITIES } from "../../constants";
import {
  Survey,
  AddSurveyForm
} from "./components";
import { Main } from "../../layout";
import { QuestionWraper } from "./components/QuestionWraper";
import { ActionChallengeWraper } from "./components/ActionChallengeWraper";



export const Index: React.FC<any> = () => {
  const [formData, setFormData] = useState({ questions: [] });
  const [toggleUpdateForm, setToggleUpdateForm] = useState(false);
  const [toggleInsertForm, setToggleInsertForm] = useState(false);
  const [universalSurveytitle, setUniversalSurveytitle] = useState<any>(null);
  const [rawQuestions, setRawQuestions] = useState("");
  const [rawChallenges, setRawChallenges] = useState("");
  const [XLFactor, setXLFactor] = useState("Fun");
  const [survey, setSurvey] = useState("work");
  const [questions, setQuestions] = useState([]);
  const [actionChallenges, setActionChallenges] = useState([]);
  const actionChallengesForTeam = actionChallenges.filter((ac:any) => ac.for === "team");
  const actionChallengesForIndividual = actionChallenges.filter((ac:any) => !ac.for || ac.for === "individual");
  const [actionChallengeFor, setActionChallengeFor] = useState("individual");
  
  
  // _id to update resources
  const [_id, set_id] = useState("");

  const handleUpdate = async (id:any) => {
    set_id(id);
    const res = await getSurveyTitleById(id);

    setFormData((prev) => {
      return {
        ...res.surveyTitle,
      };
    });
    setToggleUpdateForm(true);
  };

  const handdleUpdateSubmit = async () => {
    const res = await updateSurveyTitle(_id, formData);
    if (res.success) {
      toast.success(res.message);
      setUniversalSurveytitle(res.surveyTitle);
    } else {
      toast.error(res.message);
    }
  };

  const handdleAddSurvey = async () => {
    setFormData(() => {
      return {
        questions: [],
      };
    });
    setToggleInsertForm(true);
  };

  const handdleAddSurveySubmit = async () => {
    const res = await createSurveyTitle({...formData,isUniversal: true});

    if (res.success) {
      toast.success(res.message);
      setUniversalSurveytitle(res.surveyTitle);
    } else {
      toast.error(res.message);
    }
  };


  const handleRawQuestionAdd = async ()=> {
    const questions:any[] = [];
    const json = XLDataToJSON(rawQuestions);
    json.forEach((ques:any) => {
      questions.push({question: ques[0],XLFactor});
    });

    const res = await addQuestions(universalSurveytitle._id,questions);

    if(res.success){
      toast.success(res.message);
      setQuestions(res.questions);
    }
    else{
      toast.error(res.message)
    }
  };

  const handlrRawActionsChallengesAdd = async ()=> {
    const challenges:any[] = [];
    const json = XLDataToJSON(rawChallenges);
    json.forEach((ques:any) => {
      challenges.push(ques[0]);
    });
    const data = {
      XLFactor,
      survey,
      for: actionChallengeFor,
      challenges
    }

    const res = await addActionChallenges(universalSurveytitle._id,data);
    
    if(res.success){
      toast.success(res.message);
      setActionChallenges(res.actionChallenges);
      setRawChallenges("");
    }
    else {
      toast.error(res.message);
    }
  }

  const handleDeleteQuestion = async (questionId:any)=> {
    // universalSurveytitle._id
    const res = await removeQuestion(universalSurveytitle._id,questionId);
    if(res.success){
      toast.success(res.message);
      // console.log(res);
      setQuestions(res.questions);
    }
    else{
      toast.error(res.message);
    }
  }

  useEffect(() => {
    (async () => {
      const res = await getUniversalSurveyTitle();
      console.log(res);
      if (res.success) {
        setUniversalSurveytitle(res.surveyTitle);
        setQuestions(res.surveyTitle.questions);
        setActionChallenges(res.surveyTitle.actionChallenges);
      } else {
        toast.error(res.message);
      }
    })();
  }, []);

  return (
    <>
      <Main>
        <h2 className="text-center py-10 text-secondry-color text-2xl font-bold">Manage Universal Survey Title</h2>

        {/* text area to add questions  */}
        <div className="mb-10  w-full py-5 bg-white shadow-lg rounded ">
              <div className="p-3">
                <h5>Select XL Factor</h5>
                <select
                className="border w-full py-1" 
                value={XLFactor} 
                name="XLFactor" 
                onChange={(e)=> setXLFactor(e.target.value)}>
                  {
                    XL_FACTORS.map(factor => <option key={factor} value={factor}>{factor}</option>)
                  }
                </select>
              </div>
              <div className="w-full p-3">
                <textarea 
                value={rawQuestions} 
                onChange={(e)=>setRawQuestions(e.target.value)} 
                placeholder="Paste XML here..." 
                className="w-[100%] p-2 focus:outline-1 focus:outline-blue-500 font-bold border-[0.1px] resize-none h-[120px] border-[#9EA5B1] rounded-md"></textarea>
                <div className="flex justify-end">
                  <button onClick={handleRawQuestionAdd} className="text-sm font-semibold absolute bg-[#4F46E5] w-fit text-white py-2 rounded px-3">Add Questions</button>
                </div>
              </div>
        </div>
     
        {/* questions list  */}
        <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Questions</h5>
          <div className="flex flex-col gap-3">
            {
              XL_FACTORS.map(XLFactor => {

                const q = questions.filter((ques:any) =>ques.XLFactor === XLFactor);
                
                return <QuestionWraper 
                key={XLFactor} 
                XLFactor={XLFactor}
                questions={q} 
                handleDeleteQuestion={handleDeleteQuestion}
                />
              })
            }

          </div>
        </div>
        
        {/* textarea to add action challenges  */}
        <div className="my-10  w-full py-5 bg-white shadow-lg rounded ">
              <div className="p-3">
                <h5>Select Survey</h5>
                <select
                className="border w-full py-1" 
                value={survey} 
                name="survey" 
                onChange={(e)=> setSurvey(e.target.value)}>
                  <option value="work">Work</option>
                  <option value="relationship">Relationship</option>
                  <option value="hobby">Hobby</option>
                  <option value="study">Study</option>
                  <option value="sport">Sport</option>
                  <option value="business">Business</option>
                </select>
              </div>
              <div className="p-3">
                <h5>Select XL Factor</h5>
                <select
                className="border w-full py-1" 
                value={XLFactor} 
                name="XLFactor" 
                onChange={(e)=> setXLFactor(e.target.value)}>
                  {
                    XL_FACTORS.map(factor => <option key={factor} value={factor}>{factor}</option>)
                  }
                </select>
              </div>
              <div className="p-3">
                <h5>Select Action Challenges for</h5>
                <select
                className="border w-full py-1" 
                value={actionChallengeFor} 
                name="for" 
                onChange={(e)=> setActionChallengeFor(e.target.value)}>
                  <option value="team">Group/Team</option>
                  <option value="individual">Individual</option>
                </select>
              </div>
              <div className="w-full p-3">
                <textarea 
                value={rawChallenges} 
                onChange={(e)=>setRawChallenges(e.target.value)} 
                placeholder="Paste XML here..." 
                className="w-[100%] p-2 focus:outline-1 focus:outline-blue-500 font-bold border-[0.1px] resize-none h-[120px] border-[#9EA5B1] rounded-md"></textarea>
                <div className="flex justify-end">
                  <button onClick={handlrRawActionsChallengesAdd} className="text-sm font-semibold absolute bg-[#4F46E5] w-fit text-white py-2 rounded px-3">Add Action Challenges</button>
                </div>
              </div>
        </div>
     
        {/* action challenges individual  */}
        <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Action Challenges (Individual)</h5>
          <div className="flex flex-col gap-3">
            {
              ACTIVITIES.map((survey,i) => {
                return <ActionChallengeWraper 
                key={i} 
                survey={survey} 
                actionChallenges={actionChallengesForIndividual}
                universalSurveytitle={universalSurveytitle}
                setActionChallenges={setActionChallenges}
                />
              })
            }

          </div>
        </div>

         {/* action challenges team  */}
         <div className="bg-white shadow-lg rounded mb-10 p-3">
          <h5>Action Challenges (Leader/Manager)</h5>
          <div className="flex flex-col gap-3">
            {
              ACTIVITIES.map((survey,i) => {
                return <ActionChallengeWraper 
                key={i} 
                survey={survey} 
                actionChallenges={actionChallengesForTeam}
                universalSurveytitle={universalSurveytitle}
                setActionChallenges={setActionChallenges}
                />
              })
            }

          </div>
        </div>
           
        <div>
          <div className="surveytitle-container pb-10">
            {
              universalSurveytitle && <Survey
                                        surveyTitle={universalSurveytitle}
                                        handleUpdate={handleUpdate}
                                        setAllSurveyTitles={setUniversalSurveytitle}
                                      />                        
            }
            {
              !universalSurveytitle && <div className="my-10 text-center">You Don't have Universal Survey Yet</div>
            }
          </div>
        </div>
        

        {/* update Modal  */}
        {/* <Modal
          title="Update"
          saveBtnText="Update"
          cancelBtnText="Cancel"
          isOpen={toggleUpdateForm}
          setIsOpen={() => setToggleUpdateForm(!toggleUpdateForm)}
          onSubmit={handdleUpdateSubmit}
        >
        <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal> */}
        {/* insert Modal  */}
        {/* <Modal
          title="Add"
          saveBtnText="Insert"
          cancelBtnText="Cancel"
          isOpen={toggleInsertForm}
          setIsOpen={() => setToggleInsertForm(!setToggleInsertForm)}
          onSubmit={handdleAddSurveySubmit}
        >
          <AddSurveyForm formData={formData} setFormData={setFormData} />
        </Modal> */}
        {/* update Modal  */}
        <AddSurveyForm onSubmit={handdleUpdateSubmit} formData={formData} setFormData={setFormData} isOpen={toggleUpdateForm} setIsOpen={setToggleUpdateForm} />

        {/* insert Modal  */}
        <AddSurveyForm onSubmit={handdleAddSurveySubmit} formData={formData} setFormData={setFormData} isOpen={toggleInsertForm} setIsOpen={setToggleInsertForm} />



        {/* add question btn   */}
        <div className="add_questions" title="Add Survey Title">
          {
            !universalSurveytitle && <Add onClick={handdleAddSurvey}/>
          }
        </div>
      </Main>
    </>
  );
}

export default Index;









