import { Actions } from "./Actions";
import { DashboardStatus } from "./DashboardStatus";
import { EmptyField } from "./EmptyField";
import { ManagerGroupsOrTeams } from "./ManagerGroupsOrTeams";
import { OnboardingEmailReceived } from "./OnboardingEmailReceived";
import { OnBoardingStatus } from "./OnBoardingStatus";
import { OneLineField } from "./OneLineField";
import { PersonalSurveys } from "./PersonalSurveys";

interface IUsersTable {
    users: any;
    setAllUsers: any;
    handdleUpdateUser: any;
  }
  
  export const UsersTable: React.FC<IUsersTable> = ({users,setAllUsers,handdleUpdateUser}) => {
  
  
    return (
      <div className="flex flex-col gap-3">
        {
            users.map((user:any,idx:number) => (
              <div key={idx} className="pb-4 flex flex-wrap border-b">
                  {
                      ["Dob","Profession","Title","MiddleNames","Surname","Forename","Email","User Name","Gender","Organisation","Department","Team","Role","Rank","Class Name","Level","Student Number","Mobile Phone Number","Landline Number","Address","Data Share","No. Survey User","Personal Surveys","Manager Groups/Teams","Onbording Email Time","Post Survey Time","Received Onboarding Email","On Boarding Status","Dashboard Tour Status","Actions"].map((item,idx1) => (
                        <div key={idx1} className="p-2 px-3 bg-secondry-color/10 mb-1">
                            <p className="mb-1 font-bold text-sm text-secondry-color whitespace-nowrap">{item}</p>
                            {
                              item === "Profession" && <OneLineField user={user} fieldName="profession"/>
                            }
                            {
                              item === "Dob" && <OneLineField user={user} fieldName="dob"/>
                            }
                            {
                              item === "Title" && <OneLineField user={user} fieldName="title"/>
                            }
                            {
                              item === "MiddleNames" && <OneLineField user={user} fieldName="middleNames"/>
                            }
                            {
                              item === "Surname" && <OneLineField user={user} fieldName="surname"/>
                            }
                            {
                              item === "Forename" && <OneLineField user={user} fieldName="forename"/>
                            }
                            {
                              item === "Email" && <OneLineField user={user} fieldName="email"/>
                            }
                            {
                              item === "User Name" && <OneLineField user={user} fieldName="userName"/>
                            }
                            {
                              item === "Gender" && <OneLineField user={user} fieldName="gender"/>
                            }
                            {
                              item === "Organisation" && <OneLineField user={user} fieldName="organizationName"/>
                            }
                            {
                              item === "Department" && <OneLineField user={user} fieldName="department"/>
                            }
                            {
                              item === "Team" && <OneLineField user={user} fieldName="team"/>
                            }
                            {
                              item === "Role" && <OneLineField user={user} fieldName="role"/>
                            }
                            {
                              item === "Rank" && <OneLineField user={user} fieldName="rank"/>
                            }
                            {
                              item === "Class Name" && <OneLineField user={user} fieldName="className"/>
                            }
                            {
                              item === "Level" && <OneLineField user={user} fieldName="level"/>
                            }
                            {
                              item === "Student Number" && <OneLineField user={user} fieldName="studentNumber"/>
                            }
                            {
                              item === "Mobile Phone Number" && <OneLineField user={user} fieldName="mobilePhoneNumber"/>
                            }
                            {
                              item === "Landline Number" && <OneLineField user={user} fieldName="landlineNumber"/>
                            }
                            {
                              item === "Onbording Email Time" && <OneLineField user={user} fieldName="onbordingEmailTime"/>
                            }
                            {
                              item === "Post Survey Time" && <OneLineField user={user} fieldName="postSurveyDate"/>
                            }
                            {
                              item === "Address" && <OneLineField user={user} fieldName="address"/>
                            }
                            {
                              item === "Data Share" && <OneLineField user={user} fieldName="isSharingData"/>
                            }
                            {
                              item === "No. Survey User" && (user?.activities?.length || <EmptyField/>)
                            }
                            {
                              item === "Personal Surveys" && <PersonalSurveys user={user}/>
                            }
                            {
                              item === "Manager Groups/Teams" && <ManagerGroupsOrTeams user={user}/>
                            }
                            {
                              item === "On Boarding Status" && <OnBoardingStatus user={user}/>
                            }
                            {
                              item === "Received Onboarding Email" && <OnboardingEmailReceived user={user}/>
                            }
                            {
                              item === "Dashboard Tour Status" && <DashboardStatus user={user}/>
                            }
                            {
                              item === "Actions" && <Actions user={user} setAllUsers={setAllUsers} handdleUpdateUser={handdleUpdateUser}/>
                            }
                        </div>
                      ))
                  }
              </div>
            )) 
        }
      </div>
    )
  }
  