import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";
const fixedURL = `${DOMAIN_NAME}/survey-link`;




export const getTodaysUnCompletedSurveyLinkOfUser = async (userId:string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey-link/${userId}`,"GET",{});

    return res;
}


export const getAllStreaksOfUser = async (userId:string) => {

    const res = await fetchRequest(`${fixedURL}/streak/${userId}`,"GET",{});

    return res;
    
}


export const getTodaysStreakOfUser = async (userId:string) => {

    const res = await fetchRequest(`${fixedURL}/streak/todays/${userId}`,"GET",{});

    return res;
    
}