import {useEffect, useState} from 'react';
import {
  getAllSurveyTitles,
  getAllGroups
} from "../../Store/actions";
import {Main} from "../../layout";
import { Users } from './components/Users';




function AddUsers() {
  const [data, setData] = useState<any>(null);
  const [groups, setGroups] = useState(null);
  const [prePostSurvey, setPrePostSurvey] = useState(null);
  const [isDropdownShow, setIsDropdownShow] = useState(false);


  const csvToJson = (csv:any) => {
    const lines = csv.split('\n');
    const headers = lines[0].split(',').map((header:any) => header.trim());
    
    return lines.slice(1).map((line:any) => {
      const values = line.split(',').map((value:any) => value.trim());
      const obj: { [key: string]: any } = {};
      headers.forEach((header:string, index:number) => {
        obj[header] = values[index];
      });
      return obj;
    });
  };

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e:any) => {
            // const data = e.target.result;
            // const workbook = read(data, { type: "array" });
            // const sheetName = workbook.SheetNames[0];
            // const worksheet = workbook.Sheets[sheetName];
            // const keys = getKeysFromSheet(worksheet);
            // const values = utils.sheet_to_json(worksheet);


            const array = csvToJson(e.target.result)
            const values = array.map((obj:any) => {
              const value: {[key: string]: any} = {};
              for (const key in obj) {
                if(obj[key]){
                  value[key] = obj[key];
                }
              }
              if(Object.keys(value).length === 0){
                return null;
              }
              return value;
            }).filter((v:any) => v);
            const keys:any = Object.keys(array[0]);
            setData({keys,values});
        };
        reader.readAsText(e.target.files[0]);
        // reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  useEffect(() => {
    (async ()=> {
      const stRes = await getAllSurveyTitles();
      const grpRes = await getAllGroups();
      if(stRes.success){
        const nonUniversal = stRes.surveyTitles.filter((st:any) => !st.isUniversal);
        setPrePostSurvey(nonUniversal);
      }
      if(grpRes.success){
        setGroups(grpRes.groups);
      }
    })()
  }, []);

  useEffect(() => {
    document.addEventListener("click",(e:any)=> {
      if(e.target.id === "dropdownSearch" || e.target.id === "dropdownSearchButton"){
        return;
      }
      setIsDropdownShow(false);
    })

    return () => {
      document.removeEventListener("click",()=>{});
    };
  }, []);
  
  return (
    <Main>
          <h2 className="pt-10 text-center mb-10 pb-3 border-b border-secondry-color/10 font-bold text-2xl text-secondry-color">Add Client Users</h2>

          <div className='flex gap-10 justify-center items-center'>
            <input type="file" accept=".csv" multiple={false} onChange={readUploadFile} name="exelFile" id="exelFile"/>
          </div>

          {
            data && <Users isDropdownShow={isDropdownShow}  setIsDropdownShow={setIsDropdownShow} prePostSurvey={prePostSurvey || []} data={data} groups={groups || []} setData={setData}/>
          }
    </Main>
  )
}

export default AddUsers;








