import { mapper__100_100_to_0_100 } from "../../../utils";
import { questions, tableHeaders } from "../constants";

export function prepareCSVData(completedSurveys:any,groups:any){

  
  const calcullateTotalDuration = (survey:any)=> {
    let totalTime = 0;

    for (const ans of survey.answers) {
        totalTime += (ans.endtime - ans.starttime); 
    }
 
    return parseFloat(`${(totalTime/1000)/60}`).toFixed(2) + " Mins";
  }

  const mapRange =  (value:any, inMin:any, inMax:any, outMin:any, outMax:any)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  if(!completedSurveys){
    return null;
  }

  const newTableHeader = tableHeaders.map(item => {
    const ques = completedSurveys[0]?.surveytitle?.questions.find((q:any) => q.XLFactor === `${item}`);
    if(ques){
      return `${item} ${ques.question}`;
    }
    return item;
  });
  
  const array = [[...newTableHeader]];

  completedSurveys.forEach((survey:any,i:number) => {
    const group = groups?.find((gp:any) => {
      const user = gp.users?.find((user:any) => user._id === survey?.user?._id);
      if(user){
        return gp
      }
      return undefined 
    })
    const duration = calcullateTotalDuration(survey);

    const ques = questions.map(question => {
      const ans = survey.answers.find((an:any) => an.XLFactor === `${question}`);
      // console.log(ans);
      return ans ? mapRange(ans.rating,1,7,0,100) : "NA";
    });

    const eventTags = survey?.event?.tags?.map((tag:any) => tag);
    const eventFeelings = survey?.event?.feelings?.map((tag:any) => tag);

  
    const arr = [
      i+1,
      `${survey.user?.forename} ${survey.user.surname}`,
      survey.user?.email,
      survey.user?.organizationName,  
      survey.user?.departent,  
      group?.groupname,  
      survey?.surveytitle?.title,
      new Date(survey?.createdAt).toLocaleString().split(",")[0],  
      duration,
      ...ques,
      survey.lxscore,
      mapper__100_100_to_0_100(survey?.lxscore).toFixed(2),
      survey.minLXScore,  
      survey.maxLXScore,
      `${survey?.event?.description} ${eventTags?.join(",")}`,
      survey?.xlChangeFromLastScore,
      survey?.isexcluded,
      eventFeelings?.join(","),
      survey.surveyName 
    ]

    array.push(arr);
  })

  return array;
}
