

interface IProgressBar {
  totalSteps: number;
  completedSteps: number;
  height?: number;
  bgClass?: string;
}


export const ProgressBar: React.FC<IProgressBar> = ({totalSteps,completedSteps,height,bgClass}) => {
    
  const percentage = (completedSteps/totalSteps)*100;
   
    return (
      <>
        <div>
          <span id="ProgressLabel" className="sr-only">Loading</span>
  
          <span
            role="progressbar"
            aria-labelledby="ProgressLabel"
            aria-valuenow={75}
            className="block rounded-full bg-[#CECECE]"
          >
            <span
            className={`block rounded-full ${bgClass? bgClass:"bg-indigo-600"}`}
            style={{width: `${percentage}%`,height: height? `${height}px`:'4px'}}
            />
          </span>
        </div>
      </>
    )
}

