import {standardDeviation} from "./index";

export function OPQCalculator(selectedRanges:any,accessor?:any){
    accessor = accessor || "XLScore";
    
    const scores = selectedRanges.map((item:any) => item[accessor]);
    
    const sd = standardDeviation(scores);

    if(isNaN(sd)){
        return "Not Enough Data";
    }
    
    return sd;
}
