interface IOnboardingEmailReceived {
    user: any;
  }
  
  export const OnboardingEmailReceived: React.FC<IOnboardingEmailReceived> = ({user}) => {
  
    return <>
                {
                  user.hasReceivedOnboardingEmail && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0'>
                    {
                      user.onbordingEmailResponse? user.onbordingEmailResponse:"Yes"
                    }
                  </p>
                }
                {
                  !user.hasReceivedOnboardingEmail && <p className='px-3 py-2 rounded-full font-semibold text-xs bg-red-50 text-red-600 text-center rounded-md mb-0'>No</p>
                }
    </>
  }
  