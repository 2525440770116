import {USER_LOGIN,USER_RESET} from "../../constants";

interface IdefaultState {
  login: boolean;
  details: any;
}

const defaultState = {
  login: false,
  details: null
}


interface Iuser {
  type: string;
  payload?: any;
}

export function user(state:IdefaultState = defaultState, action:Iuser) {

    switch (action.type) {

      case USER_LOGIN:
        return {
          ...state,
          login: true,
          ...action.payload
        }
      case USER_RESET:
        return {
          ...state,
          login: false,
          details: null,
        }
 
      default:
        return state
    }
  }
