const dateFormet = (date: Date) =>{
    let dd:any = new Date(date).getDate();
    let mm:any = new Date(date).getMonth();
    mm++;
    let yyyy = new Date(date).getFullYear()

    mm = mm<10?`0${mm}`:mm;
    dd = dd<10?`0${dd}`:dd;

    return `${yyyy}-${mm}-${dd}`;
  }


export {dateFormet};