
interface ITbody {
    children: JSX.Element[] | JSX.Element;
}

export const Tbody: React.FC<ITbody> = ({children}) => {

    return (
        <tbody className="text-secondry-color divide-y">{children}</tbody>
    )
  }