import { useEffect, useState } from "react";
import {motion} from "framer-motion";
import ConfettiExplosion from 'react-confetti-explosion';
import { PRIMARY_COLOR, SECONDRY_BUTTON_COLOR, SECONDRY_COLOR } from "../../constants";
import cogniPulseBadge from "../../assets/img/cogniPulseBadge.png"
import { PrimaryButton } from "..";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {confettiRise} from "../../assets/sounds";
import { RootState } from "../../Store/store";
import { Modal } from "./Modal";


const data = [
  {
    title: "Fantastic Progress",
    description: "You've just unlocked a key to your Peak Performance journey. Keep going!",
  },
  {
    title: "You're on your way",
    description: "Another milestone reached. Continue to unlock your full potential.",
  },
  {
    title: "Milestone Achieved",
    description: "Your path to greatness is getting clearer. What's next?",
  },
  {
    title: "Great Job",
    description: "You've captured your state. Let's see what more you can achieve today!",
  },
  {
    title: "Bravo",
    description: "Another milestone in the bag. Ready for the next challenge?",
  },
  {
    title: "Awesome Progress",
    description: "You're closer to your unique blueprint for success.",
  },
  {
    title: "You're Doing Great",
    description: "Keep up the momentum and reach new heights.",
  },
  {
    title: "Congratulations",
    description: "You're making significant strides towards peak performance. On to the next!",
  },
  {
    title: "Well Done",
    description: "Another milestone completed. Your journey to peak performance continues!",
  },
  {
    title: "Excellent Work",
    description: "You've captured your state. Continue to discover your peak performance blueprint.",
  },
];
const myData = data[Math.floor(Math.random() * data.length)];



interface ICPPSPSIPM {
  isImPuseChanged: any;
  isPhySelfieChanged: any;
  isCogniPuseChanged: any;
  numberOfCompletedImpactPulse: any;
  numberOfCompletedPhycheSelfies: any;
  numberOfNotifications: any;
  numberOfCompletedSurveys: any;
  setShowProgress: any;
}


const CogniPulsePhySelPhySelfieImpulseProgressModal: React.FC<ICPPSPSIPM> = ({isImPuseChanged,isPhySelfieChanged,isCogniPuseChanged,numberOfCompletedImpactPulse,numberOfCompletedPhycheSelfies,numberOfNotifications,numberOfCompletedSurveys,setShowProgress}) => {
    
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [showConfetti, setShowConfetti] = useState(false);
    const PIPoints = useSelector((state:RootState) => state.globalStates.PIPoints);
    const location = useLocation();
    const lineDur = .2;
    const circleDur = .1;
    const total = lineDur+circleDur;
    const current = numberOfCompletedSurveys;
    const lastAnimation = 1;
    const timeTillConfetti = 1000*((numberOfCompletedImpactPulse+numberOfCompletedPhycheSelfies+numberOfCompletedSurveys)*total)+2000+(lastAnimation*1000);
    const timeTillProgress = (numberOfCompletedImpactPulse+numberOfCompletedPhycheSelfies+numberOfCompletedSurveys+2)*total;
    
    useEffect(() => {
      const time = setTimeout(() => {
          setShowConfetti(true);
          confettiRise.pause();
          confettiRise.currentTime = 0;
          confettiRise.play();
          setTimeout(() => {
            confettiRise.pause();
            setShowConfetti(false);
          }, 3000);
      },timeTillConfetti);
  
      return ()=> {
        clearTimeout(time);
      }
    }, [timeTillConfetti]);


    return (
        <Modal isHeader={false} headerText={<></>} setIsOpen={setShowProgress}>
            <div className="">
              <h1 className="text-center text-2xl md:text-3xl text-primary-button-color font-bold">
                  {myData.title}, {loggedInUser.forename}!
              </h1>
              {
                showConfetti && <div className="flex justify-center"><ConfettiExplosion zIndex={2001} force={.8} duration={3000} particleCount={250}/></div>
              }
              <div className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] px-4 md:px-0  mx-auto flex flex-col items-center justify-center">
                <img className="w-[90%]" src={cogniPulseBadge} alt="cogniPulseBadge"/>
              </div>

              {
                location.state?.isNonCogniPulse && <>
                  <p className="text-center text-md mb-8 text-secondry-color">
                    Success! You've captured your Impact! This is a key step towards unlocking your true potential.
                  </p>
                </>
              }
            
              {
                !location.state?.isNonCogniPulse && <>
                  <p className="text-center text-md mb-8 text-secondry-color">
                      {myData.description}
                  </p>
                </>
              }


              <div className="mb-8">
                <Steps 
                  stepsItems={new Array(numberOfNotifications).fill(1)} 
                  currentStep={current}
                  label={"Today's CogniPulses"}
                  initialDlay={0}
                  lineDur={lineDur}
                  circleDur={circleDur}
                  isChanged={isCogniPuseChanged}
                />

                <div className="h-[5px]"/>

                <Steps 
                  stepsItems={new Array(numberOfNotifications).fill(1)} 
                  currentStep={numberOfCompletedPhycheSelfies} 
                  label={"Psy-Selfies"}
                  initialDlay={(current*total)+1}
                  lineDur={lineDur}
                  circleDur={circleDur}
                  isChanged={isPhySelfieChanged}
                />

                <div className="h-[5px]"/>

                <Steps 
                  stepsItems={new Array(numberOfNotifications).fill(1)} 
                  currentStep={numberOfCompletedImpactPulse} 
                  label={"ImPulse"}
                  initialDlay={((numberOfCompletedImpactPulse+current)*total)+1}
                  lineDur={lineDur}
                  circleDur={circleDur}
                  isChanged={isImPuseChanged}
                />
              </div>

              <div className="mb-8">
                <p className="text-secondry-color text-center mb-1">Progress on your Unique Cognitive Blueprint For Flow</p>
                <div className="border-2 px-2 py-4 border-secondry-color/20 rounded-md">
                  <div className="bg-secondry-color w-full h-1">
                    <motion.div initial={{width: "0%"}} animate={{width: `${(PIPoints/(PIPoints + 800))*100}%`}} transition={{ease: 'easeInOut',duration: lastAnimation,delay: timeTillProgress}} className="h-full bg-secondry-button-color"/>
                  </div>
                </div>
              </div>

              <p className="text-secondry-color text-center mb-3">Take the Next Step Towards Peak Performance!</p>
              
              <div className="shadow-[0px_px_10px_10px_#00000024] rounded-md">
                <PrimaryButton style={{fontSize: "16px"}} onClick={()=>setShowProgress(false)}>See Your Current State & Get Personalised Coaching!</PrimaryButton>
              </div>
            </div>
        </Modal>
    )
}

export {CogniPulsePhySelPhySelfieImpulseProgressModal};
  

interface ISteps {
  stepsItems: any;
  currentStep: any;
  label: any;
  initialDlay: any;
  lineDur: any;
  circleDur: any;
  isChanged: any;
}

export const  Steps: React.FC<ISteps> = ({stepsItems,currentStep,label,initialDlay,lineDur,circleDur,isChanged}) => {
  

  return (<>
  
         <div className="mb-2">
            <h2 className="text-center mb-1 text-secondry-color font-bold">{label}</h2>
            <div className="flex justify-between items-center border-2 p-2 border-secondry-color/20 rounded-md">
                {
                  stepsItems.map((item:any,idx:number) => {
                    const isLast = isChanged? true:true;
                    const cDur = !isLast? 0:circleDur;
                    const cdelay = !isLast? 0:idx === 0? (lineDur+circleDur)+initialDlay:((lineDur+circleDur)*(idx+1))+initialDlay;
                    const lDur = !isLast? 0:lineDur;
                    const lDelay = !isLast? 0:(((lineDur+circleDur))*idx)+initialDlay;

                    return (<>
                    <div key={idx} className={`h-[34px] w-full flex items-center`}>

                        <div className="flex-1 h-[4px] bg-secondry-color">
                            <motion.div 
                                initial={{
                                  width: "0%",
                                  // boxShadow: "none",
                                  backgroundColor: SECONDRY_COLOR,
                                }} 
                                animate={{
                                  width: `100%`,
                                  // boxShadow: currentStep > idx? "0px 0px 2px 1px #39CCCC":"",
                                  backgroundColor: currentStep > idx? SECONDRY_BUTTON_COLOR:SECONDRY_COLOR,
                                }} 
                                transition={{ease: 'linear', duration: lDur, delay: lDelay}}
                                style={{originX: 0}}
                                className="w-full h-full"
                            />
                        </div>

                        <motion.div 
                          initial={{
                            // boxShadow: "none",
                            backgroundColor: PRIMARY_COLOR,
                            border: `4px solid ${SECONDRY_COLOR}`,
                            color: SECONDRY_COLOR
                          }} 
                          animate={{
                            // boxShadow: currentStep > idx? "0px 0px 5px 2px #39CCCC":"",
                            backgroundColor: currentStep > idx? SECONDRY_COLOR:"",
                            border: currentStep > idx? `4px solid ${SECONDRY_BUTTON_COLOR}`:`4px solid ${SECONDRY_COLOR}`,
                            color: currentStep > idx? `white`:SECONDRY_COLOR
                          }} 
                          transition={{ease: 'linear',duration: cDur,delay: cdelay}}  
                          className="relative h-[30px] w-[30px] rounded-full text-[12px] flex justify-center items-center text-white"
                        > 
                          {idx+1}
                        </motion.div>  

                    </div>  
                    </>)
                  })
                }
            </div>
        </div>
  </>)
}



