export function XLDataToJSON(rawData:any) {
    // console.log(data);
    let clipText = rawData;
                 
    let clipRows = clipText.split('\n');
    for (let i = 0; i < clipRows.length; i++) {
      clipRows[i] = clipRows[i].split('\t');
    }
    let jsonObj = [];
    for (let i = 0; i < clipRows.length; i++) {
      let item:any = {};
      for (let j = 0; j < clipRows[i].length; j++) {
        if (clipRows[i][j] !== '\r') {
          if (clipRows[i][j].length !== 0) {
            item[j] = clipRows[i][j];
          }
        }
      }
      jsonObj.push(item);
    }
    

    return jsonObj;
}

