interface ITable {
    children: JSX.Element[] | JSX.Element;
}

export const Table: React.FC<ITable> = ({children}) => {

    return (
      <div className="w-full flex flex-col max-h-full">
        <div className="w-full flex-grow overflow-auto">
          <table className="w-full table-auto relative text-sm text-left">
              {children}
          </table>
        </div>
      </div>
    )
  }