import {fetchRequest} from "../../utils";
import { 
    DOMAIN_NAME
} from "../../constants";


export const getUserNotifications = async () => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/notification/user`,"GET", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}


export const createNotification = async (body:any) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/notification`,"POST", body)
        
        return res;

    } catch (error) {
        console.log(error);
    }
}


export const updateNotification = async (notificationId:string,body:any) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/notification/${notificationId}`,"PUT", body)
        
        return res;

    } catch (error) {
        console.log(error);
    }
}


export const deleteNotification = async (notificationId: string) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/notification/${notificationId}`,"DELETE", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}