import { EmptyField } from "./EmptyField";

interface IPersonalSurveys {
    user: any;
  }
  
  export const PersonalSurveys: React.FC<IPersonalSurveys> = ({user}) => {
  
    if(!user?.groups?.length){
      return <EmptyField/>
    }
  
    return (
      <>
         {
            user.groups.map((group:any,idx:number) => {
              return <span key={idx}><b>{idx+1}</b> : {group.groupname} <br/></span>
            })
         } 
      </>
    )
  }
  