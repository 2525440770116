import {fetchRequest} from "../../utils";
import { 
    GET_SCHEDULE_TIMES,
    DOMAIN_NAME
} from "../../constants";
import { AppDispatch } from "../store";



export const getAllScheduledTimes = () => async (dispatch: AppDispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/all`,"GET",{});

        // console.log(res.surveyPeriodFrequencyAndTimes);
        if(res.success){
           dispatch({type: GET_SCHEDULE_TIMES ,payload: res.surveyPeriodFrequencyAndTimes});
        }
        
    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const getAllScheduledTimesForGroups = async () => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/all`,"GET",{});

        return res;
        
    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const addScheduledTime = async (data:any) => {

    try {

        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime`,"POST",data);
       
        return res;

    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const updateScheduledTime = async (timingId:string,data:any) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${timingId}`,"PUT",data);

        return res;

    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}



export const deleteScheduledTime = async (timingId:string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${timingId}`,"DELETE",{});

    return res;
}


export const getScheduledTimeByGroupId = async (groupId:string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${groupId}`,"GET",{});

    return res;
}


export const getScheduledTimeById = async (id:string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/getbyid/${id}`,"GET",{});

    return res;
}
