import { useEffect } from 'react';
import { useState } from 'react';
import { InfoIconWithToolTip, RectTooltip } from "../../../components";
import { motion, useAnimation} from "framer-motion";
// import { useParams } from 'react-router-dom';
// import { useMixPanelEvents } from './useMixPanelEvents';
import { arc } from 'd3-shape';
import {SECONDRY_COLOR,ACCENT_COLOR, QUADRANTS} from "../../../constants";
import { ArrowDownRed, ArrowUpGreen } from '../../../components/Icons';
const tooltipId = "tooltips-inside-score-in-brain";
export const XLScoreInBraintoolTipText = `A high score means you're more able to access your potential for adaptive performance. A low score means your potential is more likely to be limited to tactical performance.`; 


interface IScoreInBrainWithLabels {
  containerRef: any;
  XLScore: any;
  XLChange: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
}

const ScoreInBrainWithLabels: React.FC<IScoreInBrainWithLabels> = ({containerRef,XLScore,XLChange,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    const [width, setWidth] = useState(715);

    useEffect(() => {
      if(containerRef){
        setWidth(containerRef.current.clientWidth);
      }
    }, [containerRef]);


    return <>
        <ProgressBar currentTourStep={currentTourStep} isBrainAnimate={isBrainAnimate} setIsAnimationEnds={setIsAnimationEnds} width={width} progress={XLScore} change={parseFloat(XLChange)}/>
    </> 
}

export {ScoreInBrainWithLabels};


interface IProgressBar {
  width: any;
  progress: any;
  change: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
}

const ProgressBar: React.FC<IProgressBar> = ({width,progress,change,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    const containerwidth = width;
    width = width === 715? 270:((57/100)*width);
    const [currentProgress,setCurrentProgress]= useState(0);
    const [animationDone,setAnimationDone]= useState(false);
    const [isLabelAnimationDone,setIsLabelAnimationDone]= useState(false);
    const height = width;
    const strokeWidth = 19.2;
    const r = 116;
    const strokeDasharray = 2 * 3.14 * r;
    const strokeDashoffset = strokeDasharray * ((100 - currentProgress)/100);
    const gap = 40;
    const labelsAnimationDuration = .2;
    const SVGWidth = containerwidth;
    const SVGHeight = 380;
    const left = SVGWidth/2;
    const top = SVGHeight/2;     

    
    useEffect(() => {
        if(!isBrainAnimate){
          setAnimationDone(true);
          setIsAnimationEnds(true);
          setCurrentProgress(progress);
        }
        if(isLabelAnimationDone && isBrainAnimate){
          let time1:any = undefined;
          const timing = setInterval(() => {
            setCurrentProgress(p => {
                // if((p < (progress/4) || p > (progress-(progress/4))) && p <= progress){
                //   return p+.1;
                // }
                if(p <= progress){
                  // const t = easeOutBounce((p+.5)/progress)*progress;
                  return p+.5;
                }
                
                clearInterval(timing);
                setAnimationDone(true);
                time1 = setTimeout(() => {
                  setIsAnimationEnds(true);
                }, 2000);

                return progress;
            })
          }, 5);

          return () => {
              clearInterval(timing);
              clearInterval(time1);
          };
        }
    }, [progress,isLabelAnimationDone,setIsAnimationEnds,isBrainAnimate]);
    

    useEffect(() => {
      const tim = setTimeout(() => {
        setIsLabelAnimationDone(true);
      }, isBrainAnimate? ((((labelsAnimationDuration*1000)*4)*3)):0);

      return () => {
        clearTimeout(tim);
      };
    }, [isBrainAnimate]);


    
    return (<>
        <svg 
        id='introSelector5' 
        style={{overflow: "visible"}}  
        // onClick={userBrain} 
        // onMouseEnter={userBrain}  
        width={SVGWidth} 
        height={SVGHeight} 
        viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
          
          <defs>
            <mask id='myMask'>
              {/* Add mask content here */}
              <circle r={r} cx={left} cy={top} fill="transparent" stroke={`white`} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray+1} strokeDashoffset={strokeDashoffset+2}/>
            </mask>

            <filter id='shadow' colorInterpolationFilters="sRGB">
              <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5"/>
            </filter>
          </defs>

          <circle filter="url(#shadow)" r={r} cx={left} cy={top} fill="transparent" stroke="white" strokeWidth={strokeWidth}/>
          
         
          {/* apply gradient  */}
          <foreignObject x={0} y={0} width={SVGWidth} height={SVGHeight} mask='url(#myMask)'>
            <div style={{width: `100%`,height: `100%`,background: "conic-gradient(from 90deg at 45%, rgba(167, 0, 0, 1), rgba(255, 0, 0, 1) 15%, rgba(255, 140, 49, 1) 34%, rgba(255, 162, 49, 1) 49%, rgba(8, 200, 0, 1) 51%, rgba(91, 210, 86, 1) 56%, rgba(96, 240, 62, 1) 68%, rgba(103, 255, 0, 1) 90%, rgba(255, 253, 0, 1) 100%)"}}></div>
          </foreignObject>
          
          <Quadrants radius={r+(strokeWidth/2)} SVGWidth={SVGWidth} SVGHeight={SVGHeight} isBrainAnimate={isBrainAnimate} labelsAnimationDuration={labelsAnimationDuration}/>

          {/* only for dashboard tutorial  */}
          {
            (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3) && <>
            <circle r={r-(strokeWidth/2)} cx={left} cy={top} fill="black" style={{opacity: .4}} stroke="transparent" strokeWidth={strokeWidth}/>
            {/* <circle r={r+strokeWidth} cx={left} cy={top} fill="transparent" style={{opacity: .6}} stroke="blue" strokeWidth={strokeWidth+100}/> */}
            </>
          }
          <circle id='introSelector2' r={r+7} cx={left} cy={top} fill="transparent" stroke="transparent" strokeWidth={strokeWidth}/>
          {/* top half  */}
          <rect id='introSelector4' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top-((height-strokeWidth)/2)}/>
          {/* bottom half  */}
          <rect id='introSelector3' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top}/>
        
          <foreignObject style={{overflow: "visible",display: (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3)? "none":""}} width={r*2-gap} height={r*2-gap} x={left-((r*2-gap)/2)} y={top-((r*2-gap)/2)}>
            <XLScore r={r}  progress={currentProgress} change={change} animationDone={animationDone} isLabelAnimationDone={isLabelAnimationDone}/>
          </foreignObject>

        </svg>
        <RectTooltip id={tooltipId}/>
    </>)
}

interface IXLScore {
  progress: any;
  change: any;
  animationDone: any;
  isLabelAnimationDone: any;
  r: any;
}

const XLScore: React.FC<IXLScore> = ({progress,change,animationDone,isLabelAnimationDone,r}) => {
    const brainColor = getBrainColor(progress); 

    
    return (
        <>
          <div className="relative flex justify-center items-center w-full h-full z-20">
                <div className="absolute w-[100%] h-[100%]">
                  <BrainSVG color={brainColor} progress={progress} isLabelAnimationDone={isLabelAnimationDone}/>
                </div>
                
                <div id='introSelector1' className='myBox w-[55%] h-[55%] absolute bg-white p-1 shadow-xl rounded-full flex flex-col items-center justify-center'>
                     {
                        isLabelAnimationDone && <>
                                <div>
                                    <div style={{fontSize: `${(r/100)*10}px`}} className='font-bold text-center m-0 text-secondry-color flex gap-1 items-center'>
                                      <span>XL Score</span> 
                                      <InfoIconWithToolTip id="XLScore-tooltip" content={XLScoreInBraintoolTipText}/>
                                    </div>
                                    <div style={{color: brainColor,fontSize: `${(r/100)*26}px`,lineHeight: `${(r/100)*24}px`}} className='text-center font-bold flex justify-center items-center'>
                                      <span id='dashboard-step-1' className='p-1' >{parseFloat(progress).toFixed(0)}</span>
                                    </div>
                                </div>
                                {
                                  animationDone && <Change change={change} r={r}/>
                                }
                        </>
                     } 
                </div> 
          </div>
        </>
    )
}

interface IChange {
  change: any;
  r: any;
}

const Change: React.FC<IChange> = ({change,r}) => {
    // const { userId } = useParams();
    // const {userBrainDialBottom} = useMixPanelEvents(userId);


    return (
       <div 
        // onClick={userBrainDialBottom} 
        // onMouseEnter={userBrainDialBottom} 
        style={{fontSize: `${(r/100)*10}px`}} 
        className={`${change < 0 ? 'text-red-600':'text-green-600'} flex justify-center items-center gap-1`}
       >
         <div className="">{parseFloat(change).toFixed(0)}%</div>
         {
            change < 0 ? <ArrowDownRed className={"w-3"}/>:<ArrowUpGreen className={"w-3"}/>
         }
       </div>
    )
}

interface IBrainSVG {
  color?: any;
  progress: any;
  isLabelAnimationDone: any;
}

const BrainSVG: React.FC<IBrainSVG> = ({color = "#000000",progress,isLabelAnimationDone}) => {
  const pulse = useAnimation();
  
  useEffect(() => {
    if(isLabelAnimationDone){
      const interval = progress*20;
      const interval1 = setInterval(() => {
          pulse.start({filter: `drop-shadow(0px 0px 10px ${color})`},{duration:interval/1000,ease: "easeInOut"});
        }, interval);
        const interval2 = setInterval(() => {
        pulse.start({filter: `drop-shadow(0px 0px 0px ${color})`},{duration:interval/1000,ease: "easeInOut"});
      }, interval*2);

      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
      }
    }
  }, [pulse,color,progress,isLabelAnimationDone]);

  return (
    <>
    <svg  
    id='dashboard-step-5'
    fill={color} 
    height="100%" 
    width="100%" 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    xmlSpace="preserve"
    filter="url(#shadow)" 
    >
        <motion.svg 
        initial={{filter: `drop-shadow(0px 0px 0px ${color})`}}
        animate={pulse}
        width="90%" height="90%" x={"5%"} y={"5%"} 
        viewBox="0 0 463 463">
            <g>
                <path  d="M151.245,222.446C148.054,237.039,135.036,248,119.5,248c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5
                  c23.774,0,43.522-17.557,46.966-40.386c14.556-1.574,27.993-8.06,38.395-18.677c2.899-2.959,2.85-7.708-0.109-10.606
                  c-2.958-2.897-7.707-2.851-10.606,0.108C184.947,202.829,172.643,208,159.5,208c-26.743,0-48.5-21.757-48.5-48.5
                  c0-4.143-3.358-7.5-7.5-7.5s-7.5,3.357-7.5,7.5C96,191.715,120.119,218.384,151.245,222.446z"/>
                <path d="M183,287.5c0-4.143-3.358-7.5-7.5-7.5c-35.014,0-63.5,28.486-63.5,63.5c0,0.362,0.013,0.725,0.019,1.088
                  C109.23,344.212,106.39,344,103.5,344c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5c26.743,0,48.5,21.757,48.5,48.5
                  c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5c0-26.611-16.462-49.437-39.731-58.867c-0.178-1.699-0.269-3.418-0.269-5.133
                  c0-26.743,21.757-48.5,48.5-48.5C179.642,295,183,291.643,183,287.5z"/>
                <path d="M439,223.5c0-17.075-6.82-33.256-18.875-45.156c1.909-6.108,2.875-12.426,2.875-18.844
                  c0-30.874-22.152-56.659-51.394-62.329C373.841,91.6,375,85.628,375,79.5c0-19.557-11.883-36.387-28.806-43.661
                  C317.999,13.383,287.162,0,263.5,0c-13.153,0-24.817,6.468-32,16.384C224.317,6.468,212.653,0,199.5,0
                  c-23.662,0-54.499,13.383-82.694,35.839C99.883,43.113,88,59.943,88,79.5c0,6.128,1.159,12.1,3.394,17.671
                  C62.152,102.841,40,128.626,40,159.5c0,6.418,0.965,12.735,2.875,18.844C30.82,190.244,24,206.425,24,223.5
                  c0,13.348,4.149,25.741,11.213,35.975C27.872,270.087,24,282.466,24,295.5c0,23.088,12.587,44.242,32.516,55.396
                  C56.173,353.748,56,356.626,56,359.5c0,31.144,20.315,58.679,49.79,68.063C118.611,449.505,141.965,463,167.5,463
                  c27.995,0,52.269-16.181,64-39.674c11.731,23.493,36.005,39.674,64,39.674c25.535,0,48.889-13.495,61.71-35.437
                  c29.475-9.385,49.79-36.92,49.79-68.063c0-2.874-0.173-5.752-0.516-8.604C426.413,339.742,439,318.588,439,295.5
                  c0-13.034-3.872-25.413-11.213-36.025C434.851,249.241,439,236.848,439,223.5z M167.5,448c-21.029,0-40.191-11.594-50.009-30.256
                  c-0.973-1.849-2.671-3.208-4.688-3.751C88.19,407.369,71,384.961,71,359.5c0-3.81,0.384-7.626,1.141-11.344
                  c0.702-3.447-1.087-6.92-4.302-8.35C50.32,332.018,39,314.626,39,295.5c0-8.699,2.256-17.014,6.561-24.379
                  C56.757,280.992,71.436,287,87.5,287c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5C60.757,272,39,250.243,39,223.5
                  c0-14.396,6.352-27.964,17.428-37.221c2.5-2.09,3.365-5.555,2.14-8.574C56.2,171.869,55,165.744,55,159.5
                  c0-26.743,21.757-48.5,48.5-48.5s48.5,21.757,48.5,48.5c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5
                  c0-33.642-26.302-61.243-59.421-63.355C104.577,91.127,103,85.421,103,79.5c0-13.369,8.116-24.875,19.678-29.859
                  c0.447-0.133,0.885-0.307,1.308-0.527C127.568,47.752,131.447,47,135.5,47c12.557,0,23.767,7.021,29.256,18.325
                  c1.81,3.727,6.298,5.281,10.023,3.47c3.726-1.809,5.28-6.296,3.47-10.022c-6.266-12.903-18.125-22.177-31.782-25.462
                  C165.609,21.631,184.454,15,199.5,15c13.509,0,24.5,10.99,24.5,24.5v97.051c-6.739-5.346-15.25-8.551-24.5-8.551
                  c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5c13.509,0,24.5,10.99,24.5,24.5v180.279c-9.325-12.031-22.471-21.111-37.935-25.266
                  c-3.999-1.071-8.114,1.297-9.189,5.297c-1.075,4.001,1.297,8.115,5.297,9.189C206.8,343.616,224,366.027,224,391.5
                  C224,422.654,198.654,448,167.5,448z M395.161,339.807c-3.215,1.43-5.004,4.902-4.302,8.35c0.757,3.718,1.141,7.534,1.141,11.344
                  c0,25.461-17.19,47.869-41.803,54.493c-2.017,0.543-3.716,1.902-4.688,3.751C335.691,436.406,316.529,448,295.5,448
                  c-31.154,0-56.5-25.346-56.5-56.5c0-2.109-0.098-4.2-0.281-6.271c0.178-0.641,0.281-1.314,0.281-2.012V135.5
                  c0-13.51,10.991-24.5,24.5-24.5c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5c-9.25,0-17.761,3.205-24.5,8.551V39.5
                  c0-13.51,10.991-24.5,24.5-24.5c15.046,0,33.891,6.631,53.033,18.311c-13.657,3.284-25.516,12.559-31.782,25.462
                  c-1.81,3.727-0.256,8.214,3.47,10.022c3.726,1.81,8.213,0.257,10.023-3.47C303.733,54.021,314.943,47,327.5,47
                  c4.053,0,7.933,0.752,11.514,2.114c0.422,0.22,0.86,0.393,1.305,0.526C351.883,54.624,360,66.13,360,79.5
                  c0,5.921-1.577,11.627-4.579,16.645C322.302,98.257,296,125.858,296,159.5c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5
                  c0-26.743,21.757-48.5,48.5-48.5s48.5,21.757,48.5,48.5c0,6.244-1.2,12.369-3.567,18.205c-1.225,3.02-0.36,6.484,2.14,8.574
                  C417.648,195.536,424,209.104,424,223.5c0,26.743-21.757,48.5-48.5,48.5c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5
                  c16.064,0,30.743-6.008,41.939-15.879c4.306,7.365,6.561,15.68,6.561,24.379C424,314.626,412.68,332.018,395.161,339.807z"/>
                <path d="M359.5,240c-15.536,0-28.554-10.961-31.745-25.554C358.881,210.384,383,183.715,383,151.5c0-4.143-3.358-7.5-7.5-7.5
                  s-7.5,3.357-7.5,7.5c0,26.743-21.757,48.5-48.5,48.5c-13.143,0-25.447-5.171-34.646-14.561c-2.898-2.958-7.647-3.007-10.606-0.108
                  s-3.008,7.647-0.109,10.606c10.402,10.617,23.839,17.103,38.395,18.677C315.978,237.443,335.726,255,359.5,255
                  c4.142,0,7.5-3.357,7.5-7.5S363.642,240,359.5,240z"/>
                <path d="M335.5,328c-2.89,0-5.73,0.212-8.519,0.588c0.006-0.363,0.019-0.726,0.019-1.088c0-35.014-28.486-63.5-63.5-63.5
                  c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5c26.743,0,48.5,21.757,48.5,48.5c0,1.714-0.091,3.434-0.269,5.133
                  C288.462,342.063,272,364.889,272,391.5c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5c0-26.743,21.757-48.5,48.5-48.5
                  c4.142,0,7.5-3.357,7.5-7.5S339.642,328,335.5,328z"/>
            </g>
        </motion.svg>
    </svg>
    </>
  )
}

interface IQuadrants {
  radius: any;
  SVGHeight: any;
  SVGWidth: any;
  isBrainAnimate: any;
  labelsAnimationDuration: any;
}

const Quadrants: React.FC<IQuadrants> = ({radius,SVGHeight,SVGWidth,isBrainAnimate,labelsAnimationDuration}) => {
  
  const arcGenerator:any = arc().innerRadius(radius+10).outerRadius(radius+15);

  const tacticalPerformancePath:any = arc().innerRadius(radius-5).outerRadius(radius-5).startAngle(Math.PI/2).endAngle(Math.PI+(Math.PI/2));

  const adaptivePerformancePath:any = arc().innerRadius(radius-15).outerRadius(radius-15).startAngle(-Math.PI/2).endAngle(Math.PI/2);

  const firstLayerTextArc = arc().innerRadius(radius+30).outerRadius(radius+30);

  const firstLayerTextArcBottom = arc().innerRadius(radius+39).outerRadius(radius+39);

  const secondLayerTextArc =  arc().innerRadius(radius+50).outerRadius(radius+50);

  const secondLayerTextArcBottom =  arc().innerRadius(radius+59).outerRadius(radius+59); 



  return (
    <>
      <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`} >
          {
            QUADRANTS.map((quadrant,idx) => {
              const isBQ = idx===0 || idx===1;
              const flt:any = isBQ? firstLayerTextArcBottom:firstLayerTextArc;
              const slt:any = isBQ? secondLayerTextArcBottom:secondLayerTextArc;
              const dur = isBrainAnimate? labelsAnimationDuration:0;
              const parentDur = dur*3;
              const delay = idx*parentDur;

              return <motion.g key={idx} transition={{duration: parentDur,delay: delay}} initial={{opacity: 0}} animate={{opacity: 1}}>
                  {/* quadrant arcs  */}
                  <motion.path filter="url(#shadow)"  transition={{duration: dur,delay: delay+0}} initial={{opacity: 0}} animate={{opacity: 1}} fill={quadrant.fill} d={arcGenerator(quadrant.angles)}/>
                  
                  {/* first layer text  */}
                  <path id={`flt${idx}`} fill="black" d={flt(quadrant.angles)}/>
                  <motion.text fill={ACCENT_COLOR} transition={{duration: dur,delay: delay+.1}} initial={{opacity: 0}} animate={{opacity: 1}}>
                    <textPath style={{fontSize: "12.5px"}} startOffset={isBQ? "75%":"25%"} textAnchor='middle' href={`#flt${idx}`}>
                        {quadrant.desc}
                    </textPath>
                  </motion.text>

                  {/* second layer text  */}
                  <path id={`slt${idx}`} fill="black" d={slt(quadrant.angles)}/>
                  <motion.text fill={SECONDRY_COLOR} transition={{duration: dur,delay: delay+.2}} initial={{opacity: 0}} animate={{opacity: 1}}>
                    <textPath style={{fontSize: "16px",fontWeight: "bold"}} startOffset={isBQ? "75%":"25%"} textAnchor='middle' href={`#slt${idx}`}>
                        {quadrant.title}
                    </textPath>
                  </motion.text>

                  {/* {
                    SVGWidth > 700 && <Labels labels={quadrant.labels} radius={radius} isBrainAnimate={true} animDuration={dur} idx={idx} prevDelay={delay+.3}/>
                  } */}
              </motion.g>
            })
          }
      </g>

      <g  transform={`translate(${SVGWidth/2},${SVGHeight/2})`}>
        <path id="curve" fill='transparent' d={adaptivePerformancePath()} />
        <text fill={SECONDRY_COLOR}>
          <textPath startOffset={"25%"} textAnchor='middle' href="#curve">
            Adaptive Performance
          </textPath>
        </text>
      </g>

      <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`}>
        <path id="circlePath" fill="black" d={tacticalPerformancePath()}/>

        <text fill={ACCENT_COLOR}>
          <textPath startOffset={"75%"} textAnchor='middle' href="#circlePath">
              Tactical Performance
          </textPath>
        </text>
      </g>
    </>
  )
}

// export function getBrainColor(currentProgress){
//   const margin = 0;
//   if(currentProgress <= (7+margin)){
//     return "rgba(167, 0, 0, 1)";
//   }
//   if(currentProgress <= (15+margin)){
//     return "rgba(255, 0, 0, 1)";
//   }
//   if(currentProgress <= (34+margin)){
//     return "rgba(255, 140, 49, 1)";
//   }
//   if(currentProgress <= (49+margin)){
//     return "rgba(255, 162, 49, 1)";
//   }
//   if(currentProgress <= (51+margin)){
//     return "rgba(8, 200, 0, 1)";
//   }
//   if(currentProgress <= (56+margin)){
//     return "rgba(91, 210, 86, 1)";
//   }
//   if(currentProgress <= (68+margin)){
//     return "rgba(96, 240, 62, 1)";
//   }
//   if(currentProgress <= (90+margin)){
//     return "rgba(103, 255, 0, 1)";
//   }
//   return "rgba(255, 253, 0, 1)"
// } 


export function getBrainColor(value:any) {
  if (value < 0 || value > 100) {
    throw new Error('Value must be between 0 and 100');
  }

  const getShade = (startColor:any, endColor:any, ratio:any) => {
    const hex = (start:any, end:any, ratio:any) => Math.round(start + ratio * (end - start)).toString(16).padStart(2, '0');
    const r = hex(parseInt(startColor.slice(1, 3), 16), parseInt(endColor.slice(1, 3), 16), ratio);
    const g = hex(parseInt(startColor.slice(3, 5), 16), parseInt(endColor.slice(3, 5), 16), ratio);
    const b = hex(parseInt(startColor.slice(5, 7), 16), parseInt(endColor.slice(5, 7), 16), ratio);
    return `#${r}${g}${b}`;
  };

  const colorRanges = [
    { min: 0, max: 25, startColor: '#8B0000', endColor: '#FF0000' }, // Dark red to red
    { min: 25, max: 50, startColor: '#8B4500', endColor: '#FFA500' }, // Dark orange to orange
    { min: 50, max: 75, startColor: '#006400', endColor: '#00FF00' }, // Dark green to green
    { min: 75, max: 100, startColor: '#CCCC00', endColor: '#FFFF00' } // Dark yellow to yellow
  ];

  for (const range of colorRanges) {
    if (value >= range.min && value <= range.max) {
      const ratio = (value - range.min) / (range.max - range.min);
      return getShade(range.startColor, range.endColor, ratio);
    }
  }
}

// function Labels({radius,isBrainAnimate,animDuration,idx,prevDelay,labels}){
  
//   const totalLabels = labels.length;
//   const startAngle = idx === 0? 30:idx === 1? 120:idx === 2? 210:300;
//   const endAngle = idx === 0? 60:idx === 1? 150:idx === 2? 240:330;
//   // const iconWidth = 30;
//   const tooltipWidth = 12;
  
//   const calculateAngle = (index, totalLabels, startAngle, endAngle) => {
//     const angleRange = endAngle - startAngle;
//     const angularDistance = angleRange / (totalLabels - 1);
//     const labelAngle = (index * angularDistance) + startAngle;
//     return labelAngle * (Math.PI / 180);
//   };

//   const getCoordinates = (index) => {
//     const angle = calculateAngle(index,totalLabels,startAngle,endAngle);
//     const x =  (radius+65) * Math.cos(angle);
//     const y =   (radius+65) * Math.sin(angle);
//     return { x, y, angle};
//   };

//   const getCoordinates1 = (index) => {
//     const angle = calculateAngle(index,totalLabels,startAngle-10,endAngle+10);
//     const x =  (radius+65) * Math.cos(angle);
//     const y =   (radius+65) * Math.sin(angle);
//     return { x1: x, y1: y, angle1: angle};
//   };

//   const getTextWidthEstimation = (textContent) => {
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');
//     context.font = '12px Satoshi'; // Use the default font
  
//     const measurement = context.measureText(textContent);
//     return measurement.width;
//   }

//   const labelElements = labels.map((label, index) => {
//     const { x, y ,angle} = getCoordinates(index);
//     const { x1, y1 ,angle1} = getCoordinates1(index);
//     const temp = 110;

//     const cos = Math.cos(angle);
//     const cos1 = Math.cos(angle1);
//     const sin = Math.sin(angle);
//     const sin1 = Math.sin(angle1);
//     const sx = x;
//     const sy = y;
//     const mx = x1 + temp * cos1;
//     const my = y1 + temp * sin1;

//     const textAnchor = sin === -1? 'center':cos >= 0 ? 'start' : 'end';
//     const textWidth = getTextWidthEstimation(label.text);
//     const isLeft = cos <= 0 ? true:false;
//     const duration = isBrainAnimate? animDuration:0;
//     const delay = isBrainAnimate? (prevDelay+(index*animDuration)):0;
//     const mx1 = (idx === 0 || idx === 3)? 10:-10;
//     const mx2 = (idx === 1 || idx === 2)? -26:0;


//     return (
//       <motion.g 
//       initial={{opacity: 0}}
//       animate={{opacity: 1}}
//       transition={{duration: duration,delay: delay}}
//       key={index}>
//         <path d={`M${sx},${sy}L${mx},${my}`} stroke={SECONDRY_COLOR+"7f"} fill="none"/>
//         <circle cx={mx} cy={my} r={2} fill={SECONDRY_COLOR+"7f"} stroke="none" />
//         <foreignObject width={tooltipWidth} height={tooltipWidth} className='hidden md:block flex justify-center items-center' x={isLeft? (mx + (cos >= 0 ? 1 : -1) * 12)-20:(mx + (cos >= 0 ? 1 : -1) * 12)+textWidth+5} y={my-((tooltipWidth/2)-1)}>
//           <div className="w-full h-full flex items-end justify-center text-secondry-color">
//             <UI.ToolTipInfoIcon className="w-[12px] h-[12px]" data-tooltip-id={tooltipId} data-tooltip-content={label.tooltip}/>  
//           </div>
//         </foreignObject>
//         <text className='hidden md:block text-[12px]' x={mx+mx1+mx2} y={my+5} textAnchor={textAnchor} fill={SECONDRY_COLOR}>{label.text}</text>
//       </motion.g>
//     );
//   });

//   return labelElements;
// }











