  interface ISomethingNotFound {
    someting: string;
  }
  
  export const SomethingNotFound: React.FC<ISomethingNotFound> = ({someting}) => {
    return (
      <>
      <div className="flex justify-center items-center">
      <h5>{someting} Not Found!</h5>
      </div>
      </>
    )
  }