import { LOGIN_USER, LOGOUT_USER,UPDATE_ACTIVITIES,UPDATE_LOGIN_USER } from "../../constants";


interface IloggedInUser {
    type: string;
    payload?: any;
}


export function auth(state:any=null,action:IloggedInUser){
    switch (action.type) {
        case LOGIN_USER:
            return action.payload

        case UPDATE_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }

        case UPDATE_LOGIN_USER:
            return {
                ...state,
                ...action.payload
            }

        case LOGOUT_USER:
            return null
    
        default:
            return state;
    }
}
