import { PrimaryButton } from "../../../components";
import { displayTime } from "../utils";


interface IRadioButton {
    radios: any;
    setRadios: any;
}

export const RadioButton: React.FC<IRadioButton> = ({radios,setRadios}) => {
  
    const handleSelect = (e:any,item:any) => {
        if(e.target.id === "time"){
            setRadios((prev:any) => {
                return prev.map((radio:any) => radio.dayName === item.dayName? {...radio,time: e.target.value}:radio)
            });
            return;
        }
        setRadios((prev:any) => {
            return prev.map((radio:any) => radio.dayName === item.dayName? {...radio,isChecked: !radio.isChecked}:radio)
        })
    }

    const handleTimeClick = (item:any,time:any) => {
        setRadios((prev:any) => {
            return prev.map((radio:any) => radio.dayName === item.dayName? {...radio,time}:radio)
        });
    }

    const handleAddTime = (e:any,item:any) => {
        e.preventDefault();
        setRadios((prev:any) => {
            return prev.map((radio:any) => {
                if(radio.dayName === item.dayName){
                    return {
                        ...radio,
                        times: [...radio.times,radio.time]
                    }
                }
                return radio;
            })
        })
    }

    const handleDelete = (item:any,time:any) => {
        setRadios((prev:any) => {
            return prev.map((radio:any) => {
                if(radio.dayName === item.dayName){
                    return {
                        ...radio,
                        times: radio.times.filter((t:any) => t !== time)
                    }
                }
                return radio;
            })
        })
    }

    const sortTime = (a:any, b:any) => {
        // Split the time strings into hours and minutes
        const [aHours, aMinutes] = a.split(':').map(Number);
        const [bHours, bMinutes] = b.split(':').map(Number);
      
        // Compare hours first
        if (aHours !== bHours) {
          return aHours - bHours;
        }
      
        // If hours are the same, compare minutes
        return aMinutes - bMinutes;
    }


    return (<>
         <div>
            <h2 className="font-medium text-secondry-color">Select days</h2>
            <ul className="my-3 mb-6 space-y-3">
                {/* Radio groups */}
                {
                    radios.map((item:any, idx:number) => (
                        <li key={idx} className="flex flex-col justify-center gap-2">
                            <div className='flex flex-wrap gap-2 items-center'>
                                <input onChange={(e)=>handleSelect(e,item)} type="checkbox" checked={item.isChecked} name={item.dayName} id={`${idx}`} className="accent-accent-color form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150" />
                                <label htmlFor={`${idx}`} className="text-sm text-secondry-color font-medium">
                                    {item.dayName}
                                </label>
                                {
                                    item.isChecked && item.times.sort(sortTime).map((time:any,idx:number) => (
                                    <span key={idx} className='text-xs bg-secondry-color/40 text-white px-1 font-bold rounded-md flex items-center gap-1'>
                                        <span onClick={()=> handleTimeClick(item,time)}>{displayTime(time)}</span>
                                        <span onClick={()=> handleDelete(item,time)} title="Delete" className="cursor-pointer"><DeleteSVG/></span>
                                    </span>
                                    ))
                                }
                            </div>
                            {
                                item.isChecked && <>
                                    <div className='flex gap-2'>
                                        <input onChange={(e)=>handleSelect(e,item)} value={item.time} type="time" id="time" className="h-[44px] bg-secondry-color/10 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-0 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        <div>
                                            <PrimaryButton disabled={item.time?false:true} onClick={(e:any)=>handleAddTime(e,item)}>
                                                Add Time
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </>
                            }
                        </li>
                    ))
                }
            </ul>
        </div>
    </>)
};



function DeleteSVG(){
    return <svg  className="text-secondry-color dark:text-gray-500 w-[10px] h-[10px] mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
}

