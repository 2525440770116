import React, { useEffect, useState } from 'react';
import { 
    Header,
    Wrapper 
} from "./index";
import { Main } from '../../../layout';
import { Loading, PrimaryButton,SurveyUI} from '../../../components';
import { toast } from 'react-toastify';
import { createPriPostSurvey, getSurveyTitleByUserIdAndSurveyId, updateLoggedInUser } from '../../../Store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollToTop } from "../../../hooks";
import { getUserActivities } from '../../../Store/actions/activity';
import { AppDispatch, RootState } from '../../../Store/store';

  interface ISetYourBaseline {
    setScreen: any;
  }

  export const  SetYourBaseline: React.FC<ISetYourBaseline> = ({setScreen})  => {
      const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
      useScrollToTop();

      const handleBack = () => {
        setScreen((prev:any) => {
          return {
            ...prev,
            baiseline: true,
            organisationName: true
          }
        })
      }

    return (
          <Main>
              <Wrapper>
                  <Header handleBack={handleBack} title="Set Your Baseline"/>
                  <Index setScreen={setScreen} userId={loggedInUser._id}/>
              </Wrapper> 
          </Main> 
    )
  }

  interface IIndex {
    setScreen: any;
    surveyId?: any;
    userId: any;
  }

  const  Index: React.FC<IIndex> = ({setScreen,surveyId,userId})  => {
        const [survey,setSurvey] = useState<any>(null);
        const [user,setUser] = useState<any>(null);
        const [questions,setQuestions] = useState(null);
        const [answers, setAnswers] = React.useState<any>(null);
        const [isPreDone, setIsPreDone] = React.useState(false);
        const [isSurveyDone, setIsSurveyDone] = React.useState(false);
        const [isSubmiting, setIsSubmiting] = useState(false);
        const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
        const dispatch: AppDispatch = useDispatch();
    
        const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
            setIsSubmiting(true);
            const myAnswers = [...answers,{rating: lastAns,XLFactor: lastFactor,starttime,endtime: new Date().getTime()}];
            const data = {
                answers: myAnswers,
                surveytitle: survey._id,
                user: user._id 
            };
            
            const res = await createPriPostSurvey(data);
            // console.log(res);
            if(res.success){
                dispatch(updateLoggedInUser({isPreSurveyDone: true}));
                setIsSurveyDone(true);
            }
            setIsSubmiting(false);
        }
    
        useEffect(() => {
        (async()=>{
            const tUserId = userId || loggedInUser._id;
            const tSurveyId = surveyId || loggedInUser.preSurveyId;
            const res = await getSurveyTitleByUserIdAndSurveyId(tUserId, tSurveyId,false);
            const res1 = await getUserActivities();

            if(res.success && res1.success){
            setSurvey(res.surveyTitle);
            setUser(res.user);
            if(res.user.organizationName){
                const surveyName = res1.activities[0].category;
                const title = res1.activities[0].name;
                const questions = replaceQuestionsPlaceholders(res.surveyTitle.questions,res.user.organizationName,res.user.personalImprovementGoals,res.user.personalImprovementObstacles,surveyName,title)
                setQuestions(questions);
            }
            }
            else {
            toast.error("Something Went Wrong!");
            }
        })()
        }, [userId,surveyId,loggedInUser]);
    

        if(!survey || !user){
        return <div className="w-full h-[93vh]"><Loading/></div>
        }
    
        if(!isPreDone){
          return <PreSurvey  setIsPreDone={setIsPreDone}/>
        }
    
        if(isSurveyDone){
          return <AfterSurveyPage setScreen={setScreen}/>
        }
    
        return (
        <>
            <div className='h-[20px]'/>
            {/* <p className='text-center text-secondry-color mb-2 font-bold'>Setting your baseline</p> */}
            <SurveyUI isSubmiting={isSubmiting} handleSubmit={handleSubmit} questions={questions} answers={answers} setAnswers={setAnswers}/>
        </>
        )
  }

  interface IPreSurvey {
    setIsPreDone:any;
  }
  
  const  PreSurvey: React.FC<IPreSurvey> = ({setIsPreDone}) => {

    const handleNext = () => {
      setIsPreDone(true);
    }
  
    
    return (
      <> 
                  {/* <p className='text-secondry-color mb-6 font-bold text-center'>Setting Your Baseline</p> */}
                  <p className='text-secondry-color mb-6'>Let's Personalise Your Journey</p>
                  <p className='text-secondry-color mb-6'>We need some insights from you to tailor your experience and help you track your growth with XLRate</p>
                  <p className='text-secondry-color mb-3 font-bold'>Why Provide Baseline Data?</p>
                  <ul className='list-disc mb-6 pl-6 text-secondry-color'>
                    <li className=''><span className='font-bold'>Personalised Feedback:</span> Get tailored insights and actionable recommendations</li>
                    <li className=''><span className='font-bold'>Track Progress:</span> Measure your improvement and celebrate milestones</li>
                    <li className=''><span className='font-bold'>Optimise Performance:</span> Identify and enhance areas of growth</li>
                  </ul>
                  <p className='text-secondry-color mb-6'>Ready to start? Let’s set your baseline!</p>
                
                  <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                    Start Now
                  </PrimaryButton>
      </>
    )
  }

  interface IAfterSurveyPage {
    setScreen: any;
  }
  
  const  AfterSurveyPage: React.FC<IAfterSurveyPage> = ({setScreen}) => {



    const handleNext = async () => {
        setScreen((prev:any) => {
            return {
                ...prev,
                baiseline: false,
                welcome: true
            }
        })
    }
  
  
    return (<>
        <p className='text-secondry-color mb-6'>Thank You!</p>
  
        <p className='text-secondry-color mb-6'>You've completed your baseline setting</p>

        <p className='text-secondry-color mb-3 font-bold'>Your personalised journey with XLRate begins now. Here’s what’s next</p>

        <ul className='list-disc mb-6 pl-6 text-secondry-color'>
            <li className=''>How XLRate works</li>
            <li className=''>Set Goals: Tailored goals based on your input</li>
            <li className=''>1st CogniPulse</li>
            <li className=''>Your Personal Growth Dashboard</li>
        </ul>

        <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
            Next
        </PrimaryButton>
    </>)
  }
  
  export function replaceQuestionsPlaceholders(questions:any,organizationName:any,personalImprovementGoals:any,personalImprovementObstacles:any,surveyName:any,title:any){
    questions = questions.map((ques:any) => {
      let question = ques.question.replace("__organisation__", organizationName);
      question = question?.replace("[insert survey subject]", surveyName);
      question = question?.replace("[insert user input name/title]", title);
      question = question?.replace("study", "Studies");

      const quesArr = question.split(" ").filter((str:any) => str !== "").map((str:any) => {
        const goalNumber = getGoalNumber(str);
        let gaolToAdd = undefined;
        if(goalNumber !== null){
          str = personalImprovementGoals[goalNumber-1] || "";
        }
        if(gaolToAdd){
          str = gaolReplace(str,gaolToAdd);
        }

        const osbNumber = getObsNumber(str);
        
        let obsToAdd = undefined;
        if(osbNumber !== null){
          obsToAdd = personalImprovementObstacles[osbNumber-1] || "";
        }
        if(obsToAdd !== undefined){
          str = obsReplace(str,obsToAdd);
        }

        return str;
      })
      question = quesArr.join(" ");

      
      return {...ques,question};
    })

    return shuffleArray(questions);
  }
  
  export function getObsNumber(str:any) {
    if(!str){
      return null;
    }
    const regex = /_yourPersonalobstacle_(\d+)_/;
    const match = str.match(regex);
    
    if (match) {
      return match[1]; // Extract the number
      // console.log(`Extracted number: ${number}`);
      // return str.replace(regex, replacement);
    }
    
    return null; // Return the original string if no match is found
  }

  export function obsReplace(str:any,replacement:any) {
    const regex = /_yourPersonalobstacle_(\d+)_/;
    const match = str.match(regex);
    
    if (match) {
      // return match[1]; // Extract the number
      // // console.log(`Extracted number: ${number}`);
      return str.replace(regex, replacement);
    }
    
    return str; // Return the original string if no match is found
  }

  export function getGoalNumber(str:any) {
    if(!str){
      return null;
    }
    const regex = /_yourPersonalGoal_(\d+)_/;
    const match = str.match(regex);
    
    if (match) {
      return match[1]; // Extract the number
      // console.log(`Extracted number: ${number}`);
      // return str.replace(regex, replacement);
    }
    
    return null; // Return the original string if no match is found
  }

  export  function gaolReplace(str:any,replacement:any) {
    const regex = /_yourPersonalGoal_(\d+)_/;
    const match = str.match(regex);
    
    if (match) {
      // return match[1]; // Extract the number
      // // console.log(`Extracted number: ${number}`);
      return str.replace(regex, replacement);
    }
    
    return str; // Return the original string if no match is found
  }
  

  function shuffleArray(array:any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }