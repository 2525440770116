import React,{useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import {Tree} from "./components"; 
import {
  getAllGroups,
  getAllScheduledTimesForGroups,
  getAllSurveyTitles
} from "../../Store/actions";
import { Loading } from '../../components';
import {Main} from "../../layout";



const  Groups: React.FC<any> = () => {
  const [groups, setGroups] = useState(null);
  const [scheduledTimes, setScheduledTimes] = useState(null);
  const [surveyTitles, setSurveyTitles] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async ()=> {
      const res = await getAllGroups();
      console.log(res);
      if(res.success){
        setGroups(res.groups);
      }

      const res1 = await getAllScheduledTimesForGroups();
      if(res1.success){
        setScheduledTimes(res1.surveyPeriodFrequencyAndTimes);
      }

      const res2 = await getAllSurveyTitles();
      if(res2.success){
        setSurveyTitles(res2.surveyTitles);
      }
    })()
  }, [dispatch]);

  
  if(!groups){
    return <Main><div className="w-full h-[calc(100vh-64px-57px)] flex justify-center items-center"><Loading/></div></Main>
  }
  
  return (
    <>
    <Main>
      {
        groups && <Tree data={groups} scheduledTimes={scheduledTimes} setScheduledTimes={setScheduledTimes} surveyTitles={surveyTitles}/>
      }
    </Main>
    </>
  )
}

export default Groups;


