interface IQuestion {
    ques: any;
    i: any;
    handleDeleteQuestion: any;
  }
  
  export const Question: React.FC<IQuestion> = ({ques,i,handleDeleteQuestion}) => {
  
    return (
      <>
      <div className="flex justify-between p-2 shadow-sm bg-white rounded">
        <div className="grow-1">
          {i+1}: {ques.question}
        </div>
        <div className="">
          <button 
          className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
          onClick={()=> handleDeleteQuestion(ques._id)}
          >Delete</button>
        </div>
      </div>
      </>
    )
  }
  