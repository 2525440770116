import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Store/store";
import { useEffect, useState } from "react";
import { updateLoggedInUser } from "../../../Store/actions";
import { replaceQuestionsPlaceholders } from "../../OnBoarding/components/SetYourBaseline";
import { getUserActivities } from "../../../Store/actions/activity";
import { PrimaryButton } from "../../../components";

interface IEnterOrganizationName {
    setQuestions: any;
    survey: any;
    organizationName: any;
    setOrganizationName: any;
  }
  
  
  export const EnterOrganizationName: React.FC<IEnterOrganizationName> = ({setQuestions,survey,organizationName,setOrganizationName}) => {
    const dispatch:AppDispatch = useDispatch();
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [activities, setActivities] = useState<any>(null);
  
    const handleClick = () => {
      if(!activities){
        return
      }
      dispatch(updateLoggedInUser({organizationName}));
      const surveyName = activities[0].category;
      const title = activities[0].name;
      const questions = replaceQuestionsPlaceholders(survey.questions,loggedInUser.organizationName,loggedInUser.personalImprovementGoals,loggedInUser.personalImprovementObstacles,surveyName,title);
      setQuestions(questions);
    }
  
  
    useEffect(() => {
      (async () => {
        const res = await getUserActivities();
  
        if(res.success){
          setActivities(res.activities);
        }
      })()
    }, []);
  
    return (
      <>
  
             <div className='flex items-center justify-center w-full h-[calc(100vh-64px)]'>
                <div className='w-[500px] max-w-full w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8'>
                    <div className='mb-4'>
                      <img className='w-[400px] max-w-full' src='https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png' alt='logo'/>
                    </div>
                    
                    <p className='mb-6 text-secondry-color'>We'd Like to Personalise Your Experience</p>
                    {/* <p className='mb-6 text-secondry-color'>This helps us tailor your experience and provide relevant organisational insights</p> */}
  
                    <div className='flex flex-col text-secondry-color mb-8'>
                      <label htmlFor='organizationName' className='mb-2 font-bold' >Please enter the name of your organisation</label>
                      <input id='organizationName' placeholder='e.g. Acme Corporation' className='bg-secondry-color/20 border border-secondry-color rounded px-3 py-1' type='text' value={organizationName} onChange={(e)=> setOrganizationName(e.target.value)}/>
                      <small className='text-secondry-color/50 mt-1'>Your organisation name helps us personalise your experience. Your data is secure</small>
                    </div>
                  
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleClick} disabled={!organizationName? true:false}>
                        Submit Organisation Name
                    </PrimaryButton>
                </div>
             </div>
      </>
    )
  }
  