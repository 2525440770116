import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { usePreviousLocationSetter } from "./usePreviousLocationSetter";
import { RootState } from "../../Store/store";



export function ManagerRoutes() {
  const loggedInUser  = useSelector((state: RootState) => state.loggedInUser);
  usePreviousLocationSetter();
  
  if (!loggedInUser) {
    return <Navigate to="/login" replace />;
  }
  

  if(loggedInUser.role !== "manager" && loggedInUser.role !== "admin"){
    return <Navigate to="/login" replace />;
  }

  return <Outlet/>;
}
