import dayjs from 'dayjs';

function formatGoal(arr:any){

    if(!arr){
        return "Not Set";
    }
    if(arr.length === 0){
        return "Not Set";
    }

    const newArry = arr.map((item:any,i:number) => i !== 5? item:dayjs(item).format("DD-MM-YYYY"));

    return newArry.join(" ");
};


export {formatGoal};