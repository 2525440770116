import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components";

interface IAfterSurveyPage {}

export const AfterSurveyPage: React.FC<IAfterSurveyPage> = () => {
  const navigator = useNavigate();

  
  const handleNext = async () => {
    navigator(`/my-dashboard`);
    return;
  }


  return (<>
    <>

            <p className='text-secondry-color mb-6'>Thank You!</p>

            <p className='text-secondry-color mb-6'>You've completed your baseline setting</p>
          
            <p className='text-secondry-color mb-3 font-bold'>Your personalised journey with XLRate begins now. Here’s what’s next</p>
            <ul className='list-disc mb-6 pl-6 text-secondry-color'>
              <li className=''>How XLRate works</li>
              <li className=''>Set Goals: Tailored goals based on your input</li>
              <li className=''>1st CogniPulse</li>
              <li className=''>Your Personal Growth Dashboard</li>
            </ul>

          
            <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
              Next
            </PrimaryButton>
    </>
  </>)
}
