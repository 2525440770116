import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getBrainColor, ScoreInBrainWithLabels } from './ScoreInBrainWithLabels';
import { mapper__100_100_to_0_100 } from '../../../utils';
import { ActionChallengeModal, BackButton, EventModal, Factors, PrimaryButton, SecondryButton } from '../../../components';
import { tempImg } from '../../../assets';
import { FACTOR_COLORS, QUADRANTS } from '../../../constants';
import { useIsIncreaseOrDicrease } from './ActionChallenges';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { InfoIconWithToolTip } from '../../../components';
import {flowStates} from "../constant";
import { getCurrentStateData } from '../utils';
import { useScrollToTop } from '../../../hooks';
import { CogniPulseButton } from './CogniPulseButton';
import { ImpulseButton } from './ImpulseButton';
import { RootState } from '../../../Store/store';


interface IMobileView {
  selectedActivity: any;
  setIsYourInsightsFullViewOn: any;
  historiclData: any;
  setIsAnimation: any;
  percentageOfChangeOfFactors: any;
  factorsMinMax: any;
  scoreOfAllfactors: any;
  currentActionChallengesWithSteps: any;
  higestDiffrance: any;
  XLScoreContainer: any;
  isYourInsightsFullViewOn: any;
  currentTourStep: any;
  isAnimation: any;
  XLScore: any;
  XLChange: any;
}


  export const MobileView: React.FC<IMobileView> = ({selectedActivity,setIsYourInsightsFullViewOn,historiclData,setIsAnimation,percentageOfChangeOfFactors,factorsMinMax,scoreOfAllfactors,currentActionChallengesWithSteps,higestDiffrance,XLScoreContainer,isYourInsightsFullViewOn,currentTourStep,isAnimation,XLScore,XLChange}) => {
    const [isAnimationEnds, setIsAnimationEnds] = useState(false);
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [isShowStatesScreen, setIsShowStatesScreen] = useState(false);
    const [isShowCoachingScreen, setIsShowCoachingScreen] = useState(false);
    const [isShowStateInfoScreen, setIsShowStateInfoScreen] = useState(false);
    const [, setStateYouWantToGoIn] = useState(null);
    const [showXLScoreSVG, setShowXLScoreSVG] = useState(true);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const location = useLocation();
    const brainColor = getBrainColor(mapper__100_100_to_0_100(XLScore));
    const state = getCurrentStateData(XLScore,brainColor);
  
   
    const labels = QUADRANTS.find(qd => qd.title === state.currentFlow.quadrant)?.labels;
    const top3 = getRandomObjects(labels,3);
  
    
    useEffect(() => {
      setIsShowStatesScreen(false);
      setIsShowCoachingScreen(false);
      setIsShowStateInfoScreen(false);
      setStateYouWantToGoIn(null);
      setShowXLScoreSVG(true);
    }, [location.state?.time]);
    
    useEffect(() => {
      if(isAnimation && isAnimationEnds){
        setShowXLScoreSVG(false);
        setIsAnimationEnds(false);
      }
    }, [isAnimation,isAnimationEnds]);

    
    return <>
          <div ref={XLScoreContainer} className="w-full max-w-[715px] mx-auto">
              <p className="text-sm font-normal text-center font-bold text-secondry-color">{dayjs(historiclData[historiclData.length-1]?.year).format("DD-MM-YYYY")}</p>
              {
                !isYourInsightsFullViewOn && showXLScoreSVG &&  <ScoreInBrainWithLabels currentTourStep={currentTourStep} isBrainAnimate={isAnimation} setIsAnimationEnds={setIsAnimationEnds} containerRef={XLScoreContainer} XLScore={mapper__100_100_to_0_100(XLScore)} XLChange={XLChange}/>
              }
              {
                showXLScoreSVG && !isAnimation && <div className='pb-4'>
                  <div className='gap-2 mt-2 mb-6 flex'>
                    <CogniPulseButton selectedActivity={selectedActivity}/>
                    <ImpulseButton selectedActivity={selectedActivity}/>
                  </div>
                  <SecondryButton onClick={()=> {setIsEventModalOpen(true)}}>Add Psy-Selfie</SecondryButton>
                  <SecondryButton style={{marginTop: "15px"}} onClick={()=> {setShowXLScoreSVG(false);setIsAnimation(false);}}>Personal Coaching</SecondryButton>
                  <SecondryButton style={{marginTop: "15px"}} onClick={()=> setIsYourInsightsFullViewOn(true)}>Deeper Insights</SecondryButton>
                </div>
              }

              <EventModal
                isOpen={isEventModalOpen}
                setIsOpen={setIsEventModalOpen}
                userId={loggedInUser._id}
                isUpdate={false}
                data={{}}
                setEvents={()=>{}}
                eventId={""}
                isManagemaneEvent={false}
                surveyTitle={null}
                setIsPhySelfieChanged={()=>{}}
              />
          </div>
          
  
          {
            isShowStatesScreen && <StatesScreen setStateYouWantToGoIn={setStateYouWantToGoIn} setIsShowStatesScreen={setIsShowStatesScreen} setIsShowCoachingScreen={setIsShowCoachingScreen}/>
          }
          {
            isShowCoachingScreen && <CoachingScreen 
                                      higestDiffrance={higestDiffrance} 
                                      currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                                      setIsShowCoachingScreen={setIsShowCoachingScreen} 
                                      setIsShowStatesScreen={setIsShowStatesScreen}
                                      percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                                      factorsMinMax={factorsMinMax}
                                      scoreOfAllfactors={scoreOfAllfactors}
                                      setShowXLScoreSVG={setShowXLScoreSVG}
                                      selectedActivity={selectedActivity}
                                    />
          }
          {
            isShowStateInfoScreen && <InfoScreen currentFlow={state.currentFlow} setIsShowStateInfoScreen={setIsShowStateInfoScreen}/>
          }
          {
            !showXLScoreSVG && <GreetScreen setIsAnimation={setIsAnimation} setShowXLScoreSVG={setShowXLScoreSVG} XLScore={XLScore} XLChange={XLChange} setIsShowStateInfoScreen={setIsShowStateInfoScreen} setIsShowStatesScreen={setIsShowStatesScreen} state={state} top3={top3}/>
          }
          
    </>
  }


interface IStatesScreen {
  setIsShowStatesScreen: any;
  setIsShowCoachingScreen: any;
  setStateYouWantToGoIn: any;
}


  const StatesScreen: React.FC<IStatesScreen> = ({setIsShowStatesScreen,setIsShowCoachingScreen,setStateYouWantToGoIn}) => {
    const [state, setState] = useState(null);
    const [data,setData] = useState(flowStates);
    useScrollToTop();    

    const handleSelect = (item:any) => {
      console.log(item);
      setState(item.title);
      setData(prev => {
        return prev.map(d => d.title === item.title? {...d,isSelected: true}:{...d,isSelected: false})
      })
    }

  
    const handleGetCoachingActions = () => {
      setStateYouWantToGoIn(state);
      setIsShowStatesScreen(false);
      setIsShowCoachingScreen(true);
    }
  
    return (<>
            <div className="fixed top-[64px] left-0 w-[100vw] h-[calc(100vh-64px)] bg-primary-color">
              <div className="w-full h-full px-2 pb-6 overflow-auto">
                  <div className='mb-2 mt-2'>
                    <BackButton onClick={()=> setIsShowStatesScreen(false)} />
                  </div>
                  <div className="w-full flex flex-wrap gap-5 justify-center transition-all">
                    {
                      data.map((item:any,idx) => (
                        <div onClick={()=>{handleSelect(item)}} key={idx} className={`${item.isSelected? "bg-secondry-button-color text-white":"bg-white"} hover:bg-secondry-button-color hover:text-white border p-4 rounded-md w-[170px] min-h-[100px]`}>
                            {/* circle  */}
                           <a href="#peakStateActionChallenges" onClick={(e)=> {e.preventDefault()}} className={`active:scale-105 w-[100px] h-[100px] bg-red-200 mx-auto bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 flex justify-center items-center rounded-full shadow-[-18px_21px_36px_4px_#00000024]`}>
                                                        <div  
                                                        className="
                                                        cursor-pointer w-[80%] h-[80%] rounded-full bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] select-none
                                                        active:scale-110
                                                        transition-all
                                                        ">
                                                          <img src={item.url} alt="flow" className="w-full h-full"/>
                                                        </div>
                           </a>
                           {/* text  */}
                           <h4 className="text-xs font-bold text-secondry-color text-center mt-6 mb-2">{item.title}</h4>
                           <p className="text-xs text-secondry-color text-center">{item.description}</p>
                        </div>
                      ))
                    }
                  </div>
                  {/* user will select one of the state then click on the butrtton below then they will see the section with id of Coaching-Actions */}
                  <PrimaryButton disabled={state? false:true} style={{marginTop: "15px"}} onClick={handleGetCoachingActions}>Get Coaching Actions</PrimaryButton>
              </div>
            </div>
    </>)
  }
  
  interface ICoachingScreen {
    selectedActivity: any;
    setShowXLScoreSVG: any;
    percentageOfChangeOfFactors: any;
    factorsMinMax: any;
    scoreOfAllfactors: any;
    higestDiffrance: any;
    currentActionChallengesWithSteps: any;
    setIsShowCoachingScreen: any;
    setIsShowStatesScreen: any;
  }


  const CoachingScreen: React.FC<ICoachingScreen> = ({selectedActivity,setShowXLScoreSVG,percentageOfChangeOfFactors,factorsMinMax,scoreOfAllfactors,higestDiffrance,currentActionChallengesWithSteps,setIsShowCoachingScreen,setIsShowStatesScreen}) => {
    const [currentActionChallenge, setCurrentActionChallenge] = useState("");
    const [showActionChallengeModal, setShowActionChallengeModal] = useState(false);
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [insDec] = useIsIncreaseOrDicrease();
    useScrollToTop();  
    // const afterComplete = () => {
    //   setIsShowCoachingScreen(false);
    //   setIsShowStatesScreen(false);
    //   setShowXLScoreSVG(true);
    // }
    

    if(showActionChallengeModal){
      return <ActionChallengeModal forTeam={false} currentActivityName={selectedActivity?.name}  userId={loggedInUser._id} factor={higestDiffrance.factor} currentActionChallenge={currentActionChallenge} setShow={setShowActionChallengeModal} show={showActionChallengeModal}/>
    }

    return (<>
          <div className="fixed top-[64px] left-0 w-[100vw] h-[calc(100vh-64px)] bg-primary-color">
            <div className='w-full max-h-full px-2 pb-6 overflow-auto'>
              <div className='mb-2 mt-2'>
                  <BackButton onClick={()=> {setIsShowCoachingScreen(false);setIsShowStatesScreen(true)}}/>
              </div>

              <h1 className='text-[16px] md:text-[18px] rounded font-bold relative z-30 mb-4 text-secondry-color'>Unlock Your Peak Performance with XLRate</h1>

              <p className='text-secondry-color text-center text-sm'>Get Ready for Your Personalised Action Map!</p>
              <div className="mb-4 relative w-full mx-auto md:my-10 border-[3px] rounded-md border-accent-color">
                            <div className="w-full h-full flex justify-center items-center blur-sm">
                                <img src={tempImg} alt="temp" className="w-full h-full"/>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full z-[10]">
                                <div className="w-full h-full flex justify-center items-center">
                                        <div className="p-2 md:p-8 bg-white/60">
                                            <h2 className="text-xs md:text-lg font-bold text-center text-accent-color leading-6 md:leading-12"> 
                                                We're preparing your unique Cognitive Blueprint for Peak Performance. 
                                                Stay tuned for a notification when your insights are ready. 
                                                Set your <Link to="/manage/notifications" className="text-primary-button-color mx-2 underline">CogniPulse notifications</Link> to fit your schedule, and each pulse will bring you closer to unlocking your peak performance state.
                                            </h2>
                                        </div>
                                </div>
                            </div>
              </div>

              <div>
                    <h4 className="title flex justify-center gap-2 items-center font-bold mb-2 text-secondry-color" >Your Current XL factors</h4>
                    <p className='text-xs text-secondry-color'>Our system has analysed your current factors to identify critical areas for improvement. These specially designed Coaching actions will transform these factors and unlock your full potential.</p>
                    <p className='text-xs text-secondry-color mb-3 mt-3'>Take Coaching Action Now! Choose a simple action to boost your experience and enhance your XL:</p>

                    <div className="w-[315px] mx-auto mb-4">
                        <Factors 
                            Fun={scoreOfAllfactors?.Fun}
                            Purpose={scoreOfAllfactors?.Purpose}
                            Growth={scoreOfAllfactors?.Growth}
                            Emotives={Math.abs(scoreOfAllfactors?.Emotives)}
                            Utility={Math.abs(scoreOfAllfactors?.Utility)}
                            Apathy={Math.abs(scoreOfAllfactors?.Apathy)}
                            factorsMinMax={factorsMinMax}
                            percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                        />
                    </div>
              </div>
              

              <div className="text-[11px] md:text-[14px]">
                    <h1 className='text-[16px] md:text-[18px] rounded font-bold relative z-30 mb-4 text-secondry-color'>
                            <p className="text-center">
                                {`Click one Coaching action now & improve your XL by ${insDec(higestDiffrance.factor)}`}
                                <span id="introSelector10" className="bg-secondry-color rounded-md p-[2px] ml-1">
                                    <span style={{color: FACTOR_COLORS[`${higestDiffrance.factor}`.toUpperCase() as keyof typeof FACTOR_COLORS]}} className="px-2 underline">{higestDiffrance.factor}</span> 
                                </span>
                            </p>
                    </h1>
                    
                    <div id='dashboard-step-9' className="flex flex-wrap gap-2">
                        {
                            currentActionChallengesWithSteps && currentActionChallengesWithSteps.map((ch:any,i:number) => {
                                return <div
                                    key={i}
                                    className="w-full"
                                    id={i === 0? "introSelector11":""}
                                    >
                                        <SecondryButton
                                            onClick={()=>{
                                                setCurrentActionChallenge(ch);
                                                setShowActionChallengeModal(true);
                                            }} 
                                            key={i} 
                                            style={{padding: "12px 10px"}}
                                        >
                                            {ch.actionChallenge}
                                        </SecondryButton>
                                </div>
                            })
                        }
                    </div>
              </div>
              {/* {
                      <ActionChallenges 
                          afterComplete={afterComplete}
                          higestDiffrance={higestDiffrance} 
                          currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                          user={user}
                          currentActivityName={selectedActivity?.name}
                      />
              } */}
            </div>
          </div>
    </>)
  }
  
  interface IInfoScreen {
    setIsShowStateInfoScreen: any;
    currentFlow: any;
  }
  

  const InfoScreen: React.FC<IInfoScreen> = ({setIsShowStateInfoScreen,currentFlow}) => {
    const labels = QUADRANTS.find(qd => qd.title === currentFlow.quadrant)?.labels;
    useScrollToTop();  
  
    return (<>
          <div  className="bg-primary-color fixed top-[64px] left-0 w-[100vw] h-[calc(100vh-64px)]">
              <div className="w-full h-full px-2 pb-6 overflow-auto">
                <div className='mb-2 mt-2'>
                    <BackButton onClick={()=> {setIsShowStateInfoScreen(false)}}/>
                </div>
                <h2 className='text-xl font-bold text-secondry-color'>{currentFlow.quadrant}</h2>
                <ul className='list-disc text-secondry-color'>
                    {
                        labels?.map((label,idx) => {
                            return <li key={idx} className='mt-2 ml-5'>
                                     <span className='text-sm font-bold'>{label.text}</span>
                                     <ul className='text-secondry-color'>
                                        <li className='ml-0 text-xs'>({label.tooltip})</li>
                                     </ul>
                                  </li>
                        })
                    }
                </ul>
                <SecondryButton onClick={()=>setIsShowStateInfoScreen(false)} style={{marginTop: "20px"}}>OK</SecondryButton>
              </div>
          </div>
    </>)
  }

  interface IGreetScreen {
    state: any;
    setIsAnimation: any;
    setShowXLScoreSVG: any;
    XLScore: any;
    XLChange: any;
    setIsShowStateInfoScreen: any;
    setIsShowStatesScreen: any;
    top3: any;
  }


  const GreetScreen: React.FC<IGreetScreen> = ({state,setIsAnimation,setShowXLScoreSVG,XLScore,XLChange,setIsShowStateInfoScreen,setIsShowStatesScreen,top3}) => {
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const brainColor = getBrainColor(mapper__100_100_to_0_100(XLScore));
    useScrollToTop();  



    return <>
        <div className="w-full p-2 pt-0">
            <div className='mb-4'>
                    <BackButton onClick={()=> {setShowXLScoreSVG(true);setIsAnimation(false)}}/>
            </div>

            <div style={{backgroundColor: brainColor}} className="text-secondry-color w-[100%] h-[80px] rounded-md text-3xl  border-4 border-secondry-color mx-auto flex justify-center items-center font-bold text-secondry-color mb-3">XLScore - {mapper__100_100_to_0_100(XLScore).toFixed(2)}</div>

            <h3 className="text-lg font-bold text-secondry-color">{state.greetMessage} {loggedInUser.forename},</h3>
        
            {
              Number(XLChange) !== 0 && <p className="text-secondry-color mt-3">Your XLRate score changed by <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md`}>{Number(XLChange) > 0? "+":""}{XLChange}%</span></p>
            }
            
            <p className="text-secondry-color mt-2">You are currently in a <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md`}>{state.currentFlow?.quadrantLevel} {state.currentFlow?.quadrant}</span> state.</p>
            
            <p className="text-secondry-color mt-2">{state.currentFlow.quadrantLevel1}</p>
            {
                top3.map((obj:any,idx:number) => <SecondryButton key={idx} style={{marginTop: "10px",backgroundColor: brainColor,color: "#1f1f1f"}}><span className='mr-1'>{obj.text}</span><InfoIconWithToolTip id="asfd" content={obj.tooltip}/></SecondryButton>)
            }
            
            {/* if user click below link they will see detail about specfic state they are in  */}
            <p onClick={()=> setIsShowStateInfoScreen(true)} className="text-secondry-color underline mt-3">Click for more information about current state</p>
            <p className="text-secondry-color mt-2">{state.feedbackMessage}</p>
            <p className="text-secondry-color mt-2 mb-1">Let's start unlocking that now! So you can progress towards {loggedInUser.personalImprovementGoals[0]}</p>
            
            {/* if user click below button they will see section with the id of states  */}
            <PrimaryButton onClick={()=> setIsShowStatesScreen(true)} style={{marginTop: "10px",marginBottom: "10px",padding: "12px 0"}}>What Flow State would you like right now?</PrimaryButton>
        </div>
    </>
  }

  export function getRandomObjects(arr:any, numValues:any){
    const result = [];
    const usedIndices = new Set();

    while (result.length < numValues) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        if (!usedIndices.has(randomIndex)) {
            result.push(arr[randomIndex]);
            usedIndices.add(randomIndex);
        }
    }

    return result;
  }

  