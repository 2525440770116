import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getActionChallengeOfTeam, getActionChallengeOfUser} from "../../Store/actions";
import { useNavigate } from 'react-router-dom';
import {Main} from "../../layout";
import {PrimaryButton, ActionChallengeModal} from "../../components";
import { RootState } from '../../Store/store';
import { ACTable } from './components/ACTable';




interface IActionChallenges {}

const ActionChallenges: React.FC<IActionChallenges> = () => {
    const loggedInUser = useSelector((state: RootState)=> state.loggedInUser);
    const previousRoute = useSelector((state: RootState)=> state.globalStates.previousRoute);
    const [actionChallenges, setActionChallenges] = useState<any | null>(null);
    const [showActionChallengePopup, setShowActionChallengePopup] = useState(false);
    const [selectedActionChalenge, setSelectedActionChalenge] = useState<any>(null);
    const navigator = useNavigate();
    const [showCompeted, setShowCompleted] = useState(false);
    
    const handleBack = () => {
        navigator(previousRoute);
    }

    const handleShowHideCompletedActionChallenges = () => {
        setShowCompleted(prev => !prev);
    }

    useEffect(() => {
        (async ()=> {
            if(!loggedInUser){
                return;
            }
            const res = await getActionChallengeOfUser({userId: loggedInUser._id});
            const res1 = await getActionChallengeOfTeam({userId: loggedInUser._id});
            if(res.success){
                const completedOnes = res.actionChallenges.filter((ac:any) => ac.isCompleted);
                const unCompletedOnes = res.actionChallenges.filter((ac:any) => !ac.isCompleted);
                setActionChallenges([...unCompletedOnes,...completedOnes]);
            }
            if(res1.success){
                const completedOnes = res1.actionChallenges.filter((ac:any) => ac.isCompleted);
                const unCompletedOnes = res1.actionChallenges.filter((ac:any) => !ac.isCompleted);
                setActionChallenges((p:any) => [...p,...unCompletedOnes,...completedOnes])
            }
        })()
    }, [loggedInUser,showActionChallengePopup]);

  return (
        <Main>    
            <div className="flex items-center justify-between md:flex py-6 border-b border-secondry-color/20">
                <div className="max-w-lg">
                    <h3 className="text-secondry-color text-xl mb-0 font-bold sm:text-2xl">
                        All Action-Challenges
                    </h3>
                </div>
                <div className="max-w-lg">
                    <PrimaryButton style={{padding: "12px 20px"}} onClick={handleBack}>
                        Back
                    </PrimaryButton>
                </div>
            </div>
            <div className='pt-4 w-[350px] max-w-full'>
                <PrimaryButton onClick={handleShowHideCompletedActionChallenges}><>{showCompeted? "Hide":"Show"} Completed Action Challenges</></PrimaryButton>
            </div>
            {
                actionChallenges &&  <ACTable showCompeted={showCompeted} setActionChallenges={setActionChallenges} setShowActionChallengePopup={setShowActionChallengePopup} setSelectedActionChalenge={setSelectedActionChalenge} actionChallenges={actionChallenges}/>
            }    
            <ActionChallengeModal currentActivityName={""} forTeam={false} userId={loggedInUser._id} factor={selectedActionChalenge?.factor} currentActionChallenge={selectedActionChalenge} show={showActionChallengePopup} setShow={setShowActionChallengePopup}/>
        </Main>
  )
}

export default ActionChallenges;







