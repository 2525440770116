import React, { useEffect, useRef, useState } from 'react';
import { Main} from "../../layout";
import { getUserActivities, updateActivity } from '../../Store/actions/activity';
import { Loading, SecondryButton, ShadowBox } from '../../components';
import { firstLetterUpperCase, formatGoal, formatPurpose } from '../../utils';
import { arrayToObject } from './utils/arrayToObject';
import { GoalModal, PurposeModal } from './components';
import { useNavigate } from 'react-router-dom';



const  Index: React.FC<any> = () => {
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [activities, setActivities] = useState<any>(null);
  const [isPurposeModalOpen, setIsPurposeModalOpen] = useState(false);
  const [isGoalModalOpen, setIsGoalModalOpen] = useState(false);
  const [shouldDisplayDayOrWeekGoalInCognipuse, setShouldDisplayDayOrWeekGoalInCognipuse] = useState(false);
  const [dayOrWeekGoal, setDayOrWeekGoal] = useState("");
  const throttleTimeout = useRef<NodeJS.Timeout | null>(null);
  const navigation = useNavigate();

  const handleDailyOrWeeklyGoalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDayOrWeekGoal(e.target.value);

    if(!throttleTimeout.current){
      throttleTimeout.current = setTimeout(async () => {
        await updateActivity(activities[currentActivityIndex]._id,{dayOrWeekGoal: e.target.value});
        throttleTimeout.current = null;
      }, 2000); // 1 second delay
    }           
  }

  const handleWillDisplaydayOrWekGoalInCognipuseChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShouldDisplayDayOrWeekGoalInCognipuse(e.target.checked);
    await updateActivity(activities[currentActivityIndex]._id,{shouldDisplayDayOrWeekGoalInCognipuse: e.target.checked});
  }

  useEffect(() => {
    if(activities){
      setDayOrWeekGoal(() => {
        return activities[currentActivityIndex].dayOrWeekGoal || ""
      });
      setShouldDisplayDayOrWeekGoalInCognipuse(() => {
        return activities[currentActivityIndex].shouldDisplayDayOrWeekGoalInCognipuse
      });
    }
  }, [activities,currentActivityIndex]);
  
  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      if(res?.success){
        setActivities(res.activities);
      }
    })()
  }, []);
  
  if(!activities){
    return <Main><div className='w-full h-[calc(100vh-64px-57px)] flex items-center justify-center'><Loading/></div></Main>
  }

  

  return (
    <Main>
      <div className='h-5'/>
      <div className='w-[400px] max-w-full mx-auto'>
        <ShadowBox> 
                  {
                    activities.length > 1 && <>
                          <select value={currentActivityIndex} onChange={(e)=>{setCurrentActivityIndex(Number(e.target.value))}} className='w-full p-1 rounded-md mb-6 bg-primary-color border border-secondry-color'>
                              {
                                activities.map((activity:any,idx:number) => (
                                  <option key={idx} value={idx}>{firstLetterUpperCase(activity.name)} {firstLetterUpperCase(activity.category)}</option>
                                ))
                              }
                          </select>
                    </> 
                  }

                    <h3 className='text-center font-bold text-secondry-color'>My purpose for my {activities[currentActivityIndex].name} {activities[currentActivityIndex].category}</h3>
                    <p onClick={() =>setIsPurposeModalOpen(true)} className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].purpose.length > 0 && <>
                          {formatPurpose(arrayToObject(activities[currentActivityIndex].purpose))}
                        </>
                      }
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].purpose.length === 0 && <>
                          Click here to craft your Purpose statement
                        </>
                      }
                    </p>

                    <h3 className='text-center font-bold text-secondry-color mt-10'>My current 90 day goal for  {activities[currentActivityIndex].name} {activities[currentActivityIndex].category}</h3>
                    <p onClick={()=>{setIsGoalModalOpen(true)}} className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].goal.length > 0 && <>
                          {formatGoal(activities[currentActivityIndex].goal)}
                        </>
                      }
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].goal.length === 0 && <>
                          Click here to craft your 90 Day Goal
                        </>
                      }
                    </p>

                    <h3 className='text-center font-bold text-secondry-color mt-10'>My Day Goal</h3>
                    <textarea onChange={handleDailyOrWeeklyGoalChange} value={dayOrWeekGoal} placeholder='Enter the bespoke daily or weekly goal that your XLRate coach has created for you.' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                    <div className="flex items-center">
                        <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                        <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                    </div>

                    <div className="flex justify-center mt-12 gap-2">
                      <SecondryButton style={{padding: "12px 0"}} onClick={()=>{navigation("/my-dashboard")}}>Done</SecondryButton>
                    </div>
        </ShadowBox>
      </div>
      <div className='h-5'/>
      {
        isPurposeModalOpen && <PurposeModal setIsPurposeModalOpen={setIsPurposeModalOpen}  activities={activities} currentActivityIndex={currentActivityIndex} setActivities={setActivities}/>
      }
      {
        isGoalModalOpen && <GoalModal setIsGoalModalOpen={setIsGoalModalOpen}  activities={activities} currentActivityIndex={currentActivityIndex} setActivities={setActivities}/>
      }
    </Main>
  )
}

export default Index;



