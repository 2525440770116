import React from 'react';


interface IFactorBar {
  value: any;
  isReverse: any;
  bg: any;
  showRange: any;
  min: any;
  max: any;
  percentageOfchange: any;
  showChange: any;
}

export const FactorBar: React.FC<IFactorBar> = ({value,isReverse,bg,showRange,min,max,percentageOfchange,showChange}) => {
    const rotate = `rotate(180deg)`;
    const rangeWidth = max-min;
    const tempVal = value > 50? 50-(value-50):value;
    const margin = 30/100*tempVal;
    const tipMargin = value < 20 ? margin/3:0;
 

    return (
        <div style={{transform: isReverse? "":rotate}} className="bg-gray-500 w-[30px] h-[140px] rounded-[30px] relative">
          {/* score  */}
          <div style={{height: `calc(${value}% + ${margin}px)`,transform: isReverse? "":rotate,backgroundColor: bg}} className="min-h-[30px] rounded-[30px] font-bold text-[10px] relative">
            <div style={{top: isReverse? "unset":`-${tipMargin}px`,bottom: isReverse? `-${tipMargin}px`:"unset"}} className="cursor-pointer h-[30px] w-[30px] rounded-[30px] bg-white flex justify-center items-center absolute left-0 text-accent-color">{isReverse? "-":""}{parseFloat(value).toFixed(1)}</div>
            {
              showChange && <div style={{top: isReverse? "unset":0,bottom: isReverse? 0:"unset",color: isReverse? percentageOfchange > 0? "red":"green":percentageOfchange > 0? "green":"red"}} className="font-bold absolute w-[100%] h-[30px] -left-[38px] rounded-full flex justify-center items-center">{parseFloat(percentageOfchange).toFixed(1)}%{isReverse? percentageOfchange > 0? "↓":"↑":percentageOfchange > 0? "↑":"↓"}</div>
            }
          </div>
        
          {/* range  */}
          {
            showRange && <div style={{height: `${rangeWidth}%`,top: `${min}%`,backgroundColor: bg,left: isReverse? "31px":'-6px'}} className="w-[5px] bg-green-500 absolute flex flex-col justify-between items-center rounded-[15px]">
              <div style={{transform: isReverse? "":rotate,marginRight: isReverse? "-30px":"28px"}} className="text-[8px] font-bold">{isReverse? "-":""}{parseFloat(min).toFixed(2)}</div>
              <div style={{transform: isReverse? "":rotate,marginRight: isReverse? "-30px":"28px"}} className="text-[8px] font-bold">{isReverse? "-":""}{parseFloat(max).toFixed(2)}</div>
            </div>
          }

          {/* glassy shadow  */}
          <div style={{left: isReverse? "5px":"20px"}} className="absolute h-[20%] w-[5px]  top-[15%] bg-white/20 rounded-full"></div>
          <div style={{left: isReverse? "5px":"20px"}} className="absolute h-[50%] w-[5px]  top-[40%] bg-white/20 rounded-full"></div>
        </div>
    );  
}





