import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {firstLetterUpperCase} from "../utils";
import { Lightning, Rocket, SelfImprovement, StairsGoal } from '../components/Icons';
import { AppDispatch, RootState } from '../Store/store';
import { updateLoggedInUser } from '../Store/actions';



export const Profile: React.FC<any> = ()  => {
  const dispatch:AppDispatch = useDispatch();
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const numberOfActionChallenges = useSelector((state: RootState) => state.numberOfActionChallenges);
  const PIPoints = useSelector((state: RootState) => state.globalStates.PIPoints);
  const currentPILevel = parseFloat(`${(PIPoints || 0)/500+1}`).toFixed(2);
  const IStreak = useSelector((state: RootState) => state.globalStates.IStreak);
  const statistics = [
    {
      label: "Action Challenges",
      value: numberOfActionChallenges,
      icon: <StairsGoal className='w-8 h-8 text-green-600'/>
    },
    {
      label: "Personal Improvement Points",
      value: PIPoints,
      icon: <SelfImprovement className='w-8 h-8'/>
    },
    {
      label: "Personal Improvement Level",
      value: currentPILevel,
      icon: <Rocket className='w-8 h-8'/>
    },
    {
      label: "Improvement Streak",
      value: IStreak,
      icon: <Lightning className='w-8 h-8'/>
    },
  ]


  const handleChangeDataShare = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.checked){
      dispatch(updateLoggedInUser({isSharingData: true}));
    }
    else {
      dispatch(updateLoggedInUser({isSharingData: false}));
    }
  }

  return (
    <>
        {/* <div className='h-10'/> */}
        <div className="mx-auto max-w-7xl min-h-[calc(100vh-64px)] shadow-xl text-gray-900">
            <div className="h-32 overflow-hidden">
                <img className="object-cover object-top w-full" src='https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ' alt='Mountain'/>
            </div>
            <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
                <div className='w-full h-full bg-primary-button-color text-white rounded-full flex justify-center items-center text-6xl font-bold'>{loggedInUser.forename[0].toUpperCase()}</div>
            </div>
            <div className='w-[590px] max-w-full px-2 py-4 mx-auto'>
                <div className="text-center mt-2 border-b-2 border-secondry-color/20 pb-4">
                    <h2 className="font-semibold text-secondry-color">{firstLetterUpperCase(loggedInUser.forename)} {firstLetterUpperCase(loggedInUser.surname)}</h2>
                </div>

                <div className='my-4'>
                  <h1 className='text-2xl text-secondry-color font-semibold'>Statistics</h1>
                  <div className='mt-2 grid grid-cols-1 md:grid-cols-2 gap-2'>
                    {
                      statistics.map((item,idx) => (
                        <div key={idx} className='text-secondry-color border-2 border-secondry-color/20 p-2 rounded-md flex items-center gap-4'>
                          {item.icon}
                          <div className='flex flex-col'>
                            <span className='font-bold'>{item.value}</span>
                            <span>{item.label}</span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>

                {/* <div className='mt-4'>
                  <h1 className='text-2xl text-secondry-color font-semibold'>Achievements</h1>
                  <div className='mt-2 grid grid-cols-1 md:grid-cols-2 gap-2'>
                    {
                      [0,0,0,0,0,0].map((item,idx) => (
                        <div key={idx} className='border-2 border-secondry-color/20 rounded-md h-[50px]'></div>
                      ))
                    }
                  </div>
                </div> */}

                <div className='mt-4'>
                  <h1 className='text-2xl text-secondry-color font-semibold'>Data</h1>
                  <div className='mt-2'>
                    <label className="inline-flex items-center cursor-pointer">
                      <input checked={loggedInUser?.isSharingData} onChange={handleChangeDataShare} type="checkbox" value="" className="sr-only peer"/>
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondry-button-color/30 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondry-button-color"/>
                      <span className="ms-3 text-sm font-medium text-secondry-color">Share your data with your Performance Coach</span>
                    </label>  
                  </div>
                </div>
            </div>
        </div>
    </>
  )
}
