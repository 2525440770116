import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getGroupById, updateBatchUsers } from "../../../Store/actions";
import dayjs from "dayjs";
import { PopupContainer } from "../../../layout";
import { Loading, ModalHeader, PrimaryButton } from "../../../components";

interface IUpdateBatchUsers {
    setVisilivity: any;
    groupId: any;
    surveyTitles: any;
  }
  
  export const UpdateBatchUsers: React.FC<IUpdateBatchUsers> = ({setVisilivity,groupId,surveyTitles}) => {
      const [userIds, setUserIds] = useState(null);
      const [error, setError] = useState<any>(null);
      const [data, setData] = useState<any>({postSurveyId: "",preSurveyId: "",postSurveyDate: "",onbordingEmailTime: ""});
      const [groupName, setGroupName] = useState(null);
      
  
      const handleOnChange = (e:any) => {
        setData((prev:any) => {
          return {
            ...prev,
            [e.target.name]: e.target.value
          }
        })
      }
  
      const handleSubmit = async () => {
        let isAllExists = true
  
        Object.keys(data).forEach(key => {
          if(!data[key]){
            isAllExists = false;
          }
        })
  
        if(!isAllExists){
          toast.error("All fields are required");
          return;
        }
  
        const d = {
          data: {
            ...data,
            onbordingEmailTime: new Date(data.onbordingEmailTime),
            postSurveyDate: new Date(data.postSurveyDate)
          },
          userIds
        }
  
        const res = await updateBatchUsers(d);
  
        if(res.success){
          toast.success("Updated Successfully");
          setVisilivity(false);
        }
        else{
          toast.error(res.message);
        }
      }
  
      useEffect(() => {
        (async ()=> {
          const res = await getGroupById(groupId);
          if(res.success){
            const user = res.group.users.find((u:any) => u !== null);
            
            if(!user){
              setError("No User Found");
              return;
            }
            if(user.requiredActivities?.length === 0){
              setError("Users in this group are not batch users");
              return;
            }
            setGroupName(res.group.groupname);
            setUserIds(res.group.users.map((u:any) => u?._id)); 
            setData({
              postSurveyId: user.postSurveyId,
              preSurveyId: user.preSurveyId,
              postSurveyDate: dayjs(user.postSurveyDate).format("YYYY-MM-DDTHH:mm:SS"),
              onbordingEmailTime: dayjs(user.onbordingEmailTime).format("YYYY-MM-DDTHH:mm:SS")
            })
          }
          else {
            toast.error("Something went wrong!");
          }
        })()
  
        return () => {
          setError(null);
        }
      }, [groupId]);
  
  
      if(error || !surveyTitles){
        return <PopupContainer>
                  <ModalHeader leftText={""} handleCrosClick={()=>{setVisilivity(false)}}/>
                  <p className="mt-4 text-red-800 bg-red-100">{error}</p>
                </PopupContainer>
      }
  
      if(!userIds){
        return <PopupContainer><Loading/></PopupContainer>
      }
  
  
  
      return (
        <>
          <PopupContainer>
             <ModalHeader leftText={groupName} handleCrosClick={()=>{setVisilivity(false)}}/>
             <div className="pt-4  max-w-full">
                <div className="w-full">
                    <label htmlFor="postSurveyId">Post Survey</label>
                    <select value={data.postSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-1" name="postSurveyId" id="postSurveyId">
                        {
                          surveyTitles.map((st:any) => <option key={st._id} value={st._id}>{st.title}</option>)
                        }
                    </select>
                </div>
                <div className="w-full mt-4">
                    <label htmlFor="preSurveyId">Pre Survey</label>
                    <select value={data.preSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-2" name="preSurveyId" id="preSurveyId">
                        {
                          surveyTitles.map((st:any) => <option key={st._id} value={st._id}>{st.title}</option>)
                        }
                    </select>
                </div>
                <div className="w-full mt-4">
                    <label htmlFor="postSurveyDate">Post Survey Date</label>
                    <input value={data.postSurveyDate} onChange={handleOnChange} type="datetime-local" id="postSurveyDate" name="postSurveyDate" className="block w-full p-2 rounded-md mt-2"/>
                </div>
                <div className="w-full mt-4">
                    <label htmlFor="onbordingEmailTime">Onboarding Email Time</label>
                    <input value={data.onbordingEmailTime} onChange={handleOnChange} type="datetime-local" id="onbordingEmailTime" name="onbordingEmailTime" className="block w-full p-2 rounded-md mt-2"/>
                </div>
                <PrimaryButton style={{marginTop: "20px"}} onClick={handleSubmit}>Done</PrimaryButton>
             </div>
          </PopupContainer>
        </>
      )
  }