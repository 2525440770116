import { PrimaryButton, SecondryButton } from "../../../components";
import { PopupContainer } from "../../../layout";

interface IGroupModal {
    addName: any;
    isOpen: any;
    onSubmit: any;
    setIsOpen: any;
    groupName: any;
    setGroupName: any;
    setIsUniversal: any;
    isUniversal: any;
  }
  
  export const GroupModal: React.FC<IGroupModal> = ({addName,isOpen,onSubmit,setIsOpen,groupName,setGroupName,setIsUniversal,isUniversal}) => {
  
  
    if(!isOpen){
      return null;
    }
  
    return (
          <PopupContainer>
            <div className="mb-14">
               <input 
                  type="text"
                  value={groupName} 
                  onChange={(e)=>{setGroupName(e.target.value)}}
                  className="w-full border rounded-md p-2"
                  placeholder="group name.."
                />
                <label htmlFor="universal" className="mt-2 flex items-center">
                  Is Universal: 
                  <input type="checkbox" id="universal" className="ml-2" onChange={e=> setIsUniversal((prev:any) => !prev)} checked={isUniversal}/>
                </label>
            </div>
  
            <div className="flex gap-2">
              <PrimaryButton onClick={()=> setIsOpen(false)} style={{padding: "12px 0"}}>Cancel</PrimaryButton>
              <SecondryButton onClick={onSubmit} style={{padding: "12px 0"}}>{addName}</SecondryButton>
            </div>
          </PopupContainer>
    )
  
  }