import {ArrowBack} from "./Icons";

interface IBackButton {
  onClick: any;
}

export const BackButton: React.FC<IBackButton> = ({onClick=()=>{}}) => {
  
  return <ArrowBack onClick={onClick}/>
}

