import { useState } from "react";
import { ACTIVITIES } from "../../../constants";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { createUsers } from "../../../Store/actions";
import { MultipleSelect } from "./MultipleSelect";

  interface IUsers {
    data: any;
    groups: any;
    setData: any;
    prePostSurvey: any;
    isDropdownShow: any;
    setIsDropdownShow: any;
  }
  
  export const Users: React.FC<IUsers> = ({data,groups,setData,prePostSurvey,isDropdownShow,setIsDropdownShow}) => {
    const [activities, setActivities] = useState(()=>ACTIVITIES.map(ac => {return{checked: false,category: ac,nameChecked: false,name: ""}}));
  
    if(!data.values[0]){
      return <div className="">Invalid Data</div>
    }
  
    const handleGroupChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.group;
              delete val.groupId;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "group")
          }
        })
        return;
      }
  
      const group = JSON.parse(e.target.value);
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,group: group.groupname,groupId: group._id}}),
          keys: [...new Set([...prev.keys,"group"])]
        }
      })
    }
  
    const handleActivityCountChange = (e:any) => {
      const number = Number(e.target.value);
      
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.activitiesCount;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "activitiesCount")
          }
        })
        return;
      }
  
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,activitiesCount: number}}),
          keys: [...new Set([...prev.keys,"activitiesCount"])]
        }
      })
    }
  
    const handlePreSurveyChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.preSurveyId;
              delete val.preSurvey;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "preSurvey")
          }
        })
        return;
      }
  
      const survey = JSON.parse(e.target.value);
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,preSurvey: survey.title,preSurveyId: survey._id}}),
          keys: [...new Set([...prev.keys,"preSurvey"])]
        }
      })
    }
  
    const handleUnRegularSurveyChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.unRegularSurveys;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "unRegularSurveys")
          }
        })
        return;
      }
  
      const survey = JSON.parse(e.target.value);
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,unRegularSurveys: survey._id}}),
          keys: [...new Set([...prev.keys,"unRegularSurveys"])]
        }
      })
    }
  
    const handlePostSurveyChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.postSurveyId;
              delete val.postSurvey;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "postSurvey")
          }
        })
        return;
      }
  
      const survey = JSON.parse(e.target.value);
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,postSurvey: survey.title,postSurveyId: survey._id}}),
          keys: [...new Set([...prev.keys,"postSurvey"])]
        }
      })
    }
  
    const handleOnboardingEmailTimingChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.onbordingEmailTime;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "onbordingEmailTime")
          }
        })
        return;
      }
  
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,onbordingEmailTime: e.target.value}}),
          keys: [...new Set([...prev.keys,"onbordingEmailTime"])]
        }
      })
    }
  
    const handlePostSurveyTimingChange = (e:any) => {
      if(!e.target.value){
        setData((prev:any) => {
          return {
            ...prev,
            values: prev.values.map((val:any) => {
              delete val.postSurveyDate;
              return val;
            }),
            keys: prev.keys.filter((key:any) => key !== "postSurveyDate")
          }
        })
        return;
      }
  
      setData((prev:any) => {
        return {
          ...prev,
          values: prev.values.map((val:any) => {return {...val,postSurveyDate: e.target.value}}),
          keys: [...new Set([...prev.keys,"postSurveyDate"])]
        }
      })
    }
  
    const handleSubmit = async () => {
      const requiredActivities = activities.filter(act => act.checked).map(act => act.nameChecked? {category: act.category,name: act.name}:{category: act.category});
      const users = data.values.map((user:any) => {return {...user,requiredActivities}});
      const dif = dayjs(users[0].onbordingEmailTime).diff(dayjs(),"minutes");
      
      
      if(dif < 1){
        toast.error("onbording Email Time should be in future!");
        return;
      }
  
      // return
      const res = await createUsers({users});
      if(res && res.success){
        toast.success("Users created successfully!");
      }
      else {
        toast.error(res.message)
      }
    }
  
    const isDisabled = !data.values[0]?.group || !data.values[0]?.activitiesCount || !data.values[0]?.requiredActivities || !data.values[0]?.onbordingEmailTime || !data.values[0]?.preSurvey || !data.values[0]?.postSurvey || !data.values[0]?.postSurveyDate;
  
  
    return (
      <div className="h-[600px] w-full bg-white p-5 mt-5 overflow-auto">
        <div className="pb-3 border-b">
          <table className='w-full table-auto border-collapse border border-slate-400 '>
          <thead>
              <tr>
                {
                  data.keys.map((key:any) => <th className='px-2 py-1 border border-slate-300' key={key}>{key}</th>)
                }
              </tr>
          </thead>
          <tbody>
            {
              data.values.map((item:any,index:number) => (
                <tr key={index}>
                  {
                    data.keys.map((key:any,i:number) => <td className='px-2 py-1 border border-slate-300' key={i}>{key === "emailBody"? <span>{`${item[key]}`.substring(0,5)}...</span>:item[key]}</td>)
                  }
                </tr>
              ))
            }
          </tbody>
          </table>
        </div>
        <div className="mt-3">
          <div className="w-full flex gap-3">
              <div className="">
                <label htmlFor="group">Group</label>
                <select onChange={handleGroupChange} name="group" id="group" className='mt-1 w-full border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                  <option value="">Select Group</option>
                  {
                    groups.map((group:any) => <option key={group._id} value={JSON.stringify(group)}>{group.groupname}</option>)
                  }
                </select>
              </div>
              <div className="">
                <label htmlFor="activitiesCount">Activities Count</label>
                <select onChange={handleActivityCountChange} name="activitiesCount" id="activitiesCount" className='mt-1 w-full border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                  <option value="">Select Activities Count</option>
                  {
                    new Array(5).fill(1).map((one,i) => <option key={i} value={i+1}>{i+1}</option>)
                  }
                </select>
              </div>
              <div className="">
                <label htmlFor="unRegularSurveys">Impact Survey</label>
                <select onChange={handleUnRegularSurveyChange} name="unRegularSurveys" id="unRegularSurveys" className='w-full mt-1 border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                  <option value="">Select Imapct Survey</option>
                  {
                    prePostSurvey && prePostSurvey.map((survey:any) => <option key={survey._id} value={JSON.stringify(survey)}>{survey.title}</option>)
                  }
                </select>
              </div>
              <div className="">
                <label htmlFor="preSurvey">Pre Survey</label>
                <select onChange={handlePreSurveyChange} name="preSurvey" id="preSurvey" className='w-full mt-1 border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                  <option value="">Select Pre Survey</option>
                  {
                    prePostSurvey && prePostSurvey.map((survey:any) => <option key={survey._id} value={JSON.stringify(survey)}>{survey.title}</option>)
                  }
                </select>
              </div>
              <div className="">
                <label htmlFor="preSurvey">Post Survey</label>
                <select onChange={handlePostSurveyChange} name="postSurvey" id="postSurvey" className='w-full mt-1 border p-2 focus:ring-4 focus:outline-none focus:ring-blue-300'>
                  <option value="">Select Post Survey</option>
                  {
                    prePostSurvey && prePostSurvey.map((survey:any) => <option key={survey._id} value={JSON.stringify(survey)}>{survey.title}</option>)
                  }
                </select>
              </div>
              <div className="">
                <label htmlFor="postSurveyDate">Post Survey Date</label>
                <input onChange={handlePostSurveyTimingChange} type="datetime-local" name="postSurveyDate" id="postSurveyDate" className='w-full mt-1 border p-2'/>
              </div>
              <div className="">
                <label htmlFor="onbordingEmailTime">Onboarding Email Time</label>
                <input onChange={handleOnboardingEmailTimingChange} type="datetime-local" name="onbordingEmailTime" id="onbordingEmailTime" className='w-full mt-1 border p-2'/>
              </div>
          </div>
          <div className="mt-3">
            <MultipleSelect setData={setData} activities={activities} setActivities={setActivities} isDropdownShow={isDropdownShow} setIsDropdownShow={setIsDropdownShow}/>
          </div>
         
          <button onClick={handleSubmit} disabled={isDisabled} className={`rounded-md p-2 ${isDisabled? "bg-blue-600/50":"bg-blue-600"}  text-white font-bold mt-2`}>Add Users</button>
        </div>
      </div>
    )
  }