import {setToken,fetchRequest} from "../../utils";
import { 
    LOGIN_USER, 
    LOGOUT_USER, 
    SET_IMPROVEMENT_STREAK, 
    SET_PERSOANL_IMPROVEMENT_POINTS, 
    UPDATE_ACTIVITIES, 
    UPDATE_LOGIN_USER,
    DOMAIN_NAME
} from "../../constants";
import {toast} from "react-toastify";
import { AppDispatch } from "../store";



export const loginUser = async (user:any) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/auth/login`,"POST",user)
        console.log(res);
        return res;

    } catch (error) {
        console.log("Error While Logging In!",error);
        return {};
    }
}

export const afterLoginSuccess = (res:any) => async (dispatch: AppDispatch) => {
    toast.success("Login Success!");
    setToken(res.token);
    dispatch({type: LOGIN_USER, payload: res.user});
    dispatch({type: SET_PERSOANL_IMPROVEMENT_POINTS ,payload: res.user.personalgrowthpoints});
    dispatch({type: SET_IMPROVEMENT_STREAK , payload: res.streak});
}


export const updateActivities = (activities: any) => async (dispatch: AppDispatch) =>{

    try {
        dispatch({type: UPDATE_ACTIVITIES,payload: activities});
    } catch (error) {
        
    }
}


export const updateLoggedInUser = (data:any) => async (dispatch: AppDispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/me`,"PUT",data);

        // console.log(res);

        if(res.success){
            dispatch({type: UPDATE_LOGIN_USER,payload: data});
        }

    }  catch (error) {
        console.error(error);
    }
}


export const forgetPassword = async (email: string) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/auth/forget-password`,"POST", {email})
        
        return res;

    } catch (error) {
        
    }

    // console.log(res);
}


export const resetPassword = async (data: any) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/auth/reset-password`,"POST", data)
        
        return res;

    } catch (error) {
        
    }

    // console.log(res);
}


export const logoutUser = () => async (dispatch: AppDispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/auth/logout`,"POST",{})
        if(res.success){
            setToken("");
            toast.success("Logout Success!");
            dispatch({type: LOGOUT_USER ,payload:null});
            // dispatch({type: RESET_CART ,payload:""});
            // dispatch({type: RESET_USERS ,payload:""});
            // dispatch({type: RESET_PRODUCTS ,payload:""});
        }
        else{
            toast.error(res.message);
        }
    } catch (error) {
        
    }

}