import {randomInRange} from "./index";
import { ADD_EVENT, CHOOSE_GOAL_AND_ACHIVEMENTS, CHOOSE_THINGS_TO_IMPROVE, COMPLETE_SURVEY, LOGIN_AFTER_FIRST_TIME, LOGIN_FIRST_TIME } from "../constants";


const rewardValues = {
    A: {min: 1,max: 15,multiplier: 1},
    B: {min: 16,max: 40,multiplier: 1.5},
    C: {min: 41,max: 70,multiplier: 1.2},
    D: {min: 71,max: 85,multiplier: 1.4},
    E: {min: 86,max: 97,multiplier: 1.8},
    F: {min: 98,max: 99,multiplier: 2.5},
    G: {min: 99,max: 100,multiplier: 5},
}


function getMultiplier(val:any){
    for (const key in rewardValues) {
      if(val >= rewardValues[key as keyof typeof rewardValues].min && val <= rewardValues[key as keyof typeof rewardValues].max){
        return {multiplier: rewardValues[key as keyof typeof rewardValues].multiplier,grade: key}
      }
    }
}

  
export const actionsAndTheirRewards = [
    {
        action: LOGIN_FIRST_TIME,
        rewardPoints: 50
    },
    {
        action: LOGIN_AFTER_FIRST_TIME,
        rewardPoints: 5
    },
    {
        action: CHOOSE_THINGS_TO_IMPROVE,
        rewardPoints: 10	//3 times only
    },
    {
        action: CHOOSE_GOAL_AND_ACHIVEMENTS,
        rewardPoints: 15	//3 times only
    },
    {
        action: COMPLETE_SURVEY,
        rewardPoints: 50
    },
    {
        action: ADD_EVENT,
        rewardPoints: 70
    },
];


function calculatePersonalImprovementPoints(myAction:any){
    const coreGrowthPointValue = actionsAndTheirRewards.find(item => item.action === myAction);  
    
    if(!coreGrowthPointValue){
      alert("Invalid Action!");
      return;
    }
    
    const {multiplier}:any = getMultiplier(randomInRange(1,100));
    const PIPoints = coreGrowthPointValue.rewardPoints*multiplier;

    return PIPoints;
}


export {calculatePersonalImprovementPoints};


