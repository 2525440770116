import { Add } from "../../../components/Icons";
import { ViewDashboardUI } from "./ViewDashboardUI";

  interface IHeader {
    setShowUserNames: any;
    showUserNames: any;
    selectedUsers: any;
    handdleViewDashboard: any;
  }
  
  export const Header: React.FC<IHeader> = ({setShowUserNames,showUserNames,selectedUsers,handdleViewDashboard}) => {
  
    return <>
      <div className="flex items-center justify-between gap-4 border-b border-secondry-color/10 py-5">
          <div className="flex items-center gap-4 pl-2">
                <Add 
                onClick={()=>{}} 
                title="Add Root Group" className="w-6 cursor-pointer" />
  
                <label className="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" value="" className="sr-only peer" onChange={()=>setShowUserNames((p:any) => !p)} checked={showUserNames}/>
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{showUserNames? "Hide User Names":"Show User Names"}</span>
                </label>
          </div>
          <div className="">
                <ViewDashboardUI selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
          </div>
      </div>
    </>
  }
  