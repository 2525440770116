import { useDispatch } from "react-redux";
import { SecondryButton, Table, Tbody, Td, Th, Thead, Tr } from "../../../components";
import dayjs from "dayjs";
import { DeleteButton } from "./DeleteButton";
import { createActionChallenge, deleteActionChallenge, setNumberOfActionChallenges } from "../../../Store/actions";
import { toast } from "react-toastify";
import { AppDispatch } from "../../../Store/store";



interface IACTable {
    showCompeted: any;
    setActionChallenges: any;
    actionChallenges: any;
    setSelectedActionChalenge: any;
    setShowActionChallengePopup: any;
}


export const ACTable: React.FC<IACTable> = ({showCompeted,setActionChallenges,actionChallenges,setSelectedActionChalenge,setShowActionChallengePopup}) => {
    const dispatch: AppDispatch = useDispatch();

    const handleView = (actionChallenge:any) => {
        setShowActionChallengePopup(true);
        setSelectedActionChalenge(actionChallenge);
    }

    const handleDeleteActionChallenhe = async (item:any) => {
        const isSure = window.confirm("Are You Sure!");
        if(!isSure){
            return;
        }

        const actionChallengeId = item._id;
        const res = await deleteActionChallenge({actionChallengeId});
        // console.log(res);
        if(res.success){
            toast.success("Deleted Successfutty!");
            setActionChallenges((prev:any) => {
                const rem = prev.filter((ac:any) => ac._id !== actionChallengeId);
                dispatch(setNumberOfActionChallenges(rem.length));
                return rem;
            });
        }
        else {
            toast.error(res.message);
        }
    }

    const handleReDo = async (ac:any) => {
        const actionChallenge = {
            factor: ac.factor,
            impact: "immediate" ,
            subFactor: ac.subFactor,
            actionChallenge: ac.actionChallenge,
            steps: ac.steps.map((step:any) => {return {step: step.step}}),
            for: ac.for,
            user: ac.user 
        };

        const res = await createActionChallenge(actionChallenge);
        
        if(res.success){
            toast.success("You can re-do this task");
            setActionChallenges((prev:any) => [res.actionChallenge,...prev]);
        }
        else {
            toast.error("Something went wrong!");
        }
    }

    const tableHeaders = ["Name","For","Accepted Date","Status","Action"];


    return (
            <div className="py-12 relative h-[calc(100vh-201px)] overflow-auto">
                <Table>
                    <Thead>
                        <Tr>
                            {
                              tableHeaders.map(h => <Th key={h}><>{h}</></Th>)  
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            actionChallenges.filter((ac:any) => showCompeted?  true:!ac.isCompleted).map((item:any, idx: number) => (
                                <Tr key={idx}>
                                    <Td>{item.actionChallenge}</Td>
                                    <Td>{item.for}</Td>
                                    <Td><>{dayjs(item.createdAt).format("DD/MM/YYYY")}</></Td>
                                    <Td>
                                        <span className={`px-3 py-2 rounded-full font-semibold text-xs ${item.isCompleted? "text-secondry-button-color bg-secondry-button-color/10" : "text-primary-button-color bg-primary-button-color/10"}`}>
                                            {item.isCompleted? "Completed":"In Progress"}
                                        </span>
                                    </Td>
                                    <Td>
                                        <div className='flex gap-3 items-center'>
                                            <div>
                                                <DeleteButton onClick={()=>handleDeleteActionChallenhe(item)}>
                                                    Delete
                                                </DeleteButton>
                                            </div>
                                            <div>
                                                <SecondryButton onClick={()=>handleView(item)}>
                                                    View
                                                </SecondryButton>
                                            </div>
                                            <button onClick={()=>handleReDo(item)} title='Re-Do' className='cursor-pointer flex jsutify-center w-[32px] h-[32px] justify-center items-center border border-secondry-color/20 rounded-full hover:bg-secondry-color/10 active:border-[2px] active:border-secondry-color/40'>
                                                <img className='w-[20px] h-[20px]' alt='' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVR4nO2YzS4EQRSFz2ZsWBFewc8aK8Qa7yKxMJ6ECE9AWMrM3NsIlh0Zc+/4Cy8xLEVLd0hkZKZ7+m+qor6ktpVzbtWpulWAw+Fw/D+89hqshvUDrFVYC2sQDdJDHOkIrDXAkYkb1JtTsNYAR+MVrHOw2EAAkg5INmCtAY6GJeHubSD4zsUBfL9SvJCGzIJ0BywM0kewvseKSzzkGme3k8UIZ10E6Xl+YrXXSrxERcqN8Mxm2QfJZ+Hi+Xe4W+vZxdP9BEiuShPO3eGWzfTiw0CxXgxJfJDdQLhthiWesm6hKLAl7nnOO8Ssl8OpvuRwjIbuky3zMbi9jHpzNGFRSrrIKLykYis1eLhKayVYOLbyqeYtq5ljeY5Z6iWz22mSt74Gku75bkp70JB0+hq40LFU85b2pGR56mvAa6+km7esvp9jQsx6Yva3Cms1wZm9BWOptWaSXWR6CpLV1KEulCxdqBGQLKRu5oyBdM9uA75fSfUGNoqajg/cWhuHHz0tdxNnwlgarXmQePYa+MG7mwbLNkgbYH340/w5HA6Hw4Fi+QJQaihUhFtWdAAAAABJRU5ErkJggg=="/>
                                            </button>
                                        </div>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </div>
    )
}