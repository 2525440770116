import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { SECONDRY_BUTTON_COLOR } from "../../../constants";

interface IProcessing {
  isCircular?: boolean
}

export const Processing: React.FC<IProcessing> = ({isCircular}) => {

  if(isCircular){
      return <div className="flex flex-col items-center gap-2 text-secondry-button-color">
        <FadeLoader color={SECONDRY_BUTTON_COLOR}/>
        Processing...
      </div>
  }

  return <div className="flex items-center gap-2 text-secondry-button-color">
    <ClipLoader color={SECONDRY_BUTTON_COLOR} size={20}/>
    Processing...
  </div>
} 