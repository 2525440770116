import {visitToTreeData} from "../../../utils";
import { v4 as uuid } from 'uuid';

export function getAllUsers(root:any){
    let userids = new Set();
    let users = new Set();
    let userSurveys = new Set();

    visitToTreeData(
        root,
        async function (d:any) {

          if(d.data.email){
            userids.add(d.data._id);
            users.add(d.data);
            d.data.surveyPreference?.surveys.forEach((s:any) => {
              userSurveys.add(s);
            })
          }  
  
        },
        function (d:any) {
          return d.children;
        }
    );

      return {userids,users,userSurveys};
  }


export function getSurveyTitleUsers(node:any){

    const surveyTitles = new Set();

    visitToTreeData(
      node,
        async function (d:any) {
          if(d.data.surveys){
            d.data.surveys.forEach((s:any) => {
              surveyTitles.add(s);
            })
          }
        },
        function (d:any) {
          return d.children;
        }
    );

      return surveyTitles;
  }


export function MakeTreeFromArray(allNodes:any,loggedInUser:any) {

    // console.log(allNodes);
    const managementGroups = loggedInUser.managementGroups || [];
    let rootNodes = allNodes.filter((node:any) => !node.parent);

     // console.log(d);
    if(loggedInUser.role === "manager"){
      rootNodes = allNodes.filter((node:any) => managementGroups.indexOf(node._id) !== -1);
    }

    const notRoots = new Set();

    rootNodes.forEach((rootNode:any) => {
      visitToTreeData(
        rootNode,
        async function (d:any) {
          
          addIdAndName(d);

          if (d.children && d.children.length > 0) {
              let children = allNodes.filter((node:any) => node.parent === d._id);
              d.children = children;
          }
          
  
          let users:any[] = [];
          if(d.users && d.users.length > 0){
              d.users.forEach((u:any) => {
                  if(u._id){
                    users.push({...u,parent:d._id});
                  }
              })
          }
  
          if(d.children){
                d.children = [...d.children,...users];
          }
          else{
              d.children = [...users];
          }
  
          if(d.children && d.children.length === 0){
            d.children = [];
          }

          if(d.children){
            d.children.forEach((child:any) => {
              notRoots.add(child._id);
            })
          }
           
        },
        function (d:any) {
          return d.children;
        }
      );
    
    })

    return rootNodes.filter((node:any) => [...notRoots].indexOf(node._id) === -1);
}


export function getUsersFromGroupWithoutTiming(groupId:any,root:any,scheduledTimes:any){

    const users = new Set();

    visitToTreeData(root);
    function visitToTreeData(node:any){

      const isGroup = scheduledTimes.find((t:any) => t.group === node._id);

      if((groupId === node._id || !isGroup) && !node.email){
        node?.users.forEach((user:any) => {
          users.add(user._id)
        })
      }

      if(node.children){
        node.children.forEach((child:any) => visitToTreeData(child))
      }
    }

    return [...users]; 
  }


export function addIdAndName(node:any) {
  const id = uuid();
  node.id = `${node._id}---${id}`;
  node.name = node.email? `${node.surname} ${node.forename}`:`${node.groupname}`;
}  