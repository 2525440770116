import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../components';

interface IUsers {
  allUserNames: any;
}

export const Users: React.FC<IUsers> = ({allUserNames}) => {
    const [showUsers,setShowUsers] = useState(false);

    useEffect(() => {
      document.addEventListener("click",(e:any)=> {
        if(e.target.id !== "user-pupup" && e.target.id !== "show-hide-users"){
          setShowUsers(false);
        }
      })

      return () => {
        document.removeEventListener("click",()=>{});
      };
    }, []);

  return (
    <>
        <div className='w-[100px] relative'>
              <PrimaryButton id='show-hide-users' onClick={()=> setShowUsers(p=>!p)} style={{padding: "0px 5px",fontSize: "12px"}}><>{showUsers? "Hide":"Show"} Users</></PrimaryButton>
              {
                showUsers && <>
                      <div id='user-pupup' className="absolute top-[110%] z-[1000] rounded-md w-[122px] h-[215px] overflow-auto mb-10 flex flex-col bg-secondry-button-color p-2">
                        {
                          allUserNames?.map((u:any,i:number) => (
                            <span key={i} className='text-white text-[12px]'>{i+1}. {u}</span>
                          )) 
                        }
                      </div>
                </>
              }
        </div>                     
    </>
  )
}
