import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import { setFirebaseToken } from './Store/actions';

const firebaseConfig = {
  apiKey: "AIzaSyA0yc4in0S9w5xDuywKAQh35YMiGj_791o",
  authDomain: "xsystems-791af.firebaseapp.com",
  projectId: "xsystems-791af",
  storageBucket: "xsystems-791af.appspot.com",
  messagingSenderId: "423312392997",
  appId: "1:423312392997:web:83874b70c5134830e050cc",
  measurementId: "G-785JN4S429"
};
  

const firebase = initializeApp(firebaseConfig);

const messaging = getMessaging(firebase);


onMessage(messaging, (payload) => {
  // console.log('Message received. ', payload);
  // console.log("foreground notification");

  if (document.visibilityState === 'visible') {
    const notification = new Notification(payload.data.title,{
      body: payload.data.body,
      icon: payload.data.icon
    });
  
    notification.addEventListener("click",() => {
      window.open(window.location.origin + payload.data.url);
    })
  }
});




export const generateFireBaseToken = async () =>  {
      // const prevPermision = Notification.permission;

      // console.log(prevPermision);
      // if(prevPermision !== "default"){
      //   const token = await getToken(messaging,{vapidKey: 'BLQrTuIw1dSCRTWJHlPWWFstpQM8IN9VReLJhZJs20uYmHO2x4Ia5sks52Fkqzc7ZfjfUVebapHYUKvK4_Kz9tg'});
      //   console.log(token);
      //   return;
      // }

      const permision = await Notification.requestPermission();


      if (permision === "granted") {
        const token = await getToken(messaging,{vapidKey: 'BLQrTuIw1dSCRTWJHlPWWFstpQM8IN9VReLJhZJs20uYmHO2x4Ia5sks52Fkqzc7ZfjfUVebapHYUKvK4_Kz9tg'});
        
        // console.log(token);
        const res = await setFirebaseToken(token);
        console.log(res?.user?.firebaseMessageTokens);
        // if(res.success){
        //   const t = await messaging.subscribeToTopic(res.user.firebaseMessageTokens, "all-device");
        //   console.log("subscribed to topic",t);
        // }
      }
      else {
        console.info("Permision Denied!");
        // alert("You Denied for permision");
      }
}
