import { useEffect, useState } from "react";
import { getAllGroups, getAllSurveyTitles } from "../../../Store/actions";
import { ACTIVITIES, GENDER_IDENTITIES } from "../../../constants";
import { PrimaryButton, SecondryButton } from "../../../components";

interface IUpdateUserModel {
    setIsOpen: any;
    formData: any;
    setFormData: any;
    handdleUpdateSubmit: any;
  }
  
  export const UpdateUserModel: React.FC<IUpdateUserModel> = ({setIsOpen,formData,setFormData,handdleUpdateSubmit}) => {
    const keys = Object.keys(formData);
    const [nonUniversalSurveyTitles, setNonUniversalSurveyTitles] = useState<any>(null);
    const [activityData, setActivityData] = useState({
      name: "",
      category: ""
    });
    const [groupId, setGroupId] = useState('');
    const [groups, setGroups] = useState<any>([]);
  
    // console.log(formData);
  
    const handleChange = (e:any) => {
      if(e.target.name === "unRegularSurveys"){
        setFormData(((prev:any) => {
          return {
            ...prev,
            [e.target.name]: Array.from(e.target.selectedOptions).map((option:any) => option.value)
          }
        }));
        return;
      }
      setFormData(((prev:any) => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      }));
    }
  
    const handleAddActivity = () => {
      setFormData(((prev:any) => {
        return {
          ...prev,
          activities: [...prev.activities,activityData]
        }
      }));
    }
  
    const handleAddGroup = () => {
      setFormData(((prev:any) => {
        return {
          ...prev,
          managementGroups: [...prev.managementGroups,groupId]
        }
      }));
    }
  
    const removeGroup = (groupId: string) => {
      setFormData(((prev:any) => {
        return {
          ...prev,
          managementGroups: prev.managementGroups.filter((gid:string) => gid !== groupId)
        }
      }));
    }
  
    function convertCamelCaseToWords(camelCaseWord:any) {
        let words = [camelCaseWord[0].toUpperCase()];
  
        for (let i = 1; i < camelCaseWord.length; i++) {
            if (camelCaseWord[i] === camelCaseWord[i].toUpperCase()) {
                words.push(' ');
            }
            words[words.length - 1] += camelCaseWord[i];
        }
  
        return words.join(' ');
    }
  
    useEffect(() => {
      (async () => {
        const res = await getAllSurveyTitles();
        const res1 = await getAllGroups();
        const NUST = res.surveyTitles.filter((st:any) => (!st.isUniversal));
        setNonUniversalSurveyTitles(NUST);
        if(res1.success){
          setGroups(res1.groups);
        }
      })()
    }, []);
  
    return (
      <div className="flex justify-center items-center fixed top-[62px] left-0 w-full h-full max-h-full bg-black/20">
        <div className="bg-white w-full md:w-[90%] max-h-full  py-4 px-2 rounded-md overflow-auto">
            <div className="w-full flex flex-wrap justify-center gap-2 p-2">
              {
                keys.map((key,idx) => {
                  if(key === "gender"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                                {
                                  GENDER_IDENTITIES.map((gen,idx) => <option key={idx} value={gen.toLocaleLowerCase()}>{gen || "Not Selected"}</option>)
                                }
                              </select>
                            </div>
                  }
                  if(key === "role"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                                {
                                  ["user","manager"].map((gen,idx) => <option key={idx} value={gen.toLocaleLowerCase()}>{gen}</option>)
                                }
                              </select>
                            </div>
                  }
                  if(key === "profession"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                                {
                                  ["","Student","Working Professional"].map((pro,idx) => <option key={idx} value={pro.toLocaleLowerCase()}>{pro || "Not Selected"}</option>)
                                }
                              </select>
                            </div>
                  }
                  if(key === "email"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="text" name={key} id={key} value={formData[key]} />
                            </div>
                  }
                  if(key === "dob"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="date" name={key} id={key} value={formData[key]} />
                            </div>
                  }
                  if(key === "postSurveyDate"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>Post Survey Time</label>
                              <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="datetime-local" name={key} id={key} value={formData[key]} />
                            </div>
                  }
                  if(key === "onbordingEmailTime"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                              <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type='datetime-local' name={key} id={key} value={formData[key]} />
                            </div>
                  }
                  if(key === "preSurveyId"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                                <label className='font-bold' htmlFor={key}>Pre Survey</label>
                                {
                                  !nonUniversalSurveyTitles && <p>Loading...</p>
                                }
                                {
                                  nonUniversalSurveyTitles && <>
                                      <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                                        {
                                          nonUniversalSurveyTitles.map((st:any,idx:number) => <option key={idx} value={st._id}>{st.title}</option>)
                                        }
                                      </select> 
                                  </>
                                }
                            </div>
                  }
                  if(key === "postSurveyId"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                                <label className='font-bold' htmlFor={key}>Post Survey</label>
                                {
                                  !nonUniversalSurveyTitles && <p>Loading...</p>
                                }
                                {
                                  nonUniversalSurveyTitles && <>
                                      <select name={key} id={key} value={formData[key]} onChange={handleChange}>
                                        {
                                          nonUniversalSurveyTitles.map((st:any,idx:number) => <option key={idx} value={st._id}>{st.title}</option>)
                                        }
                                      </select> 
                                  </>
                                }
                            </div>
                  }
                  if(key === "unRegularSurveys"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                                <label className='font-bold' htmlFor={key}>Impulse Survey</label>
                                {
                                  !nonUniversalSurveyTitles && <p>Loading...</p>
                                }
                                {
                                  nonUniversalSurveyTitles && <>
                                      <select name={key} id={key} value={formData[key]} multiple onChange={handleChange}>
                                        {
                                          nonUniversalSurveyTitles.filter((st:any) => st.title !== "Pre & Post XLrate Survey").map((st:any,idx:number) => <option key={idx} value={st._id}>{st.title}</option>)
                                        }
                                      </select> 
                                  </>
                                }
                            </div>
                  }
                  if(key === "activities"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <h6>{convertCamelCaseToWords(key)}</h6>
                              <div className='mb-2'>
                                {
                                  formData[key].map((activity:any,idx:number) => <p className='bg-white px-2 rounded-md mb-1' key={idx}>{activity.name} {activity.category}</p>)
                                }
                              </div>
                              <div className='flex items-streach'>
                                <input name='activityName' value={activityData.name} onChange={(e) => setActivityData({...activityData,name: e.target.value})} className=' px-2 py-1 border rounded-md' placeholder='Eg. coding..' />
                                <select value={activityData.category} className='p-1 border rounded-md' onChange={(e) => setActivityData({...activityData,category: e.target.value})}>
                                  {
                                    ACTIVITIES.filter(act => formData[key].find((ac:any) => ac.category === act) === undefined).map((activity,idx) => <option key={idx}>{activity}</option>)
                                  }
                                </select>
                                <button disabled={(activityData.name && activityData.category)? false:true} onClick={handleAddActivity} className='bg-blue-600 ml-1 px-2 text-white rounded-md disabled:bg-blue-600/50 disabled:cursor-not-allowed'>Add</button>
                              </div>
                            </div>
                  }
                  if(key === "managementGroups"){
                    return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                              <h6>User's Accessible Groups</h6>
                              {
                                formData["role"] === "manager" && <>
                                    {
                                      formData[key].length === 0 && <p className='text-secondry-color text-sm' >No Group</p>
                                    }
                                    {
                                      formData[key].length > 0 && <div className='my-2 text-secondry-color text-sm' >
                                        {
                                          formData[key].map((groupId:string,idx:number) => <p key={idx} className='my-1 text-sm text-secondry-color flex items-center justify-between'><span>{idx+1}. {groups.find((grp:any) => grp._id === groupId)?.groupname}</span><span onClick={()=> removeGroup(groupId)} className='text-xs bg-red-600 text-white p-1 rounded-md cursor-pointer'>Remove</span></p>)
                                        }
                                      </div>
                                    }
  
                                    <div className='flex items-streach'>
                                      <select value={groupId} className='flex-1 p-1 border rounded-md' onChange={(e) => setGroupId(e.target.value)}>
                                        <option value="">Select Group</option>
                                        {
                                          groups.filter((group:any) => formData[key].find((grp:any) => grp === group._id) === undefined).map((group:any,idx:number) => <option key={idx} value={group._id}>{group.groupname}</option>)
                                        }
                                      </select>
                                      <button disabled={groupId? false:true} onClick={handleAddGroup} className='bg-blue-600 ml-1 px-2 text-white rounded-md disabled:bg-blue-600/50 disabled:cursor-not-allowed'>Add</button>
                                    </div>
                                </>
                              }
                              
                            </div>
                  }
                  return <div key={idx} className="w-full md:w-auto flex flex-col bg-black/10 p-2 rounded-md">
                            <label htmlFor={key}>{convertCamelCaseToWords(key)}</label>
                            <input placeholder={`Enter ${convertCamelCaseToWords(key)}..`} onChange={handleChange} className='px-1' type="text" name={key} id={key} value={formData[key]} />
                         </div>
                })
              }
              <div className="w-[400px] flex justify-center mt-4 mb-14 md:mb-0 gap-4">
                  <SecondryButton onClick={()=>setIsOpen(false)}>Cancel</SecondryButton>
                  <PrimaryButton onClick={handdleUpdateSubmit}>Update</PrimaryButton>
              </div>
            </div>
        </div>
      </div>
    )
  };