import {useEffect,useState} from "react";
import {Tree } from "react-arborist";
import {
  updateGroupName,
  addGroup,
  addOrRemoveGroupFromGroup, 
  addUserToGroup,
  removeUserFromGroup, 
  addScheduledTime,
  updateScheduledTime,
  allowStopAccessToDashboardOfUsers,
  getScheduledTimeById
} from "../../../Store/actions";
import { useDispatch ,useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {AddSurveyTiming as AddSurveyTimingForm} from "./index";
import {dateFormet} from "../../../utils";
import { toast } from "react-toastify";
import {Loading} from "../../../components";
import { MakeTreeFromArray, addIdAndName , getAllUsers, getUsersFromGroupWithoutTiming } from "./utils";
import { Header } from "./Header";
import { GroupModal } from "./GroupModal";
import { UpdateBatchUsers } from "./UpdateBatchUsers";
import { ViewUserModal } from "./ViewUserModal";
import {Node} from "./Node";

  
function TreeComp({data,setScheduledTimes,scheduledTimes,surveyTitles}) {
      const [treeData, setTreeData] = useState(null);
      const [selectedUsers, setselectedUsers] = useState([]);
      const navigator = useNavigate();
      const dispatch = useDispatch();
      const [timingData, setTimingData] = useState({
        days: [],
        duration: {start: "",end: ""},
        frequency: "",
        time: {hours: "",minutes: ""},
        surveytitle: "",
        unRegularSurvey: "",
        _id: "",
      });
      const [_id, set_Id] = useState(null);
      const loggedInUser = useSelector(state => state.loggedInUser);
      const [isTimeUpdate, setIsTimeUpdate] = useState(false);
      const [showAddGroupModal, setShowAddGroupModal] = useState(false);
      const [groupName, setGroupName] = useState("");
      const [isUniversal, setIsUniversal] = useState(false);
      const [groupId, setGroupId] = useState(null);
      const [isUpdateGroup, setIsUpdateGroup] = useState(false);
      const [surveytitle] = useState("");
      // const [addOrUpdateSurveyTitle, setAddOrUpdateSurveyTitle] = useState(false);
      // const [surveyTitlesToUpdate,setSurveyTitlesToUpdate] = useState([]);
      const [selectedSurveyNames,setSelectedSurveyNames] = useState([]);
      const [displaySurveyTimingModal, setDisplaySurveyTimingModal] = useState(false);
      const [selectedGroup, setSelectedGroup] = useState(null);
      const [showUpdateBatchUsersPopup, setShowUpdateBatchUsersPopup] = useState(false);
      const [showViewUserPopup, setShowViewUserPopup] = useState(false);
      const [showUserNames, setShowUserNames] = useState(false);
 


      useEffect(() => {
            // dispatch(getAllScheduledTimes())
            const newData = JSON.parse(JSON.stringify(data));
            const temp = MakeTreeFromArray(newData,loggedInUser);
            setTreeData(temp);
      }, [data,dispatch,loggedInUser]);

      const handdleSelect = (nodes)=> {
        // console.log("aa");
        let temp = nodes[0]?.tree.selectedNodes;
  
        if(temp){
          setGroupId(temp[0].data._id);
          setSelectedGroup(temp[0]);
        }

        // set survey title for group 
        // const surveyTitlesForGroup = temp?temp[0].data.surveys:null;
  
        // if(surveyTitlesForGroup){
        //   setSurveyTitlesToUpdate(surveyTitlesForGroup);
        // }
       
    
        if(temp){
            let usersArr = [];

            temp.forEach(g => {
                if(!g.data.email){
                    const {userids,users,userSurveys} = getAllUsers(g);
                    setSelectedSurveyNames([...userSurveys][0]);

                    usersArr.push({
                        users: [...userids],
                        groupname: g.data.name,
                        groupId: g.data._id,
                        createdAt: g.data.createdAt,
                        usersDetails: users,
                        userSurveys: [...userSurveys]
                    })
                }
            })
            setselectedUsers(usersArr);
        }


        if(nodes[0]?.data?._id){
          set_Id(nodes[0].data._id)
        }
      }

      const handdleViewDashboard = ()=> {
        let temp = undefined;

        if(surveytitle){
          temp = selectedUsers.map(su => {return{...su,surveyTitle: surveytitle,selectedSurveyNames}});
        }
        else{
          temp = selectedUsers;
        }

        if(temp[0]?.users?.length <= 1){
          toast.error("You can't see dashboard");
          return;
        }
        // console.log(temp[0]);
        navigator("/dashboard/management",{ state: temp[0] })
      }

      const moveNode = (sourceId,sourceParentId,destnationId) => {
          let source = undefined;
          
          // remove source from its previous position  
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === sourceParentId){
                source = d.children.find(c => c.id === sourceId);
                d.children = d.children.filter(c => c.id !== sourceId);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          // add source to its new position   
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === destnationId){
                d.children.push(source);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          setTreeData([...treeData]);
      }

      const handleMove = async (e)=> {
        const isUser = e.dragNodes[0].data.email
        const sourceId = e.dragIds[0];
        const sourceParentId = e.dragNodes[0].parent?.id;
        const destinationId = e.parentId;
        const mongoSourceId = sourceId.split("---")[0];
        const mongoSourceParentId = sourceParentId.split("---")[0];
        const mongoDestinationId = destinationId.split("---")[0];

        if(destinationId === sourceParentId){
          return;
        }

        if(isUser && destinationId && mongoSourceId && mongoSourceParentId){
          const res = await removeUserFromGroup(mongoSourceId,mongoSourceParentId);
          const res1 = await addUserToGroup(mongoSourceId,mongoDestinationId);

          if(res.success && res1.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
          return;
        }

        if(destinationId && mongoDestinationId && mongoSourceId){
          const res = await addOrRemoveGroupFromGroup(mongoDestinationId,{groups: [mongoSourceId]});
         
          if(res.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
        }
      }

      const showAddOrUpdateGroupModal = (id,text) =>{
        if(text){
          setGroupName(text);
          setIsUpdateGroup(true);
        }
        else {
          setGroupName("");
          setIsUpdateGroup(false);
        }
        setGroupId(id);
        setShowAddGroupModal(true);
      }

      const handleAddGroupClick = (groupId) => {
        showAddOrUpdateGroupModal(groupId);
      }

      const handleEditGroupClick = (groupId,groupName) => {
        showAddOrUpdateGroupModal(groupId,groupName);
      }

      const handleAddSurveyTimingClick = (groupId) => {
          set_Id(groupId);
          setIsTimeUpdate(false);
          setTimingData(()=>{
            return {
              days: [],
              duration: {start: "",end: ""},
              frequency: "",
              time: {hours: ``,minutes: ``},
              surveytitle: "",
              _id: ""
            }
          });
          setDisplaySurveyTimingModal(true);
      }

      const handleUpdateSurveyTimingClick = async (groupId) => {
        set_Id(groupId);
        setIsTimeUpdate(true);
        const temp = await scheduledTimes.find(t => t.group === groupId);
        const res = await getScheduledTimeById(temp._id);
        const time = res.surveyPeriodFrequencyAndTime;
        if(time){
          setTimingData(()=>{
            return {
              days: time.days,
              duration: {start: dateFormet(time.duration.start),end: dateFormet(time.duration.end)},
              frequency: time.frequency,
              time: {hours: `${time.time.hours>9? time.time.hours:`0${time.time.hours}`}`,minutes: `${time.time.minutes>9? time.time.minutes:`0${time.time.minutes}`}`},
              surveytitle: time.surveytitle,
              unRegularSurvey: time.unRegularSurvey,
              _id: time._id
            }
          });
        }
        setDisplaySurveyTimingModal(true);
      }

      const handleEditBatchUsersClick = (groupId)=> {
        set_Id(groupId);
        setShowUpdateBatchUsersPopup(true);
      }

      const handleDashboardToggleClick = (e) => {
          const users = selectedUsers[0].users;
          let canAccessDashboard = false;
         
          if(e.target.checked){
            canAccessDashboard = true;
          }
          // console.log(canAccessDashboard);
          dispatch(allowStopAccessToDashboardOfUsers(users,canAccessDashboard));
      }

      
      const handleAddGroupSubmit = async ()=>{
        if(!isUpdateGroup){
          const res = await addGroup({parent: groupId,groupname: groupName,isUniversal: isUniversal});
          if(res.success){
            addGroupLocally(res);
            toast.success("Group Added Successfully!");
            setShowAddGroupModal(false);
          }
          else {
            console.log(res);
            toast.error("Error While Adding Group!");
          }
        }
        else {
          const res = await updateGroupName(groupId,{groupname: groupName});
        
          if(res.success){
            updateGroupNameLocally(res);
            toast.success("Group Updated Successfully!");
            setShowAddGroupModal(false);
          }
          else {
            toast.success("Error While Updating Group!");
          }
        }

        function addGroupLocally(res){
          const group = res.group;
          addIdAndName(group);
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.children.push(group);
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }

        function updateGroupNameLocally(res){
          const group = res.group;
    
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.groupname = group.groupname;
                d.name = group.groupname;
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }
      }

      const handdleAddSurveyTimingSubmit = async ()=> {
        const users = getUsersFromGroupWithoutTiming(groupId,selectedGroup.data,scheduledTimes)
    
        if(isTimeUpdate){
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          const res = await updateScheduledTime(timingData._id,temp);
          
          if(res.success){
            toast.success("Timing Updated Successfully!");
          }
          else {
            toast.success("Error While Updating Timing");
          }
        }
        else {
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          delete temp._id;

          const res = await addScheduledTime({...temp,group: _id});
          
          if(res.success){
            setScheduledTimes(prev => {
              return [...prev,res.surveyPeriodFrequencyAndTime]
            })
            toast.success("Timing Added Successfully!");
          }
          else {
            toast.success("Error While Adding Timing");
          }
        }
      }

      if(!treeData){
        return <div className="w-full h-[calc(100vh-64px-57px)] flex justify-center items-center"><Loading/></div>
      }
    
      return (
      <>
        <Header setShowUserNames={setShowUserNames} showUserNames={showUserNames} selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
  
        <Tree
            data={treeData}
            openByDefault={false}
            width={1000}
            indent={24}
            height={750}
            rowHeight={36}
            paddingTop={30}
            paddingBottom={10}
            padding={25 /* sets both */}
            onSelect={(nodes)=>{handdleSelect(nodes)}}
            onMove={(e)=>handleMove(e)}
            handleAddGroupClick={handleAddGroupClick}
            handleEditGroupClick={handleEditGroupClick}
            handleAddSurveyTimingClick={handleAddSurveyTimingClick}
            handleUpdateSurveyTimingClick={handleUpdateSurveyTimingClick}
            handleEditBatchUsersClick={handleEditBatchUsersClick}
            handleDashboardToggleClick={handleDashboardToggleClick}
            setShowViewUserPopup={setShowViewUserPopup}
            loggedInUser={loggedInUser}
            treeData={treeData}
            setTreeData={setTreeData}
            scheduledTimes={scheduledTimes}
            showUserNames={showUserNames}
          >
            {Node}
        </Tree>

        {/* add timing modal  */}
        <AddSurveyTimingForm onSubmit={handdleAddSurveyTimingSubmit} addName={isTimeUpdate? "Update Timing":"Add Timing"} isOpen={displaySurveyTimingModal} setIsOpen={setDisplaySurveyTimingModal} setTimingData={setTimingData} timingData={timingData}/>
        

        {/* group modal  */}
        <GroupModal addName={isUpdateGroup?"Update Group":"Add Group"} isOpen={showAddGroupModal} onSubmit={handleAddGroupSubmit} setIsOpen={setShowAddGroupModal} groupName={groupName} setGroupName={setGroupName} setIsUniversal={setIsUniversal} isUniversal={isUniversal}/>


        {/* update batch users  */}
        {
          showUpdateBatchUsersPopup && <UpdateBatchUsers setVisilivity={setShowUpdateBatchUsersPopup} groupId={_id} surveyTitles={surveyTitles}/>
        }


        {/* view user  */}
        {
          showViewUserPopup && <ViewUserModal setVisilivity={setShowViewUserPopup} selectedGroup={selectedGroup}/>
        }
      </>
      )
}

export default TreeComp;


