

interface IDeleteButton {
    children: any;
    onClick?: any;
    style?: React.CSSProperties;
    disabled?: any;
    id?: any;
}

export const DeleteButton: React.FC<IDeleteButton> = ({children,onClick=()=>{},style={},disabled=false,id=""}) => {

    return (
        <button id={id} onClick={onClick} disabled={disabled} style={style} className={`bg-red-100 text-red-600 w-full px-2 text-xs md:text-[14px] md:px-4 py-2 rounded-md  hover:shadow hover:shadow-red-600 focus:bg-red-600/70 disabled:bg-red-600/60 disabled:text-primary-color disabled:hover:shadow-none disabled:cursor-not-allowed`}>
            {
                children && children
            }
            {
                !children && "Primary Button"
            }
        </button>
    )
}