import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {Loading} from "../../../components";
import {
  FinalMessage,
  UserInfo,
  SurveySelection
} from "./index";
import { createActivity, getUserActivities, updateActivity } from '../../../Store/actions/activity';

interface IUserSurveySelection {
  isCommingFromPrePostSurvey: any;
  showOnlyUpdateActPage: any;
}

export const UserSurveySelection: React.FC<IUserSurveySelection> = ({isCommingFromPrePostSurvey,showOnlyUpdateActPage}) => {
  const navigator = useNavigate();
  const [activities, setActivities] = useState([
    {text: `My work`,selected: false,surveyName: "work"},
    {text: `My Relationship`,selected: false,surveyName: "relationship"},
    {text: `My Hobby`,selected: false,surveyName: "hobby"},
    {text: `My Studies`,selected: false,surveyName: "study"},
    {text: `My Sport`,selected: false,surveyName: "sport"},
    {text: `My Business`,selected: false,surveyName: "business"}
  ]);  
  const selectedCount =  activities.filter(ac => ac.selected).length;
  const [sections, setSections] = useState({
    surveySelection: !showOnlyUpdateActPage,
    userInfo: showOnlyUpdateActPage,
    goal: false,
    surveyFrequencyAndTime: false,
    finalMessage: false
  });
  const selectedActivities = activities.filter(act => act.selected);
  const [info, setInfo] = useState<any>({
        study: "",
        relationship: "",
        hobby: "",
        sport: "",
        business: "",
        work: ""
  });
  const [loading] = useState(false);
 

  const handleSubmit = async ()=> {
    const activities:any[] = [];
    
    selectedActivities.map(s => s.surveyName).forEach(sn => {
        activities.push({
          category: sn,
          name: info[sn]
        });
    })
  
    const actRes = await getUserActivities();

    if(actRes.success && actRes.activities.length > 0){
      for (let index = 0; index < activities.length; index++) {
        const activity = activities[index];
        const activityId = actRes.activities.find((ac:any) => ac.category === activity.category)._id;
        await updateActivity(activityId,{category: activity.category,name: activity.name});
      }
      return navigator(`/my-dashboard`);
    }
    else {
      const res = await createActivity(activities);

      if(res?.success){
        return navigator(`/my-dashboard`);
      }
    }
  }

 
 
  return (
    <>
        {
          loading? <><Loading loadingText='Please wait we are saving your preferences...'/></>:<>

        {
            sections.surveySelection && <SurveySelection 
            activities={activities} 
            selectedCount={selectedCount}
            setActivities={setActivities}
            setSections={setSections}
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            />
        }
        {
            sections.userInfo && <UserInfo
            selectedActivities={selectedActivities}
            setInfo={setInfo}
            info={info}
            setSections={setSections}
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            handleSubmit={handleSubmit}
            setActivities={setActivities}
            />
        }
        {
            sections.finalMessage && <FinalMessage
            handleSubmit={handleSubmit}
            />
        }
          </>
        }
    </>
  )
}










