import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { useEffect } from "react";
import { getAllGroups } from "../../../Store/actions";

interface IGroups {
    groups: any;
    setGroups: any;
    selectedGroup: any;
    setSelectedGroup: any;
    setData: any;
  }
  
  export const Groups: React.FC<IGroups> = ({groups,setGroups,selectedGroup,setSelectedGroup,setData}) => {
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    // const [tree, setTree] = useState(null);
    // const [descentantUsers, setDescentantUsers] = useState(null);
  
    useEffect(() => {
      (async ()=> {
        const res = await getAllGroups();
        console.log(res);
        if(res.success){
          // const tree = makeTreeFromArray(res.groups,loggedInUser);
          // setTree(tree);
          setGroups(res.groups);
          // setDescentantUsers(res.descentantUsers);
        }
      })()
    }, [setGroups,loggedInUser]);
  
    if(!groups || groups?.length === 0 || loggedInUser.role === "user"){
      return null;
    }
  
    const handleChange = (e:any) => {
      const id = e.target.value;
      const group = groups.find((grp:any) => grp._id === id);
      const users = new Set();
  
      visit(group);
      function visit(d:any){
  
        if(d.users){
          d.users.forEach((u:any) => {
            users.add(u);
          })
        }
  
        if(d.children){
          d.children.forEach((child:any) => {
            const c = groups.find((grp:any) => grp._id === child._id);
            if(c){
              visit(c)
            }
          });
        }
      }
  
      setData({
        users: [...users].map((u:any) => u._id),
        usersDetails: [...users],
        groupname: group.groupname,
        userSurveys: ""
      })
      setSelectedGroup(e.target.value)
    }
  
    // const onChange = (currentNode) => {
    //   const groupId = currentNode.value.split("---")[0];
    //   const grp = descentantUsers.find(u => u.groupId === groupId);
  
    //   if(grp.descendantUsers){
    //     const users = grp.descendantUsers.filter(u => u);
    //     if(users && users.length > 0){
    //       setData({
    //         users: users.map(u => u._id),
    //         usersDetails: users,
    //         groupname: currentNode.groupname,
    //         userSurveys: ""
    //       });
    //     }
    //   }
  
    //   if(currentNode.users && currentNode.users.length > 0){
    //     setData({
    //       users: currentNode.users.map(u => u._id),
    //       usersDetails: currentNode.users,
    //       groupname: currentNode.groupname,
    //       userSurveys: ""
    //     });
    //   }
    // }
  
    // if(!tree){
    //   return null;
    // }
  
    // const data = {
    //   label: 'View All Groups',
    //   value: 'root',
    //   children:tree
    // }
  
    return (<>
      <div className={`pt-1 [.radio-select]:bg-blue-300`}> 
        {/* <DropdownTreeSelect 
          data={data} 
          onChange={onChange} 
          mode="radioSelect"
          texts={{
            placeholder: "Select Group"
          }}
        /> */}
        <select value={selectedGroup} onChange={handleChange} className='w-full border border-secondry-color/20 bg-transparent rounded-md p-2'>
          <option value="" >Select a Group</option>
          {
            groups && groups.map((grp:any,idx:number) => <option key={idx} value={grp._id}>{grp.groupname}</option>)
          }
        </select>
      </div>
    </>)
  }