import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    Header,
    Wrapper 
} from "./index";
import { PrimaryButton } from "../../../components";
import { 
    updateLoggedInUser,
} from "../../../Store/actions";
import { Main } from "../../../layout";
import { useScrollToTop } from "../../../hooks";
import { getUserActivities, updateActivity } from "../../../Store/actions/activity";
import { AppDispatch, RootState } from "../../../Store/store";


interface IEnterActivityTitle {
    setScreen: any;
}


export  const EnterActivityTitle: React.FC<IEnterActivityTitle> = ({setScreen}) => {

    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [activities, setActivities] = useState<any>(null);
    const dispatch: AppDispatch = useDispatch();
    useScrollToTop();
    const placeHolder = "Enter activity name";

    const handleBack = () => {
        setScreen((prev:any) => {
            return {
                ...prev,
                enterActivityTitle: false,
                personalImprovementObstacles: true
            }
        })
    }
    
    const handleChange = (e:any) => {
        const category = e.target.name;
        setActivities((prev:any) => {
            return prev.map((act:any) => {
                if(act.category === category){
                    return {
                        ...act,
                        name: e.target.value
                    }
                }
                return act;
            })
        });
    }

    const giveInfo = (act:any,idx:number) => {
            switch (act.category) {
                case "work":
                    return <Field key={idx} preText={"My"} postText={"Work"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} />

                case "relationship":
                    return <Field key={idx} preText={"My relationship with"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={""}/>
   
                case "hobby":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Hobby"}/>

                case "study":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Study"}/>

                case "sport":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Sport"}/>

                case "business":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Business"}/>

                default:
                    break;
            }
    }

    const handleNext = async () => {
        

        if(loggedInUser.organisationName){
            setScreen((prev:any) => {
                return {
                    ...prev,
                    enterActivityTitle: false,
                    organisationName: false,
                    baiseline: true
                }
            });
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true}));
        }
        else {
            setScreen((prev:any) => {
                return {
                    ...prev,
                    enterActivityTitle: false,
                    organisationName: true
                }
            }); 
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true}));
        }

        for (let index = 0; index < activities.length; index++) {
            const activity = activities[index];
            await updateActivity(activity._id,{category: activity.category,name: activity.name});
        }
    }

    useEffect(() => {
        (async () => {
            const res = await getUserActivities();
            
            if(res?.success){
                setActivities(res.activities);
            }
        })()
    }, []);


    if(!activities){
        return null;
    }


    return (<>
         <Main>
            <Wrapper>
                {/* <ProgressBarAndBackButton handleBack={handleBack} completedSteps={5}/> */}
                <Header handleBack={handleBack} title="Personalise"/>
                <div className="max-h-[69vh] overflow-auto">
                    <p className='pb-10 text-xl text-secondry-color font-bold'>Enter the specific titles or names of the things you want track and improve.</p>
                
                    {/* activities */}
                    <div className='flex flex-col gap-10  mb-10'>
                        {
                            activities.map((act:any,idx:number) => {
                                return giveInfo(act,idx) 
                            })
                        }
                    </div>
                </div>
                {/* next btn  */}
                <div className="">
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                        {"Next"}
                    </PrimaryButton>
                </div>
            </Wrapper>
         </Main>
    </>)
}


interface IField {
    preText: any;
    postText: any;
    value: any;
    name: any;
    onChange: any;
    placeHolder: any;
}


export const Field: React.FC<IField> = ({preText,postText,value,name,onChange,placeHolder}) => {

    return (<>
        <div className="text-secondry-color flex justify-start items-center gap-3">
            <span className="font-bold">{preText}</span> 
            <input className="flex-1 bg-secondry-color/20 rounded-md px-4 py-2" type="text" value={value} name={name} onChange={onChange} placeholder={placeHolder}/> 
            <span className="font-bold">{postText}</span>
        </div>
    </>)
}

