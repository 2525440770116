import React, { useEffect, useState } from 'react';
import {Main} from "../layout";
import {deleteWaitingList, getAllWaitingList} from "../Store/actions";
import { Table, Tbody, Td, Th, Thead, Tr } from '../components';
import dayjs from 'dayjs';
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';

const tableHeaders = ["No.","First Name","surname","Email","Landing Page Name","Date","Time","1","2","3","4","5","6","7","8","9","10","Actions"];


export const WaitingList: React.FC<any> = () =>  {
  const [list, setList] = useState<any>(null);

  const returnAns = (asnwers:any,order:any) => {
    const ans = asnwers.find((ans:any) => ans.order === order);
    if(ans === undefined){
      return null;
    }
    if(ans.type === "multipleChoise"){
      return ans.choices.map((choice:any,idx:number) => <button className='bg-secondry-button-color px-2 rounded-md text-white' key={idx}>{choice}</button>)
    }
    if(ans.type === "rangeSlider"){
      return ans.value;
    }
  }

  const handleDelete = async (_id:any)  => {
     
      if(!window.confirm("Are you sure?")){
        return;
      }

      const res = await deleteWaitingList(_id);
      
      if(res.success){
        setList((prev:any) => {
          return prev.filter((item:any) => item._id !== _id);
        });
        toast.success("Deleted Succesfully!");
      }
      else {
        toast.error("Something went wrong!");
      }
  }

  useEffect(() => {
    getAllWaitingList()
    .then(res => {
      const waitingLists = res.waitingLists;
      setList(waitingLists);
    })
    .catch(error => {
      console.log("error",error);
    })
  }, []);

  return (
    <Main>
      <div className='py-10'>
        <h1 className='text-3xl text-secondry-color font-bold'>Waiting List</h1>
      </div>
      
      {
        list && <>
        
        <div className="w-full mt-5">
            <CSVLink data={prepareCSVData(list)} className='bg-secondry-button-color text-white no-underline px-4 py-2 flex items-center justify-center w-[150px] rounded-md mb-5'>Download Data</CSVLink>
            <Table>
                <Thead>
                  <Tr>
                    {
                      tableHeaders.map((th,idx) => <Th key={idx}><>{th}</></Th>)
                    }
                  </Tr>
                </Thead>
                <Tbody>
                    {
                      list.map((item:any,idx:number) => {

                        return <Tr key={idx}>
                                  <Td><>{idx + 1}</></Td>
                                  <Td>{item.firstName}</Td>
                                  <Td>{item.surName}</Td>
                                  <Td>{item.email}</Td>
                                  <Td>{item.pageName}</Td>
                                  <Td>{dayjs(item.createdAt).format("ll")}</Td>
                                  <Td>{dayjs(item.createdAt).format("hh:mm A")}</Td>
                                  {
                                    new Array(10).fill(1).map((itm,idx) => (
                                      <Td key={idx}>
                                          {
                                            returnAns(item.answers,idx+1)
                                          }
                                      </Td>
                                    ))
                                  }
                                  <Td><button onClick={()=> handleDelete(item._id)} className='bg-red-600 text-white px-3 py-1 rounded-md'>Delete</button></Td>
                               </Tr>
                      })
                    }
                </Tbody>
            </Table>
          </div>
        </>
      }
    </Main>
  )
}

function prepareCSVData(completedSurveys:any){

  const array = [tableHeaders];

  const returnAns = (asnwers:any,order:any) => {
    const ans = asnwers.find((ans:any) => ans.order === order);
    if(ans === undefined){
      return "";
    }
    if(ans.type === "multipleChoise"){
      return ans.choices.join(",")
    }
    if(ans.type === "rangeSlider"){
      return ans.value;
    }
  }
  
  
  completedSurveys.forEach((item:any,i:number) => {
    const ans = new Array(10).fill(1).map((itm,idx) =>  returnAns(item.answers,idx+1))

    const arr = [
      i+1,
      item.firstName,
      item.surName,
      item.email,
      item.pageName,
      dayjs(item.createdAt).format("ll"),
      dayjs(item.createdAt).format("hh:mm A"),
      ...ans
    ]

    array.push(arr);
  })

  return array;
}