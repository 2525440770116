import React from "react";
import {PrimaryButton, SecondryButton} from "../../../components";
import { PopupContainer } from "../../../layout";
import { Delete } from "../../../components/Icons";


const questionTypes = ["Fun","Purpose","Growth","Emotives","Utility","Apathy"];

interface IAddSurveyForm {
  formData: any;
  setFormData: any;
  setIsOpen: any;
  onSubmit: any;
  isOpen: any;
}

const AddSurveyForm: React.FC<IAddSurveyForm> = ({formData,setFormData,setIsOpen,onSubmit,isOpen}) => {
 
  
  const handdleOnChange = (e:any)=> {
    setFormData((prev:any) => {
      if(e.target.name === 'emailBody'){
        return {
          ...prev,
          email: {...prev.email,body: e.target.value}
        }
      }
      if(e.target.name === 'emailSubject'){
        return {
          ...prev,
          email: {...prev.email,subject: e.target.value}
        }
      }
      if(e.target.name === 'surveyquestioninstruction'){
        return {
          ...prev,
          surveyquestioninstruction: {...prev.surveyquestioninstruction,instruction: e.target.value}
        }
      }
      if(e.target.name === 'surveyquestioninstructionvisivility'){
        return {
          ...prev,
          surveyquestioninstruction: {...prev.surveyquestioninstruction,isvisible: e.target.checked}
        }
      }

      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })

  }

  const handdleAddQuestion = ()=> {
    setFormData((prev:any) => {
      return {
        ...prev,
        questions: [...prev.questions,{XLFactor: prev.XLFactor,question: prev.questionToAdd}]
      }
    })
  }

  const handdleDeleteQuestion = (question:any)=> {
    setFormData((prev:any) => {
      const remoningQuetions = prev.questions.filter((q:any) => q.question !== question)
        return {
          ...prev,
          questions: remoningQuetions
        }
      
    })
  }

  if(!isOpen){
    return null;
  }


  return (
    <PopupContainer>
      <form>
        <div className="mb-3 flex flex-col">
          <label htmlFor="title">Title</label>
          <CustomTextarea 
          name="title" 
          id="title" 
          placeholder="title" 
          defaultValue={formData?.title}
          handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="consentform" > Consent Form </label>
          <CustomTextarea
            name="consentform"
            id="consentform"
            placeholder="Concent Form"
            defaultValue={formData?.consentform}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="briefingform" > Briefing Form </label>
          <CustomTextarea
            name="briefingform"
            id="briefingform"
            placeholder="Briefing Form"
            defaultValue={formData?.briefingform}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="debriefingform" >Debriefing Form</label>
          <CustomTextarea
            name="debriefingform"
            id="debriefingform"
            placeholder="Debriefing Form"
            defaultValue={formData?.debriefingform}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="emailSubject" >Email Subject</label>
          <CustomTextarea
            name="emailSubject"
            id="emailSubject"
            placeholder="Email Subject"
            defaultValue={formData?.email?.subject}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="emailBody" >Email Body</label>
          <CustomTextarea
            name="emailBody"
            id="emailBody"
            placeholder="Email Body"
            defaultValue={formData?.email?.body}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="submittedsurveyscreen" >Submitted Surveyscreen</label>
          <CustomTextarea
            name="submittedsurveyscreen"
            id="submittedsurveyscreen"
            placeholder="Submitted Surveyscreen"
            defaultValue={formData?.submittedsurveyscreen}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3 flex flex-col">
          <label htmlFor="surveyquestioninstruction" >Survey Question Instruction</label>
          <CustomTextarea
            name="surveyquestioninstruction"
            id="surveyquestioninstruction"
            placeholder="Survey Question Instruction"
            defaultValue={formData?.surveyquestioninstruction?.instruction}
            handdleOnChange={handdleOnChange}
          />
        </div>

        <div className="mb-3">
          <label>
            <input 
            type="checkbox" 
            name="surveyquestioninstructionvisivility" 
            onChange={handdleOnChange}
            checked={formData?.surveyquestioninstruction?.isvisible}
            />
           &nbsp;&nbsp; Survey Question Instruction Visibility
          </label>
        </div>

        <div className="mb-3 flex items-center">
          <label htmlFor="surveytitleMaths">Survey Maths</label>
          <select className="flex-1 ml-2 border p-2 rounded-md" name="surveytitleMaths" id="surveytitleMaths" value={formData?.surveytitlemaths} onChange={handdleOnChange}>
            <option value="none">None</option>
            <option value="XLIndividualSurveyMathsFunction"> XL Individual Survey maths function </option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="surveytitleMaths">Questions</label>
          <div className="questions px-3">
            {
              formData.questions.map((q:any,i:number)=>{
                return <p className="flex items-center justify-between my-2 border p-2 rounded-md" key={i}>
                            <small className="grow-1">
                              <span>{q.XLFactor}</span> : <span className="survey-question" data-questiontype="q1">{q.question}</span>
                            </small>
                            <Delete title="Delete" className="cursor-pointer w-5" onClick={()=>handdleDeleteQuestion(q.question)}/>
                        </p>
              })
            }
             
          </div>
          <div className="addQuestion">
            <CustomTextarea
              name="questionToAdd"
              id="questionToAdd"
              placeholder="Add Question..."
              handdleOnChange={handdleOnChange}
            />
            <div className="flex items-streach mt-1 gap-1">
              <select className="flex-1 border p-2 rounded-md" name="XLFactor" id="questiontype" onChange={handdleOnChange}>
                <option>Question Type</option>
                {questionTypes.map((val,index)=>{
                  return <option value={val} key={index}>{val}</option>
                })}
              </select>
              <div>
                <PrimaryButton style={{padding: "12px 12px"}} onClick={handdleAddQuestion}>Add</PrimaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2">
          <PrimaryButton onClick={()=>setIsOpen(false)} style={{padding: "12px 0"}}>Cancel</PrimaryButton>
          <SecondryButton onClick={(e:any)=>{e.preventDefault();onSubmit();}} style={{padding: "12px 0"}}>{formData.questions.length? "Update":"Insert"}</SecondryButton>
        </div>
      </form>
    </PopupContainer>
  );
}

export default AddSurveyForm;



interface ICustomTextarea {
  minRows?: any;
  name: any;
  id: any;
  placeholder: any;
  handdleOnChange: any;
  defaultValue?: any;
}

export const CustomTextarea: React.FC<ICustomTextarea> = ({minRows,name,id,placeholder,handdleOnChange,defaultValue}) => {
  const [rows, setRows] = React.useState(minRows);
  const [value, setValue] = React.useState("");
  

  const onChnageHandler = (e:any)=>{
      setValue(e.target.value)
      handdleOnChange(e)
  }

  React.useEffect(() => {
    console.log("Add Survey Form 247");
    setValue(defaultValue);

    const rowlen = value?.split("\n");

    if (rowlen?.length > minRows) {
      setRows(rowlen.length);
    }
  }, [value,minRows,defaultValue]);

  return (
    <textarea 
    name={name}
    id={id}
    placeholder={placeholder}
    className="rounded-md p-2 w-full" 
    rows={rows} 
    onChange={onChnageHandler} 
    value={value}
    />
  );
}



