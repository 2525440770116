import { FactorBar } from "./FactorBar";

interface IFactor {
    value: any;
    bg: any;
    isConstrainers: any;
    showRange: any;
    min: any;
    max: any;
    change: any;
    showChange: any;
}

export const Factor: React.FC<IFactor> = ({value,bg,isConstrainers,showRange,min,max,change,showChange}) => {


    return <>
      <div className="flex flex-col items-center justify-center gap-2">
          {/* {
            !isConstrainers && <div className="flex  items-center gap-1 text-xs justify-center">
                                    <span>{factor}</span> 
                                    <span id={factor} data-tip={toolTip}>
                                      <InfoIcon/>
                                    </span>
                                </div>
          } */}
          
          <div className="h-[134px]">
            <FactorBar
              value={value}
              isReverse={isConstrainers}
              bg={bg}
              showRange={showRange}
              min={min}
              max={max}
              percentageOfchange={change}
              showChange={showChange}
            />
          </div>
  
          {/* {
            isConstrainers && <div className="flex items-center gap-1 text-xs justify-center">
                                    <span>{factor}</span> 
                                    <span id={factor} data-tip={infoTexts[0]}>
                                      <InfoIcon/>
                                    </span>
                                </div>
          } */}
      </div>
    </>
}