import React from 'react'
import { Main } from '../layout';
import { PrimaryButton } from './PrimaryButton';


interface ITermsAndContidionsPopup {
  setIsTCOpen: any;
  setIsConditionChecked: any;
}

const TermsAndContidionsPopup: React.FC<ITermsAndContidionsPopup> = ({setIsTCOpen,setIsConditionChecked}) => {
    const termAndConditions = [
        {
          title: "Terms & Conditions",
          description: `Welcome to XLRate, a personal growth platform that analyses and provides intuitive visual metrics to help you become more self-aware and track and enhance your personal growth and wellbeing. By using this platform, you agree to the following terms and conditions`
        },
        {
          title: "Personal Data Collection",
          description: `XLRate collects psychometric data and text input about your psychological state to analyse and provide intuitive visual metrics. This data may be anonymized and used by your organisation to enhance the general environment, communications, and organisational culture to improve general wellbeing and personal growth`
        },
        {
          title: "Consent",
          description: `Before collecting any personal data, XLRate will obtain explicit consent from you. You must give informed, specific, and freely given consent to the collection and processing of your personal data`
        },
        {
          title: "Withdrawing Consent",
          description: `You can withdraw your consent at any time by contacting us at DPO@xlrate.10x.systems. The process for withdrawing consent will be as easy as giving it. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal`
        },
        {
          title: "Security",
          description: `XLRate will take all necessary measures to ensure the security of your personal data, including encryption, secure data transfers, and secure storage`
        },
        {
          title: "Specific Security Measures",
          description: `We use advanced encryption methods, regular security audits, and restricted access controls to protect your data`
        },
        {
          title: "Access and Deletion",
          description: `You have the right to access, update, or delete your personal data at any time. Contact XLRate at DPO@xlrate.10x.systems to exercise these rights`
        },
        {
          title: "Response Timeframe",
          description: `We will respond to your request within one month of receipt. If more time is needed, we will inform you of the reason for the delay and the extension period`
        },
        {
          title: "Third-Party Access",
          description: `Your personal data will not be shared with any third party without your explicit consent`
        },
        {
          title: "Age Restrictions",
          description: `You must be at least 16 years old to use XLRate. If you are under 16, you must have obtained parental consent for the use of your personal data`
        },
        {
          title: "Verification of Parental Consent",
          description: `We may request proof of parental consent for users under 16, such as a signed consent form or other verification methods`
        },
        {
          title: "Changes to Terms and Conditions",
          description: `XLRate reserves the right to modify these terms and conditions at any time. By continuing to use the platform, you agree to the updated terms and conditions`
        },
        {
          title: "Notification of Changes",
          description: `We will notify you of any changes to these terms via email and in-app notifications at least 30 days before the changes take effect. You will need to accept the updated terms to continue using the platform`
        },
        {
          title: "Disclaimer of Warranties",
          description: `XLRate makes no warranty that the platform will meet your requirements or that it will be error-free or uninterrupted`
        },
        {
          title: "Limitation of Liability",
          description: `XLRate shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the platform`
        },
        {
          description: `By using XLRate, you agree to these terms and conditions. If you do not agree, do not use the platform`
        },
    ];

    const handleClick = (e:any) => {
        e.preventDefault();
        setIsTCOpen(false);
        setIsConditionChecked(true);
    }

    return <>
        <Main>
            <div className='w-[700px] max-w-full px-4 mx-auto py-5'>
                <div className='h-[80vh] overflow-auto'>
                            {
                                termAndConditions.map((item,idx) => (
                                    <div key={idx}>
                                    {
                                        item.title && <h1 className="text-xl font-bold mb-2 text-secondry-color">{item.title}</h1>
                                        }
                                    <p className="mb-6 text-secondry-color">{item.description}</p>
                                    </div>
                                ))  
                            }
                </div>
                <PrimaryButton style={{padding: "12px 0"}} onClick={handleClick}>I Consent</PrimaryButton>
            </div>
        </Main>
    </>
}

export {TermsAndContidionsPopup}