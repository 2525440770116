


interface IShadowBox {
  children: JSX.Element[] | JSX.Element | any;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  onClick?: any;
  id?: string;
}


const ShadowBox: React.FC<IShadowBox> = ({children,className,style,title="",onClick=()=>{},id}) => {
  return (
    <div id={id} onClick={onClick} style={style} title={title} className={`bg-white/20 shadow-md rounded-md p-5 ${className}`}>
        {children}
    </div>
  )
}

export {ShadowBox}