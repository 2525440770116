

interface IWrapper {
    children: any;
}

export const Wrapper: React.FC<IWrapper> = ({children}) => {

    return <>
    <div className="max-w-full w-[600px] mx-auto py-8">{children}</div>
    </>
}
