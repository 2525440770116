import { useState } from "react";
import { ArrowDown, ForwardArrowBold } from "../../../components/Icons";

interface IOnBoardingStatus {
    user:any;
  }
  
  export const OnBoardingStatus: React.FC<IOnBoardingStatus> = ({user}) => {
  
    const [isShowMore, setIsShowMore] = useState(false);
    
    const steps = [
      {
        placeholder: "Create Password",
        name: "hasCreatedNewPassword",
      },
      
      {
        placeholder: "1st Login",
        name: "hasCompletedFirstLogin",
      },
      {
        placeholder: "Welcome Screen",
        name: "isIntroSeen",
      },
      {
        placeholder: "Pre XLRate Survey",
        name: "isPreSurveyDone",
      },
      {
        placeholder: "Intro to XLRate videos",
        name: "isWelcomeSeen",
      },
      {
        placeholder: "Set goals",
        name: "isPersonalImprovementGoalsSeen",
      },
      {
        placeholder: "Obstacles",
        name: "isPersonalImprovementObstaclesSeen",
      },
      {
        placeholder: "Final projection",
        name: "isFinalProjectionSeen",
      },
      {
        placeholder: "Enter title activity",
        name: "isEnterActivityTitleSeen",
      },
      {
        placeholder: "1st Survey",
        name: "hasCompletedFirstSurvey",
      },
      {
        placeholder: "1st Dashboard",
        name: "hasSeenDashboardFirstTime",
      },
      {
        placeholder: "Post Survey",
        name: "isPostSurveyDone",
      },
    ];
  
  
    const isDone = steps.every(step => user[step.name]);
  
  
    return (
      <>
          {
            !user.isCreatedByAdmin && <p className='mb-0 text-sm'>Not Created By Admin</p>
          }
          
          {
            (user.isCreatedByAdmin && isDone) && <p title='More Info..' onClick={()=> setIsShowMore(!isShowMore)} className='cursor-pointer px-3 py-2 rounded-full font-semibold text-xs bg-green-50 text-green-600 text-center rounded-md mb-0 flex justify-between items-center'>Finished {isShowMore? <ArrowDown className={"w-4"} />:<ForwardArrowBold className={"w-4"} />}</p>
          }
  
          {
            (user.isCreatedByAdmin && !isDone) && <p title='More Info..' onClick={()=> setIsShowMore(!isShowMore)} className='cursor-pointer px-3 py-2 rounded-full font-semibold text-xs bg-yellow-50 text-yellow-600 text-center rounded-md mb-0 flex justify-between items-center'>In Progress.. {isShowMore? <ArrowDown className={"w-4"} />:<ForwardArrowBold className={"w-4"} />}</p>
          }
  
          {
              (user.isCreatedByAdmin && isShowMore)  && (
                      <>
                      <div className="mt-2">
                          {
                            steps.map((step,idx) => (
                              <div key={idx} className="flex items-center gap-2">
                                <span>{step.placeholder}</span>
                                <span className={`w-3 h-3 rounded-full ${user[step.name]? "bg-green-600":"bg-yellow-400"}`}/>
                              </div>
                              ))
                            }
                      </div>
                      </>
              )
          }
      </>
    )
  }
  