import { SET_NUMBER_OF_ACTION_CHALLENGES } from "../../constants";


interface InumberOfActionChallenges {
  type: string;
  payload?: any;
}

export function numberOfActionChallenges(state = 0, action:InumberOfActionChallenges) {

    switch (action.type) {

      case SET_NUMBER_OF_ACTION_CHALLENGES:
        return action.payload;

      default:
        return state
    }
}
