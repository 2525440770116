import React, { useEffect, useState } from 'react'
import {Main} from "../../layout";
import {
    getUserNotifications,
    deleteNotification,
    createNotification
} from "../../Store/actions";
import {Loading, PrimaryButton} from "../../components";
import { toast } from 'react-toastify';
import {
    NotificationModal, 
    Notifications
} from "./components";
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { getUserActivities } from '../../Store/actions/activity';


const dummyNotiData = {
    activityCategory:"work",
    activityName: "coding",
    days: new Array(5).fill(1).map((i,idx) => {
        return {
                day: idx+1,
                times: [
                    {
                        hour: 8,
                        minute: 30,
                        time: new Date(dayjs().startOf("day").toDate())
                    },
                    {
                        hour:14,
                        minute:0,
                        time: new Date(dayjs().startOf("day").toDate())
                    }
                ]
                
            }
    }),
    endDate:"2024-12-04",
    startDate:"2024-07-03"
}

function Index() {
    const [notifications, setNotifications] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [notificationToEdit, setNotificationToEdit] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleDelete = async (e:any,item:any) => {
        if(!window.confirm("Are you sure?")){
            return;
        };

        const res = await deleteNotification(item._id);
        if(res.success){
            toast.success("Deleted Successfutty!");
            setNotifications((prev:any) => {
                return prev.filter((noti:any) => noti._id !== item._id);
            });
        }
        else {
            toast.error(res.message);
        }
        // console.log(e,item);
    }

    const handleAddOrBack = () => {
        if(location.state?.link){
            console.log("back");
            navigate("/on-boarding")
            return;
        }
        setIsShowModal(true)
    }

    useEffect(() => {
        (async ()=> {
            setIsLoading(true)
            const res = await getUserNotifications();
            const res1 = await getUserActivities();
            
            setIsLoading(false);
            if(res.success && res1.success){
                const activityCategory = res1.activities[0]?.category;
                const activityName = res1.activities[0]?.name;
                if(res.notifications?.length === 0 && activityCategory && activityName){
                        const res1 = await createNotification({...dummyNotiData,activityCategory,activityName});
                        if(res1.success){
                            setNotifications([res1.notification]);
                        }
                }
                else {
                    setNotifications(res.notifications);
                }
            }
        })()
    }, []);


    return (
        <Main>
            <div className="items-start justify-between items-center md:flex border-b border-secondry-color/20 pb-7 pt-7">
                <div className="max-w-lg">
                    <h3 className="text-secondry-color text-xl font-bold sm:text-2xl">Manage Notifications</h3>
                </div>
                <div className="mt-3 md:mt-0 w-[150px]">
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleAddOrBack}>
                        {location.state?.link? "Back":"Create New"}
                    </PrimaryButton>
                </div>
            </div>
            {
                isLoading? <Loading/>:<Notifications handleDelete={handleDelete} setNotificationToEdit={setNotificationToEdit} notifications={notifications || []}  setIsShowModal={setIsShowModal}/>
            }
            {
                isShowModal && <NotificationModal setNotificationToEdit={setNotificationToEdit} notificationToEdit={notificationToEdit} setIsShowModal={setIsShowModal} setNotifications={setNotifications}/>
            } 

            {
                (location.state?.link) && <div className='mt-10'>
                                            <PrimaryButton disabled={(notifications === null || notifications?.length === 0)? true:false} onClick={()=> {navigate(location.state?.link)}}>Now, lets do Your 1st CogniPulse</PrimaryButton>
                                            <small className='text-center block w-full text-secondry-color'>(It takes about 50 seconds)</small>
                                        </div>
            }
        </Main>
    )
}

export default Index;

