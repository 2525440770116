import { useEffect } from "react";

interface IMultipleSelect {
    setData: any;
    activities: any;
    setActivities: any;
    isDropdownShow: any;
    setIsDropdownShow: any;
  }
  
  export const MultipleSelect: React.FC<IMultipleSelect> = ({setData,activities,setActivities,isDropdownShow,setIsDropdownShow}) => {
    
    
    const onActivityNameChange = (e:any,category:any) => {
      setActivities((prev:any) => {
        return prev.map((act:any) => act.category === category? {...act,name: e.target.value}:act);
      })
    }
  
    const Activity = ({item,setActivities}:any) => {
  
      const handleChangeActivity = (e:any) => {
        const checked = e.target.checked;
        setActivities((prev:any) => {
          if(checked){
            return prev.map((item:any) => item.category === e.target.value? {...item,checked:true}:item);
          }
          return prev.map((item:any) => item.category === e.target.value? {...item,checked:false}:item);
        })
      }
  
      const handleChangeName = (e:any) => {
        const checked = e.target.checked;
        setActivities((prev:any) => {
          if(checked){
            return prev.map((item:any) => item.category === e.target.value? {...item,nameChecked: true}:item);
          }
          return prev.map((item:any) => item.category === e.target.value? {...item,nameChecked: false}:item);
        })
      }
  
      return (
              <li className='border-b'>
                <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input checked={item.checked} value={item.category} onChange={handleChangeActivity} id="checkbox-item-11" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                  <label htmlFor="checkbox-item-11" className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{item.category}</label>
                </div>
                <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input checked={item.nameChecked} value={item.category} onChange={handleChangeName} id="checkbox-item-11" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                  <label htmlFor="checkbox-item-11" className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Name of Activity by Admin</label>
                </div>
              </li>
      )
    }
  
    useEffect(() => {
      const remaningActivities = activities.filter((item:any) => item.checked);
      setData((prev:any) => {
        if(remaningActivities.length === 0){
          return {
            values: prev.values.map((val:any) => {
              delete val.requiredActivities;
              return {...val}
            }),
            keys: prev.keys.filter((key:any) => key !== "requiredActivities")
          }
        }
        const remaningActivitiesStr = remaningActivities.map((item:any) => item.category).join(",");
        return {
          values: prev.values.map((val:any) => {return {...val,requiredActivities: remaningActivitiesStr}}),
          keys: [...new Set([...prev.keys,"requiredActivities"])]
        }
      });
    }, [setData,activities]);
  
    
  
    return (
      <>
      <div className="relative flex items-center gap-3">
        <button onClick={()=>setIsDropdownShow((p:any)=>!p)} id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" className="inline-flex items-center p-2 text-center border focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
          Select Required activities 
          <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
          </svg>
        </button>
  
        {/* selected Activities  */}
        <div className="flex items-center gap-3">
          {
            activities.filter((ac:any) => ac.checked).map((activity:any) => {
              return <div key={activity.category} className="p-2 border text-white flex gap-2">
                <span className='bg-green-600 px-2 py-1 rounded-md'>{activity.category}</span>
                {
                  activity.nameChecked && <input value={activity.name} onChange={(e)=>onActivityNameChange(e,activity.category)} className='border px-2 rounded-md text-black' type="text" placeholder='Activity Name'/>
                }
              </div>
            })
          }
          {
            activities.filter((ac:any) => ac.checked).length === 0 && <div className="">No Selected Activity</div>
          }
        </div>
  
        {/* <!-- Dropdown menu --> */}
        {
          isDropdownShow && <>
          <div id="dropdownSearch" className="absolute top-[calc(100%+10px)] z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700">
            <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
              {
                activities.map((item:any) => <Activity key={item.category} item={item} setActivities={setActivities}/>)
              }
            </ul>
          </div>
          </>
        }
      </div>
      </>
    )
  }