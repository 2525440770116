import React,{useEffect,useState} from 'react';
import {CompletedSurvey} from "./components/CompletedSurvey";
import {
  getAllCompletedSurveys,
  getAllEventsOfUser,
  getAllGroups,
  getAllSurveyTitles,
  getAllUsersSurnameForename
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Loading, Td} from "../../components"; 
import {Thead, Table, Tbody, Th, Tr} from "../../components"; 
import {XL_FACTORS} from "../../constants";
import {Main} from "../../layout";
import dayjs from 'dayjs';
import { CSVLink } from "react-csv";
import { allSurveysWithXLScoresToHistoricalSPQ } from '../../utils';
import { prepareCSVData, prepareCSVData1, prepareCSVData2 } from './utils';
import { FilterOptions } from './components/FilterOptions';
import { SomethingNotFound } from './components/SomethingNotFound';
import { ImpactTableHeaders, sdTableHeader, tableHeaders } from './constants';



interface ICompletedSurveys {}

const CompletedSurveys: React.FC<ICompletedSurveys> = () => {

  const initObj = {
    userSearch: "",
    userId: null,
    surveySearch: "",
    surveyId: null,
    groupSearch: "",
    surveyName: "",
    page: 1,
    groupId: null,
    showAll: false,
    showExcluded: false
  }
  const [searchTerms,setSearchTerms] = useState<any>(initObj);
  const [completedSurveys,setCompletedSurveys] = useState<any[]>([]);
  const [historicalOPQ,setHistoricalOPQ] = useState<any>(null);
  const [users,setUsers] = useState([]);
  const [loading,setLoading] = useState(true);
  const [surveyFound,setSurveyFound] = useState<any>(false);
  const [excludedFields,setExcludedFields] = useState<any[]>([]);
  const [groups,setGroups] = useState([]);
  const [surverTitles,setSurverTitles] = useState([]);
  const [isShowData,setIsShowData] = useState(false);
  const [paginationData,setPaginationData] = useState<any>(null);
  const [isChangingPage,setIsChangingPage] = useState(false);
  const headerStr = JSON.stringify(tableHeaders.filter((item,i:number) => excludedFields.indexOf(i+1) === -1));
  const bodyStr = completedSurveys? JSON.stringify(
    completedSurveys.map((survey,i) => {
      const group:any = groups.find((gp:any) => {
        const user = gp?.users?.find((user:any) => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })

      const arr = [
        i+1,
      `${survey?.user?.forename}${survey?.user?.surname}`,
      survey?.user?.email,
      "org",
      "dept",
      group?.groupname || "NA",
      survey?.surveytitle?.title,
      survey.surveyName,
      survey?.user?.activities.find((ac:any) => ac.category === survey.surveyName)?.name || "",
      new Date(survey?.createdAt).toLocaleString(),
      ...XL_FACTORS.map(XLFactor => {
          const ans = survey[XLFactor]
          if(!ans){
            return "NA"
          }
          return mapRange(ans.rating.$numberDecimal,1,7,0,100)
      })
      ]

      return arr.filter((item,i) => excludedFields.indexOf(i+1) === -1);
    })
  ):"";
  const [isDataAnonymized, setIsDataAnonymized] = useState(true);
  const [isImpactSurvey, setIsImpactSurvey] = useState<boolean>(false);
  const [fileName, setFileName] = useState("default.csv");
  const csvData = prepareCSVData(completedSurveys,groups,isImpactSurvey,isDataAnonymized);
  const csvDataOfSD:any = prepareCSVData1(historicalOPQ,groups);
  const [eventCSVData, seteventCSVData] = useState<any>(null);
  
  
  function mapRange(value:any, inMin:any, inMax:any, outMin:any, outMax:any) {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  const handleInclueExcludeNumber = (number:number) => {
    setExcludedFields(prev => {
      const isExist = prev.find(n => n === number)
      if(isExist){
        return prev.filter(n => n !== number);
      }
      return [...prev,number]
    })
  }

  const  downloadTxtFile = ()=> {
    const text = `${headerStr}${bodyStr}`;
    const filename = "sample_file.txt";
  
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
  
    element.style.display = "none";
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  const loadMore = async () => {
    let page = Number(searchTerms.page)+1;

    let query = ``;

    if(searchTerms.userId){
      query += `user=${searchTerms.userId}`;
    }
    if(searchTerms.surveyId){
      query += `&&surveytitle=${searchTerms.surveyId}`;
    }
    if(searchTerms.groupId){
      query += `&&groupId=${searchTerms.groupId}`;
    }

    if(searchTerms.groupId || searchTerms.surveyId || searchTerms.userId){
      query += `&&showAll=${true}`;
    }
    else {
      query += `&&showAll=${searchTerms.showAll}`;
    }

    query += `&&isexcluded=${searchTerms.showExcluded}&&page=${page}`;
    setIsChangingPage(true);
    const res = await getAllCompletedSurveys(query);
    setIsChangingPage(false);
    
    if(res.success){
        const sortedSurveys = res.surveys.sort((a:any,b:any)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
        setCompletedSurveys((prev)=> {
          return [...prev,...sortedSurveys]
        });
        const temp1 = sortedSurveys.reduce((a:any,b:any) => {
          const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
          if(b.user){
            if(a[b.user._id]){
              a[b.user._id].push(t);
            }
            else {
              a[b.user._id] = [t]; 
            }
          }
          return a; 
        },{});
        let sds:any[] = [];
        for (const key in temp1) {
          const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
          const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
          sds = [...sds,...t];
        }
        setHistoricalOPQ((prev:any) => {
          return [...prev,...sds]
        });
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            page: res.page
          }
        });
        setPaginationData({
          page: res.page,
          totalDocuments: res.totalDocuments,
          limit: res.limit,
        });
    }
    else {
      toast.error("Someting Went Wrong!")
    }
  }

  const handleDownloadEvents = async () => {
    const res = await getAllEventsOfUser(searchTerms.userId);
    // console.log(res);
    if(res.success){
      const data = prepareCSVData2(res.events);
      seteventCSVData(data);
    }
    else {
      toast.error(res.message);
    }
  }

  
  useEffect(() => {
      (async ()=>{
        setLoading(true);
        const res = await getAllCompletedSurveys("showAll=false&&isexcluded=false");
        console.log(res);
        setLoading(false);
        if(res.success){
          const sortedSurveys = res.surveys.sort((a:any,b:any)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
          setCompletedSurveys(sortedSurveys);
          setSurveyFound(sortedSurveys.length > 0? true:false);
          const temp1 = sortedSurveys.reduce((a:any,b:any) => {
            const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
            if(b.user){
              if(a[b.user._id]){
                a[b.user._id].push(t);
              }
              else {
                a[b.user._id] = [t]; 
              }
            }
            return a; 
          },{});
          let sds:any = [];
          for (const key in temp1) {
            const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
            const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
            sds = [...sds,...t];
          }

          setHistoricalOPQ(sds);
          setPaginationData({
            page: res.page,
            totalDocuments: res.totalDocuments,
            limit: res.limit,
          });
        }
        else{
          toast.error("Someting Went Wrong!")
        }
        
      })()
  }, []);

  useEffect(() => {
    (async ()=> {
      const res = await getAllUsersSurnameForename();
      if(res.success){
        setUsers(res.users);
      }
      const res1 = await getAllGroups();
      if(res1.success){
        setGroups(res1.groups);
      }
      const res2 = await getAllSurveyTitles();
      if(res2.success){
        setSurverTitles(res2.surveyTitles);
      }
    })()
  }, []);


  useEffect(() => {
    if(completedSurveys){
      const startDate = dayjs(completedSurveys[0]?.createdAt).format("ll");
      const endDate = dayjs(completedSurveys[completedSurveys?.length-1]?.createdAt).format("ll");
      const name = `${searchTerms.userSearch}_${searchTerms.surveySearch}_${searchTerms.surveyName}_${startDate}-${endDate}`
      setFileName(name);
    }
  }, [completedSurveys,searchTerms]);


  if(loading){
     return <Loading/>
  }
  
  return (
    <>
    <Main>
        <FilterOptions setIsImpactSurvey={setIsImpactSurvey} searchTerms={searchTerms} setPaginationData={setPaginationData} setSearchTerms={setSearchTerms} setLoading={setLoading} setHistoricalOPQ={setHistoricalOPQ} setCompletedSurveys={setCompletedSurveys} setSurveyFound={setSurveyFound} users={users} groups={groups} surverTitles={surverTitles}/>
        
        <div className="flex gap-3 flex-wrap">
          {
            bodyStr !== "[]" && <button onClick={downloadTxtFile} className='bg-blue-600 text-white rounded-md px-4 py-1'>Download .txt File</button>
          }
          {
            (bodyStr !== "[]" && csvData) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink filename={fileName} data={csvData} className='text-white no-underline'>Download .csv File</CSVLink></button>
          }
          {
            bodyStr !== "[]" && <button onClick={()=> setIsShowData(p => !p)} className='bg-blue-600 text-white rounded-md px-4 py-1'>{isShowData? "Hide":"Show"} Data</button>
          }
          {
            bodyStr !== "[]" && <button onClick={()=> setIsDataAnonymized(p => !p)} className='bg-blue-600 text-white rounded-md px-4 py-1'>{!isDataAnonymized? "Hide":"Show"} Identifying details</button>
          }
          {
            (csvDataOfSD?.length > 9 && csvDataOfSD) && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={csvDataOfSD} className='text-white no-underline'>Download SD Data</CSVLink></button>
          }
          {
            searchTerms.userId && <button onClick={handleDownloadEvents} className='bg-blue-600 text-white rounded-md px-4 py-1'>Fetch Events Data</button>
          }
          {
            eventCSVData && <button className='bg-blue-600 text-white rounded-md px-4 py-1'><CSVLink data={eventCSVData} className='text-white no-underline'>Download Event Data</CSVLink></button>
          }
        </div>
        
        <div className="">
          {
            (bodyStr !== "[]" && isShowData) && <>
              <div className="mt-5 flex flex-wrap gap-2">
                      {
                        tableHeaders.map((val,i) => (
                          <span  onClick={()=>handleInclueExcludeNumber(i+1)} style={{backgroundColor: excludedFields.indexOf(i+1) === -1? "":"red"}}  className='cursor-pointer bg-blue-900 text-white rounded-md p-1 text-[10px]'>{val}</span>
                        ))
                      }  
              </div>
              { headerStr }
              { bodyStr }
            </>
          }
        </div>
        
        {
          !surveyFound && <SomethingNotFound someting={"Surveys"}/>
        }
        
        {
          (!loading && surveyFound)  &&  <> 
            <div className="w-full mt-5">
              <Table >
                  <Thead>
                    <Tr>
                      <>
                        {
                          isImpactSurvey && ImpactTableHeaders.map((th,idx) => <Th key={idx}><>{th}</></Th>)
                        }
                        {
                          !isImpactSurvey && tableHeaders.map((th,idx) => <Th key={idx}><>{th}</></Th>)
                        }
                      </>
                    </Tr>
                  </Thead>
                  <Tbody>
                      {
                        completedSurveys && completedSurveys.map((item,index)=> {
                            const group = groups?.find((gp:any) => {
                                  const user = gp.users?.find((user:any) => user._id === item?.user?._id);
                                  if(user){
                                    return gp
                                  }
                                  return undefined 
                            })

                            return <CompletedSurvey 
                            survey={item} 
                            key={index} 
                            index={index}
                            isImpactSurvey={isImpactSurvey}
                            group={group}
                            isDataAnonymized={isDataAnonymized}
                            />
                        })
                      }
                  </Tbody>
              </Table>

              <div className="mt-3 w-full flex flex-col justify-center items-center gap-2">
                <p>{((searchTerms.page*paginationData.limit) > paginationData.totalDocuments? paginationData.totalDocuments:(searchTerms.page*paginationData.limit))}/{paginationData.totalDocuments}</p>
                <div className="flex items-center gap-2 mt-1">
                    <button disabled={searchTerms.page < Math.ceil(paginationData.totalDocuments/paginationData.limit)?false:true} onClick={loadMore} id='plusBtn' className='bg-blue-600 text-white rounded-md px-4 py-1 disabled:bg-blue-600/50 disabled:cursor-not-allowed'>{isChangingPage? "Loading...":"Load More"}</button>
                </div>
              </div>
            </div>
          </>
        }

        {
          (!loading && surveyFound)  &&  <> 
                                            <div className="w-full mt-5">
                                              <Table >
                                                  <Thead>
                                                    <Tr>
                                                      {
                                                        sdTableHeader.map((th,idx) => <Th key={idx}><>{th}</></Th>)
                                                      }
                                                    </Tr>
                                                  </Thead>
                                                  <Tbody>
                                                      {
                                                        historicalOPQ.map((item:any,idx:number) => {
                                                          const group:any = groups?.find((gp:any) => {
                                                              const user = gp.users?.find((user:any) => user._id === item.data?.user?._id);
                                                              if(user){
                                                                return gp
                                                              }
                                                              return undefined 
                                                          })
                                                          const survey = item.data;
                                                          const title = survey?.user?.activities.find((ac:any) => ac.category === survey.surveyName)?.name;

                                                          return <Tr key={idx}>
                                                                    <Td>{`${idx + 1}`}</Td>

                                                                    <Td>{survey?.user?.forename}{` `}{survey?.user?.surname}</Td>

                                                                    <Td>{survey?.user?.email}</Td>

                                                                    <Td>org</Td>

                                                                    <Td>dept</Td>

                                                                    <Td>
                                                                      <select
                                                                        className="form-control category-select"
                                                                        id="exampleFormControlSelect1"
                                                                      >
                                                                        <option>{group?.groupname}</option>
                                                                      </select>
                                                                    </Td>

                                                                    <Td>{survey.surveyName ? `${survey?.surveytitle?.title} ${survey.surveyName}`:survey?.surveytitle?.title}</Td>

                                                                    <Td>{item.data?.surveyName}</Td>
                                                                    
                                                                    <Td>{title}</Td>

                                                                    <Td>{new Date(item.date).toLocaleString().split(",")[0]}</Td>

                                                                    <Td>{new Date(item.date).toLocaleString().split(",")[1]}</Td>

                                                                    <Td>{item.OPQ}</Td>
                                                          </Tr>
                                                        })
                                                      }
                                                  </Tbody>
                                              </Table>
                                            </div>
                                          </>
        }
    </Main>
    </>
  )
}

export default CompletedSurveys;







