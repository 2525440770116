import { toast } from "react-toastify";
import { removeOneActionChallenge } from "../../../Store/actions";

interface IActionChallenge {
    actionChallenge: any;
    i: any;
    universalSurveytitle: any;
    actionChallengeId: any;
    setActionChallenges: any;
  }
  
  export const ActionChallenge: React.FC<IActionChallenge> = ({actionChallenge,i,universalSurveytitle,actionChallengeId,setActionChallenges}) => {
  
    const handleDeleteChallenge = async (challenge:any)=> {
  
      const res = await removeOneActionChallenge(universalSurveytitle._id,actionChallengeId,challenge);
  
      if(res.success){
        toast.success(res.message);
        setActionChallenges(res.actionChallenges);
      }
      else {
        toast.error(res.message);
      }
    }
  
    return (
      <>
      <div className="flex justify-between p-2 shadow-sm bg-white rounded">
        <div className="grow-1">
          {i+1}: {actionChallenge}
        </div>
        <div className="">
          <button 
          className="bg-red-500 text-white font-bold text-sm px-3 py-1 rounded"
          onClick={()=> handleDeleteChallenge(actionChallenge)}
          >Delete</button>
        </div>
      </div>
      </>
    )
  }