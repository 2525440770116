import React from "react";
import { useSelector } from "react-redux";
import { 
  Navigate, 
  useLocation,
  Outlet
} from "react-router-dom";
import {usePreviousLocationSetter} from "./usePreviousLocationSetter";
import { RootState } from "../../Store/store";



export function PrivateRoutes() {
  const loggedInUser  = useSelector((state:RootState) => state.loggedInUser);
  const location = useLocation();
  usePreviousLocationSetter();

  
  if (!loggedInUser) {
    return <Navigate to="/login" replace state={{ redirectTo: location }} />;
  }
  
  return <Outlet/>;
}
