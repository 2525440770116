import FadeLoader from "react-spinners/FadeLoader";
import { SECONDRY_BUTTON_COLOR } from "../constants";


interface ILoading {
  loadingText?: string;
}

export const Loading: React.FC<ILoading> = ({loadingText = "Loading..."}) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center gap-4">
          <FadeLoader color={SECONDRY_BUTTON_COLOR}/>
          <h2 className="text-center text-secondry-button-color font-bold text-xl">{loadingText}</h2>
      </div>
    </>
  );
};
