interface IThead {
    children: JSX.Element[] | JSX.Element;
}

export const Thead: React.FC<IThead> = ({children}) => {


    return (
        <thead className="text-secondry-color font-medium border-b border-secondry-color/20">
            {children}
        </thead>
    )
}