import { PrimaryButton } from "../../../components";
import { Header } from "../../OnBoarding/components";

interface IPreSurvey {
    setIsPreDone: any
  }
  
  export const PreSurvey: React.FC<IPreSurvey> = ({setIsPreDone}) => {
  
  
    const handleNext = () => {
      setIsPreDone(true);
    }
  
    
    return (
      <> 
                  <Header handleBack={()=>{}} title="Set Your Baseline"/>
                  <p className='text-secondry-color mb-6'>Let's Personalise Your Journey</p>
                  <p className='text-secondry-color mb-6'>We need some insights from you to tailor your experience and help you track your growth with XLRate</p>
                  <p className='text-secondry-color mb-3 font-bold'>Why Provide Baseline Data?</p>
                  <ul className='list-disc mb-6 pl-6 text-secondry-color'>
                    <li className=''><span className='font-bold'>Personalised Feedback:</span> Get tailored insights and actionable recommendations</li>
                    <li className=''><span className='font-bold'>Track Progress:</span> Measure your improvement and celebrate milestones</li>
                    <li className=''><span className='font-bold'>Optimise Performance:</span> Identify and enhance areas of growth</li>
                  </ul>
                  <p className='text-secondry-color mb-6'>Ready to start? Let’s set your baseline!</p>
                
                  <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                    Start Now
                  </PrimaryButton>
      </>
    )
  }